import React, { Component } from "react";
import Modal from "../../components/modal";
import Nav from "../../components/Nav";
import { CheckCircle,Plus, Target, Circle, Smartphone } from "react-feather"

import { Link } from "react-router-dom";
import moment from "moment";


class Reconciliation extends Component {
  state = {
   
  };
  render() {
    return (

      <div className="">
        <Nav
          name="MPESA Reconcilliation"
        ></Nav>

        <div className="p-6 container-fluid">
          <div className="row mt-5 px-3">
            <div className="col-md-6">
              <div class="form-group">
                <label for="fileInput1" className="p-2">Upload an M-PESA statement file</label>
                <input
                  type="file"
                  class="form-control"
                  id="fileInput1"
                  aria-describedby="emailHelp"
                  placeholder="Enter file"
                  onChange={e => this.setState({ file: e.target.files[0] })}
                />
                <button
                  className="btn btn-primary mt-5 px-5"
                  disabled={!this.state.file}
                  onClick={this.reconcile}>
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      



        <Modal
          visible={this.state.modalVisible}
          dismiss={false}
          close={() => this.setState({ modalVisible: false })}>
          <div>
            <div className="d-flex flex-row align-items-center">
              {this.state.uploadSuccessful ? (
                <CheckCircle color="#4caf50" size={64} />
              ) : (
                <div className="lds-spinner">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              )}
              <div className="ml-5 text-dark">
                {!this.state.uploadSuccessful ? (
                  <h3>Uploading ...</h3>
                ) : (
                  <h3>Upload Successful. System will take care of the rest</h3>
                )}
              </div>
            </div>
            {this.state.uploadSuccessful && (
              <div className="text-center">
                <button
                  className="btn btn-primary mt-4 px-5"
                  onClick={() => this.setState({ modalVisible: false })}>
                  Done
                </button>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }

  reconcile = () => {
    let formData = new FormData();
    formData.append("mpesa", this.state.file);
    this.setState({ modalVisible: true });
    fetch(`${window.server}/utils/recon/mpesa`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token
      },
      body: formData
    })
      .then(response => response.json())
      .then(response => {
        if (response.code) {
          alert(
            response.message +
            " \n " +
            (response.errors[0] ? response.errors[0].message : "")
          );
          this.setState({ modalVisible: false });
        } else {
          //console.log(response);
          this.setState({ user_id: response.user_id });
          setTimeout(() => {
            this.fetchStatement();
          }, 100);

          this.setState({ uploadSuccessful: true });
        }
      })
      .catch(d => {
        //console.log("Error saving the data");
        //console.log(d);
        this.setState({ modalVisible: false });
      });
  };




}

export default Reconciliation;
