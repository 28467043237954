import React, { Component } from "react";
import logo from "../img/okoa.png";
import {
  User,
  Lock,
  Mail,
  Eye,
  EyeOff,
  XCircle,
  MessageSquare,
  CheckCircle,
} from 'react-feather';
import { Link } from "react-router-dom";
import Modal from "../components/modal";
import LButton from "../components/loadingButton";
import Countdown from 'react-countdown';
import config from "../config";

class Login extends Component {
  state = {
    custom: true,
    loading: false,
    email: "",
    password: "",
    resetVisible: false,
    resetStatus: 0,
    loginStatus: 0,
    showPassword: false,
    verificationTab: 1,
    twoFa: '',
    showVerificationModal: false,
  };
  render() {
    return (
      <div className="d-flex flex-fill flex-column main-cover justify-content-center wallpaper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5 login-card">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-lg-6  d-none default-bg align-items-center justify-content-center flex-row d-md-flex icon-holder">
                      <img src={logo} className="main-logo" alt="" />
                    </div>
                    <div className="col-lg-6">
                      <div className="p-md-5 p-4">
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-4 font-weight-bold">
                            <div>OKOA FLOAT</div>
                          </h1>
                        </div>

                        <div className="text-center mb-4">
                          <small className="text-muted">Admin login</small>
                        </div>
                        <form
                          onSubmit={e => {
                            e.preventDefault();
                            this.login();
                          }}
                        >
                          <div className="form-group d-flex flex-row align-items-center">
                            <Mail
                              className="login-icon align-self-center position-absolute ml-2"
                              color="gray"
                              size={18}
                            />

                            <input
                              type="email"
                              className="form-control form-control-user  icon-input"
                              placeholder="Email Address"
                              value={this.state.email}
                              required={true}
                              onChange={e => {
                                this.setState({ email: e.target.value });
                              }}
                            />
                          </div>
                          <div className="form-group d-flex flex-row align-items-center position-relative">
                            <Lock
                              className="login-icon align-self-center position-absolute ml-2 "
                              color="gray"
                              size={18}
                            ></Lock>
                            <input
                              type={
                                this.state.showPassword ? "text" : "password"
                              }
                              required={true}
                              className="form-control form-control-user icon-input"
                              placeholder="Password"
                              autocomplete="off"
                              onChange={e => {
                                this.setState({ password: e.target.value });
                              }}
                              value={this.state.password}
                            />

                            {!this.state.showPassword && (
                              <Eye
                                size={18}
                                className="login-icon align-self-center position-absolute eye-icon"
                                onClick={() =>
                                  this.setState({
                                    showPassword: !this.state.showPassword
                                  })
                                }
                              ></Eye>
                            )}

                            {this.state.showPassword && (
                              <EyeOff
                                size={18}
                                className="login-icon align-self-center position-absolute eye-icon"
                                onClick={() =>
                                  this.setState({
                                    showPassword: !this.state.showPassword
                                  })
                                }
                              ></EyeOff>
                            )}
                          </div>
                          {/* <div className="form-group d-flex flex-row align-items-center justify-content-between position-relative">
                            <Link
                              className=""
                              onClick={() =>
                                this.setState({ resetVisible: true })
                              }
                            >
                              <small>Forgot password?</small>
                            </Link>
                          </div> */}
                          <div className="text-center">
                            <div className="d-inline-block">
                              <LButton
                                text="Login"
                                status={this.state.loginStatus}
                              ></LButton>
                            </div>
                          </div>
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          visible={this.state.resetVisible}
          close={() => {
            this.setState({ resetVisible: false });
          }}>
          <div className="py-3">
            <h5 className="text-center font-weight-bold">Reset password</h5>
          </div>

          <div className="form-group d-flex flex-row align-items-center mx-3">
            <Mail
              className="login-icon align-self-center position-absolute ml-2"
              color="gray"
              size={18}
            />

            <input
              type="email"
              className="form-control form-control-user pl-4 icon-input"
              placeholder="Email Address"
              value={this.state.email}
              onChange={e => {
                this.setState({ email: e.target.value });
              }}
            />
          </div>
          <div className="text-center">
            <div className="my-3 d-inline-block">
              <LButton
                text="Reset"
                status={this.state.resetStatus}
                onClick={() => this.resetEmail()}
              ></LButton>
            </div>
          </div>
        </Modal>



        <Modal visible={this.state.showVerificationModal} dismiss={true}>
          <div className='text-center '>
           
            {this.state.verificationTab === 2 && (
              <div className='verify-container '>
                <h3 className='mb-4'>Verification code</h3>

                <p className='text-whitesmoke'>
                  A verification code has been sent to your mobile device. Enter
                  the code below in order to proceed.
                </p>
                <form
                  action=''
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.verify();
                  }}>
                  <div className='d-flex flex-row align-items-center '>
                    <MessageSquare className='position-absolute text-whitesmoke ml-2'></MessageSquare>

                    <input
                      type='text'
                      className='form-control code-input my-4 text-center pl-3'
                      onChange={(e) => {
                        this.setState({ twoFa: e.target.value });
                      }}
                      required
                    />
                  </div>
                  <div className='text-whitesmoke'>
                    <Countdown
                      daysInHours={true}
                      date={Date.now() + 300000}
                      onComplete={() => {
                        this.setState({ verificationTab: 1 });
                        window.alert2.notify({
                          title: 'Verification code has timed out',
                          description:
                            'Please try again by selecting any of the provided options.',
                          autoCloseDuration: 10000,
                        });
                      }}
                    />
                  </div>
                  <button className='btn btn-primary px-4 btn-round mt-3'>
                    Submit
                  </button>
                </form>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }

  login = () => {
    let { email } = this.state;
    let { password } = this.state;
    if (!(email !== "" && password !== "")) {
      alert("Please fill in all the required values");
      return false;
    }

    this.setState({ loginStatus: 1 });
    // let data = { name, email, password };

    fetch(window.server + "/authentication", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      },
      body: `{"strategy": "local",  "email": "${email}", "password": "${password}"}`
    })
      .then(response => response.json())
      .then(response => {
        

        if (response.ErrorCode) {
          console.log('error is',response);
          this.setState({ loginStatus: 0 });           
          localStorage.clear();
          if (response.ErrorDescription == "Sent 2FA") {
            this.setState({
              loginStatus: 2,
              verificationTab: 2,
              showVerificationModal: true,
            });
          }else if(response.ErrorDescription == "Invalid login"){
            window.alert2.show({
              loader: false,
              icon: <XCircle size={60} className=''></XCircle>,
              title: 'Invalid Credentials!',            
              buttons: true,
            });

          }
           else {
            window.alert2.show({
              loader: false,
              icon: <XCircle size={60} className=''></XCircle>,
              title: 'Internal Api Error',            
              buttons: true,
            });
          }


          
        } else if (response.accessToken) {
          localStorage.token = response.accessToken;
          //alert(JSON.stringify(response.user));role1-agents, role2- admin, role3- marketers
          window.user = response;
          localStorage.user = JSON.stringify(window.user);
          localStorage.id = response.user.id;
          localStorage.role_id = response.user.role_id;
          let location = "/";
          if (localStorage.previousPath) location = localStorage.previousPath;
          window.location = location;
          this.setState({ loginStatus: 2 });
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        alert("No Network. Please try again.");
        this.setState({ loginStatus: 0 });
      });
  };

  resetEmail = () => {
    let { email } = this.state;
    if (!(email !== "")) {
      alert("Please enter a valid email");
      return false;
    }

    this.setState({ resetStatus: 1 });

    fetch(window.server + "/utils/resetpassword", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      },
      body: `{"email": "${email}"}`
    })
      .then(response => response.json())
      .then(response => {
        //console.log(response);

        if (response.ErrorCode) {
          this.setState({ resetStatus: 0 });

          alert(response.message);
          // alert ("Invalid Login Credentials. Kindly Try Again.");
        } else {
          this.setState({ resetStatus: 2 });
          alert(response.result);
        }
      })
      .catch(e => {
        this.setState({ resetStatus: 0 });
        console.error(e);
      });
  };


  verify = () => {
    let { password,email,twoFa } = this.state;

    console.log('password',this.state.password);
    console.log('email',this.state.email);
    console.log('twoFa',this.state.twoFa);
   

    window.alert2.show({
      loader: true,
      title: 'Verifyin ...',
      buttons: true,
    });


     
    fetch(window.server + "/authentication", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      },
      body: `{"strategy": "local",  "email": "${email}", "password": "${password}","twoFa": "${twoFa}"}`
    })
    .then(response => response.json())
      .then((response) => {
        console.log('response is',response)

        if (response.ErrorCode) {
          
          alert("Invalid Login Credentials. Kindly Try Again.");
          this.setState({ loginStatus: 0 });
          return false;
        } else if (response.accessToken) {
          console.log('response is',response)
          localStorage.token = response.accessToken;        
          window.user = response;
          localStorage.user = JSON.stringify(window.user);
          localStorage.id = response.user.id;
          localStorage.role_id = response.user.role_id;
          let location = "/";
          if (localStorage.previousPath) location = localStorage.previousPath;
          window.location = location;
          this.setState({ loginStatus: 2 });
        }
       
      })
      .catch((err) => {       
        this.setState({ loginStatus: 0 });
        console.log(err);
        window.alert2.show({
          loader: false,
          icon: <XCircle size={60} className=''></XCircle>,
          title: 'Verification Error!',
          message: 'Invalid Code Entered',
          buttons: true,
        });
      });
  };
}

export default Login;
