import React, { Component } from 'react';
import Nav from '../../components/Nav';
import { Route } from 'react-router-dom';
import Form from '../../components/form';
import moment from 'moment';
import { X, XCircle, CheckCircle } from 'react-feather';

class EmployeeAdd extends Component {
  state = {
    details: {
      passport: null,
      firstname: 'gabriel',
      middlename: 'kamau',
      surname: 'muhangia',
      msisdn: '0717638548',
      date_of_birth: '2020-01-08',
      gender: '1',
      marital_status: '1',
      identity_type: '1',
      id_number: '2324234',
      kra_pin: 'KSDNF32324234',
      sub_county_id: '1',
      ward_id: '2',
      address: '17 kingstion road',
      nationality: '1',
      pledge: '3000'
    }
  };
  render() {
    return (
      <div className='page border-left'>
        <Nav name='Add an Employee'></Nav>
        <div className='mt-3 table-card  border-0 card shado mx-3 shadow mb-5'>
          <div className='p-md-4 p-3'>
            <Route
              path='/employee-add/1'
              exact
              render={props => (
                <div className='mt-2 '>
                  <h4>Employee Details</h4>

                  <Form
                    submitText={'Add Employee'}
                    back={false}
                    inputs={[
                      {
                        label: 'Passport photo',
                        name: 'passport',
                        value: this.state.details.passport,
                        type: 'passport'
                      },
                      {
                        label: 'First name',
                        name: 'firstname',
                        value: this.state.details.firstname
                      },
                      {
                        label: 'Middle name',
                        name: 'middlename',
                        value: this.state.details.middlename
                      },
                      {
                        label: 'Surname',
                        name: 'surname',
                        value: this.state.details.surname
                      },
                      {
                        label: 'Phone number',
                        name: 'msisdn',
                        value: this.state.details.msisdn,
                        type: 'tel'
                      },
                      {
                        label: 'Date Of Birth',
                        name: 'date_of_birth',
                        value: this.state.details.date_of_birth,
                        type: 'date'
                      },
                      {
                        label: 'Gender',
                        name: 'gender',
                        value: this.state.details.gender,
                        type: 'select',
                        options: [
                          { name: 'Male', value: 1 },
                          { name: 'Female', value: '2' }
                        ]
                      },
                      {
                        label: 'Marital Status',
                        name: 'marital_status',
                        value: this.state.details.marital_status,
                        type: 'select',
                        options: [
                          { name: 'Single', value: 1 },
                          { name: 'Married', value: '2' }
                        ]
                      },

                      {
                        label: 'Address',
                        name: 'address',
                        value: this.state.details.address
                      },
                      {
                        label: 'nationality',
                        name: 'nationality',
                        value: this.state.details.nationality,
                        type: 'select',
                        options: [
                          { name: 'Kenyan', value: 1 },
                          { name: 'Nigerian', value: 2 }
                        ]
                      },
                      {
                        label: 'Pledge (Kshs)',
                        name: 'pledge',
                        value: this.state.details.pledge,
                        type: 'number'
                      },
                      { type: 'break' },
                      {
                        label: 'Identification Type',
                        name: 'identity_type',
                        value: this.state.details.identity_type,
                        type: 'select',
                        options: [
                          { name: 'Identity Card', value: 1 },
                          { name: 'Passport Number', value: 2 }
                        ]
                      },
                      {
                        label: 'ID number',
                        name: 'id_number',
                        type: 'number',
                        value: this.state.details.id_number
                      },
                      {
                        label: 'KRA pin',
                        name: 'kra_pin',
                        value: this.state.details.kra_pin
                      },
                      {
                        label: 'Sub County',
                        name: 'sub_county_id',
                        value: this.state.details.sub_county_id,
                        type: 'select',
                        options: [
                          ...window.subCounties.map((d, i) => {
                            return { name: d.sub_county_name, value: d.id };
                          })
                        ]
                      },
                      {
                        label: 'Ward',
                        name: 'ward_id',
                        value: this.state.details.ward_id,
                        type: 'select',
                        options: [
                          ...window.wards.map((d, i) => {
                            return { name: d.ward_name, value: d.id };
                          })
                        ]
                      },
                      // {
                      //   label: 'Village',
                      //   name: 'village',
                      //   value: this.state.details.village
                      // },
                      { type: 'break' },
                      {
                        label: '',
                        name: 'IDFront',
                        value: this.state.details.IDFront,
                        type: 'id'
                      },
                      {
                        label: '',
                        name: 'IDBack',
                        value: this.state.details.IDBack,
                        type: 'hidden'
                      }
                    ]}
                    submit={data => {
                      data.password = '1234';
                      this.setState({ details: data });
                      setTimeout(() => {
                        this.addEmployee();
                      }, 0);
                      //   props.history.push("/member-add/2");
                    }}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>
    );
  }

  addEmployee = () => {
    let formData = new FormData();
    let { details } = this.state;
    details.msisdn = window.verifyNumber(details.msisdn);
    let postData = {
      ...this.state.details
    };

    Object.keys(postData).map(d => {
      if (d === 'kins') return;
      let data = postData[d];
      try {
        if (data.substring(0, 11) === 'data:image/') {
          formData.append(d, this.dataURLtoFile(data), 'file.png');
        } else {
          formData.append(d, data);
        }
      } catch (error) {
        console.error(error);
        formData.append(d, data);
      }
    });

    window.alert2.show({
      loader: true,
      title: 'Adding User',
      buttons: true
    });

    fetch(`${window.server}/users/registration`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.token
      },
      body: formData
    })
      .then(response => response.json())
      .then(response => {
        if (response.code) {
          window.alert2.show({
            loader: false,
            icon: <XCircle size={60}></XCircle>,
            title: 'Error ' + response.code,
            buttons: true,
            message:
              response.message +
              ' \n ' +
              (response.errors[0] ? response.errors[0].message : '')
          });

          this.setState({ modalVisible: false });
        } else {
          window.alert2.show({
            loader: false,
            icon: <CheckCircle size={60}></CheckCircle>,
            title: 'User Created Successfully',
            buttons: true,
            onSubmit: () => {
              this.props.history.push(
                `/member-view/details/${response.user_id}`
              );
            }
          });
        }
      })
      .catch(d => {
        //console.log("Error saving the data");
        //console.log(d);
        this.setState({ modalVisible: false });
      });
  };

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };
}

export default EmployeeAdd;
