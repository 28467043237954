import React, { Component } from "react";
import { uploadFile } from 'react-s3';
import Modal from "../../components/modal";
import Nav from "../../components/Nav";
import { CheckCircle,Plus, Target, Circle, Smartphone } from "react-feather"

import { Link } from "react-router-dom";
import moment from "moment";


class documentsUpload extends Component {

    constructor(props) {

        super(props);
        this.state = {
   
        };
}
  render() {
    return (       

      <div className="">
        <Nav
          name="Upload Agent Documents"
        ></Nav>

        <div className="p-6 container-fluid">
          <div className="row mt-5 px-3">
            <div className="col-md-6">
              <div class="form-group">
                <label for="fileInput1" className="p-2">Upload documents</label>
                <input
                  type="file"
                  class="form-control"
                  id="fileInput1"
                  aria-describedby="emailHelp"
                  placeholder="Enter file"
                  onChange={e => this.setState({ files: e.target.files })}
                  multiple="multiple"
                />
                <button
                  className="btn btn-primary mt-5 px-5"
                  disabled={!this.state.files}
                  onClick={this.uploadFiles}>
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
     
      </div>
    );
  }

  
  uploadFiles = () => {
    let formData = new FormData();
    for (let index = 0; index < this.state.files.length; index++) {
      const file = this.state.files[index];
      formData.append("agentFile", file);
    }

    this.setState({ modalVisible: true });
    fetch(`${window.server}/agent-files?agent_id=${this.props.match.params.agent_id}`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token
      },
      body: formData
    })
      .then(response => response.json())
      .then(response => {
          window.alert2.show({
            loader: false,
            icon: <CheckCircle size={60}></CheckCircle>,
            title: "File Uploaded",
            message:JSON.stringify(response),
            buttons: true,
            onSubmit: () => {
              this.props.history.push(`/agent-view/details/${this.props.match.params.id}/${this.props.match.params.agent_id}`);
            }
          });

        this.setState({ modalVisible: false });

        })
      .catch(d => {
        console.log("Error saving the data");
        //console.log(d);
        this.setState({ modalVisible: false });
        window.alert2.show({
          loader: false,
          icon: <CheckCircle size={60}></CheckCircle>,
          title: "Error Uploading File. Contact Admin",
          buttons: true,
          onSubmit: () => {
            this.props.history.push(`/agent-view/details/${this.props.match.params.id}/${this.props.match.params.agent_id}`);
          }
        });

      });
  };





}

export default documentsUpload;
