import React, { Component } from "react";
import Nav from "../../components/Nav";
import { Route } from "react-router-dom";
import Form from "../../components/form";
import { X, Printer, XCircle, CheckCircle } from "react-feather";
import logo from "../../img/_logo.png";
import moment from "moment";

class agentAdd extends Component {
  state = {
    details: {
      passport: null,
      firstname: "gabriel",
      middlename: "kamau",
      surname: "muhangia",
      msisdn: "0717638548",
      date_of_birth: "2020-01-08",
      gender: "1",
      marital_status: "1",
      identity_type: "1",
      id_number: "2324234",
      kra_pin: "KSDNF32324234",
      // sub_county_id: "1",
      // ward_id: "2",
      village: "KIJABE",
      address: "17 kingstion road",
      nationality: "1",
      pledge: "3000"
    },
    kins: [
      {
        type: "parent",
        firstname: "samuel",
        othername: "kariega",
        id_number: "22323423",
        msisdn: "0717342422",
        relationship: "father"
      }
    ],
    acceptAgreement: false
  };
  render() {
    return (
      <div className="page border-left">
        <Nav name="Add an Agent"></Nav>
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow mb-5">
          <div className="p-md-4 p-3">
            <Route
              path="/agent-add/1"
              exact
              render={props => (
                <div className="mt-2 ">
                  <h4>1. Agent Details</h4>

                  <Form
                    //submitText={"Next"}
                    //back={false}
                    inputs={[
                      {
                        label: "First name",
                        name: "first_name",
                        value: this.state.first_name
                      },
                      {
                        label: "Other names",
                        name: "other_names",
                        value: this.state.other_names
                      },
                      {
                        label: "ID number",
                        name: "id_number",
                        type: "number",
                        value: this.state.id_number
                      },
                      {
                        label: "Phone number",
                        name: "msisdn",
                        value: this.state.msisdn,
                        type: "tel"
                      },
                      {
                        label: "Location",
                        name: "location",
                        value: this.state.location
                      },
                      {
                        label: "Agent Number",
                        name: "agent_no",
                        value: this.state.agent_no
                      },
                      {
                        label: "Business Name",
                        name: "business_name",
                        value: this.state.business_name
                      }
                    ]}
                  />
                </div>
              )}
            />

            
          </div>
        </div>
      </div>
    );
  }

  generateInputs = () => {
    let data = {
      full_name: `${this.state.details.firstname} ${this.state.details.middlename} ${this.state.details.surname}`,
      date_of_birth: moment(this.state.details.date_of_birth).format(
        "Do MMMM YYYY"
      ),
      "Phone Number": this.state.details.msisdn,
      KRA_pin: this.state.details.kra_pin,
      gender: ["Male", "Female"][this.state.details.gender],
      sub_county: window.wardsList[parseInt(this.state.details.sub_county_id)],
      ward: window.wardsList[parseInt(this.state.details.ward_id)],
      village: this.state.details.village,
      ID_number: this.state.details.id_number,
      address: this.state.details.address,
      nationality: this.state.details.nationality,
      pledge: "KES " + parseInt(this.state.details.pledge).toLocaleString()
    };

    //console.log(data);

    return Object.keys(data).map(d => (
      <div className="col-md-3 pb-3">
        <div className="page-label text-capitalize">{d.replace(/_/g, " ")}</div>
        <div>{data[d] || "-"}</div>
      </div>
    ));
  };

  verify(data) {
    let result = true;
    let missing = [];
    Object.keys(data).map(d => {
      if (data[d] === "") {
        missing.push(d.replace("_id", "").replace(/_/g, " "));
        result = false;
      }
    });
    missing.join(", ");
    if (!result) alert("Please fill all the require fields : " + missing);
    return result;
    // return true;
  }

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  addClient = () => {
    let formData = new FormData();
    let { details } = this.state;
    details.msisdn = window.verifyNumber(details.msisdn);
    let postData = {
      ...this.state.details,
      ...this.state.documents,
      ...this.state.business,
      kins: this.state.kins,
      ...this.state.other
    };

    Object.keys(postData).map(d => {
      if (d === "kins") return;
      let data = postData[d];
      try {
        if (data.substring(0, 11) === "data:image/") {
          formData.append(d, this.dataURLtoFile(data), "file.png");
        } else {
          formData.append(d, data);
        }
      } catch (error) {
        console.error(error);
        formData.append(d, data);
      }
    });

    //console.log(postData);

    postData.kins.map((d, i) => {
      Object.keys(d).map(d1 => {
        formData.append(`kins[${i}][${d1}]`, d[d1]);
      });
    });

    window.alert2.show({
      loader: true,
      title: "Adding User",
      buttons: true
    });

    fetch(`${window.server}/utils/agent/register`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token
      },
      body: formData
    })
      .then(response => response.json())
      .then(response => {
        if (response.code) {
          window.alert2.show({
            loader: false,
            icon: <CheckCircle size={60}></CheckCircle>,
            title: "Agent Created Successfully",
            buttons: true,
            onSubmit: () => {
              this.props.history.push(`/okoa-agents`);
            }
          });

          this.setState({ modalVisible: false });
        } else {
          window.alert2.show({
            loader: false,
            icon: <CheckCircle size={60}></CheckCircle>,
            title: "Agent Created Successfully",
            buttons: true,
            onSubmit: () => {
              // this.props.history.push(
              //   `/member-view/details/${response.user_id}`
              // );

              this.props.history.push(`/okoa-agents`);
            }
          });
        }
      })
      .catch(d => {
        //console.log("Error saving the data");
        //console.log(d);
        this.setState({ modalVisible: false });
      });
  };
}

export default agentAdd;
