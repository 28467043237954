import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import moment from "moment";
import Nav from "../../components/Nav";

class Breakdown extends Component {
  state = {
    tableData: [],
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
    end: moment().add(1, 'days').format('YYYY-MM-DD'),
    start: moment().format('YYYY-MM-DD'),
  };

  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="New Agents"
        ></Nav>
        <div className="mt-3 border-0 card shado mx-3 bg-white">
          <Filter
            getFilter={filter => {
              setTimeout(() => {
                this.setState({
                  filter
                });
                //console.log(this.state);
              }, 0);
            }}
            dateRange={["start_date", "end_date"]}
          />
          <div className="p-4">
            <Table
              sortDirection={-1}
              data={this.state.tableData}
              fetch={params => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchLoans = () => {
    this.setState({ table_loading: true });

    let q = {
      ...this.state.filter,
      ...this.state.query
    };

    if (this.state.filter.hasOwnProperty('start_date')) {
      //console.log('true');
    } else {
      q = {
        start_date: this.state.start,
        end_date: this.state.end
      }
    }

    fetch(`${window.server}/utils/api/download-users`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(q)
    })
      .then(response => response.json())
      .then(response => {

        if (response != null && !response.ErrorCode && response.length !== 0) {

          delete response[0]

          let data = [];

          response.map((d, i) => {
            data.push({
              // user: d.user,
              agent_name: d.first_name + " " + d.other_names,
              agent_no: d.agent_no,
              store_number: d.store_number,
              limit: "KES." + parseFloat(d.limit).toLocaleString(),
              business_name: d.business_name,
              location: d.location,
              owner_name: d.owner_name,
              owner_msisdn: d.owner_msisdn,
              operator_msisdn: d.operator_msisdn,
              date_created: moment(d.created_at).format('DD-MM-YYYY HH:mm:ss')
            });
          });

          let dts = {};
          dts.data = data;

          this.setState({
            tableData: { ...dts },
            table_loading: false
          });

        } else {

          this.setState({
            tableData: {},
            table_loading: false
          });

        }

      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchLoans();
      }, 100);
    }
  }
}

export default Breakdown;
