import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class okoaAdmins extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false
  };

  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="Administrators"
        ></Nav>
        <div className="mt-3 border-0 mx-3 bg-white">
          <div className="p-4">
            <Table
              search={["first_name", "other_names", "msisdn"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={params => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchAgents = () => {
    this.setState({ table_loading: true });
    let q = {
      // ...this.state.filter,
      ...this.state.query,
      role_id: "2"
    };

    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");

    fetch(`${window.server}/users?${urlParams}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        console.log("users", response);
        let data = [];
        response.data.map((d, i) => {
          data.push({
            email_address: d.email,
            date_created: moment(d.created_at).format('YYYY-MM-DD HH:mm:ss'),
            Date_updated: moment(d.updated_at).format("YYYY-MM-DD HH:mm:ss"),
            status: <span className="badge badge-success">ACTIVE</span>,
            actions: (
              <div className="d-flex flex-row">
                <Link
                  to={"/admin-view/details/" + d.id}
                  className="btn btn-sm btn-primary px-2">
                  View
                </Link>
              </div>
            )
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false
        });
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchAgents();
      }, 100);
    }
  }
}

export default okoaAdmins;
