import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Edit, Edit2, Delete, Plus, Target, Circle, Smartphone, DollarSign } from "react-feather";
import { Link } from "react-router-dom";
import Form from "../../components/form";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";
import axios from 'axios';
import { ToastsContainer, ToastsStore } from 'react-toasts'

class okoaAgentLimit extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    editModalStatements: false,
    deleteModalStatements: false,
    query: {},
    filter: {},
    table_loading: false,
    id: '',
    agent_account_id: '',
    month: '',
    client_value: '',
    safaricom_value: '',
    fetch_saf_value: [],
    fetch_data: [],
  };

  timeout = null;
  render() {
    return (
      <div className="">
        <div className="mt-3 border-0 card mx-3 bg-white">
          <div className="p-4">
            <Table
              search={["agent_account_id", "month", "client_value", "safaricom_value"]}
              sort="month"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={params => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
            <div style={{ backgroundColor: '#fafafa', minHeight: '100px', display: 'flex', flex: '1', justifyContent: 'space-between', alignItems: 'center', padding: '15px' }}>
              <div style={{ flex: '1', justifyContent: 'center', textAlign: 'center', }}>AVG SAFARICOM VALUE : <span style={{ borderRadius: '10px', backgroundColor: '#CC8433', color: 'white', padding: '8px' }}>{parseFloat(parseInt(this.state.fetch_saf_value) / this.state.fetch_data.length)}</span> </div>
              <div style={{ flex: '1', justifyContent: 'center', textAlign: 'center', }}>AVG LIMIT VALUE : <span style={{ borderRadius: '10px', backgroundColor: '#4CAF50', color: 'white', padding: '8px' }}>{parseFloat((this.state.fetch_data.length) / (parseInt(this.state.fetch_saf_value)) * localStorage.getItem("agentLimitValue") * 100)}</span> </div>
            </div>
          </div>

        </div>

        <Modal
          visible={this.state.editModalStatements}
          close={() => this.setState({ editModalStatements: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Edit Agent Statements</h5>
          </div>
          {typeof this.state.activeDetails !== 'undefined' && (
            <div className="mt-3">
              <Form
                submitText={"Update"}
                back={false}
                inputs={[
                  {
                    label: "Month",
                    name: "month",
                    type: "date",
                    value: this.state.response.data[this.state.activeDetails]
                      .month
                  },
                  {
                    label: "Client Value",
                    name: "client_value",
                    type: "text",
                    value: this.state.response.data[this.state.activeDetails]
                      .client_value
                  },
                  {
                    label: "Safaricom Value",
                    name: "safaricom_value",
                    type: "text",
                    value: this.state.response.data[this.state.activeDetails]
                      .safaricom_value
                  },
                  {
                    // label: "Id",
                    name: "id",
                    type: "hidden",
                    readonly: "readonly",
                    value: this.state.response.data[this.state.activeDetails]
                      .id
                  }

                ]}
                submit={data => {
                  //console.log(data);
                  this.setState({ details: data });
                  setTimeout(() => {
                    this.editLimit(data);
                  }, 0);
                }}
              />

            </div>
          )}
        </Modal>

        <Modal
          visible={this.state.deleteModalStatements}
          close={() => this.setState({ deleteModalStatements: false })}
        >
          <div className="d-flex flex-row align-items-center">
            <Edit2 className="mr-3"></Edit2>
            <h5 className="m-0 mt-1">Delete Agent Statement</h5>
          </div>
          {typeof this.state.activeDetails !== 'undefined' && (
            <div className="mt-3">
              <Form
                submitText={"Delete"}
                back={false}
                inputs={[
                  {
                    label: "Are you sure You want to delete the Agent statement",
                    name: "id",
                    type: "hidden",
                    readonly: "readonly",
                    value: this.state.response.data[this.state.activeDetails]
                      .id
                  }

                ]}
                submit={data => {
                  //console.log(data);
                  this.setState({ details: data });
                  setTimeout(() => {
                    this.deleteLimit(data);
                  }, 0);
                }}
              />

            </div>
          )}
        </Modal>


      </div >
    );
  }

  fetchLoanRequests = () => {
    this.setState({ table_loading: true });

    let q = {
      ...this.state.query,
      "agent_account_id": this.props.match.params.agent_account_id
    };

    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");
    fetch(`${window.server}/statement?${urlParams}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        console.log("statements----", response);

        let data = [];
        let sumCount = 0;
        this.setState({ fetch_data: response.data });

        response.data.map((d, i) => {

          sumCount += parseInt(d.safaricom_value);

          this.setState({ fetch_saf_value: sumCount });

          data.push({
            statement_ID: d.id,
            month: `${moment(d.month).format('MMMM')}, ${moment(d.month).format('YYYY')}`,
            client_value: "KES " +
              parseFloat(
                parseFloat(d.client_value)
              ).toLocaleString(),
            safaricom_value: "KES " +
              parseFloat(
                parseFloat(d.safaricom_value)
              ).toLocaleString(),
            date_created: moment(d.created_at).format('DD-MM-YYYY HH:mm:ss'),
            date_updated: moment(d.updated_at).format('DD-MM-YYYY HH:mm:ss'),
            action: (
              <div className="d-flex flex-row">
                <button
                  onClick={() => {
                    this.setState({ editModalStatements: true, activeDetails: i });
                    //console.log(this.state.response.data[0]);
                  }}
                  className="btn btn-sm btn-success px-3 mr-2"
                >
                  Edit Statements
                </button>

                <button
                  onClick={() => {
                    this.setState({ deleteModalStatements: true, activeDetails: i });
                    //console.log(this.state.response.data[0]);
                  }}
                  className="btn btn-sm btn-danger px-3"
                >
                  Delete Statement
                </button>
              </div>
            )
          });
        });

        let dts = {};
        dts.data = data;


        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false
        });
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  editLimit = data => {
    // if (
    //   !window.confirm("Are you sure that you want to Repost this transaction?")
    // )
    //   return false;
    console.log(data);
    this.setState({ modalVisible: true });

    let postData = data;
    let stateId = postData.id;
    //alert(stateId);
    fetch(`${window.server}/statement/${stateId}`, {
      method: "PATCH",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(postData)
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
            " \n " +
            (response.errors[0] ? response.errors[0].message : "")
          );
          this.setState({ modalVisible: false });
        } else {
          console.log(response);
          alert(
            "Agent Account Statement Updated Successfully"
          );
          this.setState({ uploadSuccessful: true });
          this.setState({ editModalStatements: false });
        }
      })
      .catch(d => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ editModalStatements: false });
      });

  };

  deleteLimit = data => {
    // if (
    //   !window.confirm("Are you sure that you want to Repost this transaction?")
    // )
    //   return false;
    console.log(data);
    this.setState({ modalVisible: true });

    let postData = data;
    let stateId = postData.id;
    //alert(stateId);
    fetch(`${window.server}/statement/${stateId}`, {
      method: "DELETE",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        alert(
          "Limit Statement removed successfully"
        );
        //this.verifyComplete();
        this.fetchLoanRequests();
        this.setState({ deleteModalStatements: false });
      })
      .catch(d => {
        console.log("Error deleting the data");
        console.log(d);
        this.setState({ deleteModalStatements: false });
      });

  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchLoanRequests();
      }, 100);
    }
  }
}

export default okoaAgentLimit;
