export default {
  name: 'OKOA FLOAT',
  //live api
   server_url: 'https://api.okoafloat.com'
  //testing api
  //server_url: 'http://193a715e65a6.ngrok.io'
  //server_url: 'https://api.okoafloat.com:7443'

  //server_url: `http://localhost:3030`
};

