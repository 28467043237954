import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import moment from 'moment';
import Filter from './filter';

class Chart extends Component {
  state = {
    data: {
      labels: [],
      datasets: this.props.datasets
    },
    filter: {}
  };
  render() {
    return (
      <div>
        <div className="card">
          <div className="card-header">
            <h5 className="font-weight-bold">{this.props.title}</h5>
          </div>

          <Line
            options={{
              hover: {
                mode: "nearest",
                intersect: true
              },
              tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                  label: function (tooltipItem, data) {
                    var value =
                      data.datasets[tooltipItem.datasetIndex].data[
                      tooltipItem.index
                      ];
                    let v = value.y.toLocaleString();
                    return (
                      data.datasets[tooltipItem.datasetIndex].label +
                      ' : kes ' +
                      v
                    );
                  }
                }
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      userCallback: function (value, index, values) {
                        // Convert the number to a string and splite the string every 3 charaters from the end

                        return 'kes ' + value.toLocaleString();
                      }
                    }
                  }
                ],
                xAxes: [
                  {
                    ticks: {}
                  }
                ]
              }
            }}
            data={this.state.data}
          />
        </div>
      </div>
    );
  }

  updateTimeout = null;
  componentWillReceiveProps(props) {
    if (props.datasets.length) {
      clearTimeout(this.updateTimeout);
      this.updateTimeout = setTimeout(() => {
        //console.log(props);
        this.updateState(props);
      }, 500);
      // //console.log('received');
    }
  }

  updateState = props => {
    let labels = props.datasets[0].data.map(d => moment(d.t).format('Do MMM'));

    this.setState({ data: { labels, datasets: props.datasets } });
  };

  updateFilter = () => {
    setTimeout(() => {
      if (this.props.updateFilter) this.props.updateFilter(this.state.filter);
    }, 0);
  };
}


export default Chart;
