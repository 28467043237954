import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class suspensePayment extends Component {
    state = {
        tableData: { data: [] },
        response: { data: [] },
        tableError: false,
        query: {},
        filter: {},
        table_loading: false
    };

    timeout = null;
    render() {
        return (
            <div className="">
                <Nav
                    name="Suspense Payments"
                ></Nav>
                <div className="mt-3 border-0 mx-3 bg-white">
                    <div className="p-4">
                        <Table
                            search={["first_name", "other_names", "msisdn"]}
                            sort="id"
                            sortDirection={-1}
                            data={this.state.tableData}
                            fetch={params => {
                                this.setState({ query: params });
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                        />
                    </div>
                </div>
            </div>
        );
    }

    fetchAgents = () => {
        this.setState({ table_loading: true });
        let q = {
            // ...this.state.filter,
            ...this.state.query,
        };

        let urlParams = Object.entries(q)
            .map(e => e.join("="))
            .join("&");

        fetch(`${window.server}/suspense-payments?${urlParams}`, {
            headers: {
                Authorization: localStorage.token
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log("users", response);
                let data = [];
                response.data.map((d, i) => {
                    data.push({
                        mpesa_code: d.mpesa_code,
                        float_amount: "KES." + parseFloat(d.float_amount).toFixed(2),
                        business_number: d.business_number,
                        business_name: d.business_name,
                        mpesa_sms: d.mpesa_sms,
                        date_created: moment(d.created_at).format('YYYY-MM-DD HH:mm:ss')
                    });
                });

                let dts = {};
                dts.data = data;

                this.setState({
                    tableData: { ...response, ...dts },
                    response,
                    table_loading: false
                });
            })
            .catch(d => {
                this.setState({ table_loading: false });
                console.error(d);
            });
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
            JSON.stringify({ ...prevState.query, ...prevState.filter })
        ) {
            let $t = this;

            clearTimeout(this.timeout);
            this.timeout = setTimeout(function () {
                $t.fetchAgents();
            }, 100);
        }
    }
}

export default suspensePayment;
