import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class MemberDeposits extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false
  };
  timeout = null;
  render() {
    return (
      <div className="">
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              search={["firstname", "middlename", "surname", "msisdn"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={params => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchMemberDeposits = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
      account_type_id: 2,
      "credit[$gt]": 0,
      customer_id: this.props.match.params.id
    };

    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");

    // //console.log(urlParams);
    fetch(`${window.server}/customer-logs?${urlParams}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        //console.log(response);

        let data = [];
        response.data.map((d, i) => {
          data.push({
            id: d.id,
            transaction_id: d.transaction_id,
            ref_no: d.transaction_ref_no,
            // member:
            //   !d.customer ||
            //   `${d.customer.firstname} ${d.customer.middlename} ${d.customer.surname}`,
            // account_type_id: d.account_type_id,
            debit:  "KES " +
              parseFloat(
                parseFloat(d.debit)
              ).toLocaleString(),
            credit:  "KES " +
              parseFloat(
                parseFloat(d.credit)
              ).toLocaleString(),
            // amount:
            //   "KES " +
            //   parseFloat(
            //     parseFloat(d.debit) !== 0 ? d.debit : d.credit
            //   ).toLocaleString(),
            // type: parseFloat(d.debit) === 0 ? "Credit" : "Debit",
            balance_before:  "KES " +
              parseFloat(
                parseFloat(d.balance_before)
              ).toLocaleString(),
            balance_after: "KES " +
              parseFloat(
                parseFloat(d.balance_after)
              ).toLocaleString(),
            description: d.description,
            narration: d.narration,
            date_time : moment(d.date_created).format('Do MMMM YYYY, h:mm:ss a')
            // status: d.status
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false
        });
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function() {
        $t.fetchMemberDeposits();
      }, 100);
    }
  }
}

export default MemberDeposits;
