import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Grid,
  User as UsersIcon,
  DollarSign,
  Menu as MenuBars,
  Users,
  LogOut,
  Briefcase,
  ChevronDown,
  ChevronUp,
  CreditCard,
  Layout
} from "react-feather";

import Access from "./accessManager";
import config from "../config";
import user from "../img/user.svg";

class Menu extends Component {
  state = {
    title: config.name,
    menu: [
      {
        name: "Dashboard",
        icon: <Grid size={20} />,
        link: "/",
        access: "all"
      },
      {
        name: "Loans Breakdown",
        icon: <DollarSign size={20} />,
        link: "/summary",
        access: "all"
      },
      // {
      //   name: "Administrators",
      //   icon: <Users size={20} />,
      //   link: "/okoa-admin",
      //   access: "all"
      // },
      {
        name: "Users",
        icon: <Users size={20} />,
        link: "/users",
        access: "all",
        children: [
          {
            name: "Administrators",
            link: "/okoa-admin",
            access: "all"
          }, {
            name: "Marketers Rep",
            link: "/marketrep",
            access: "all"
          }, {
            name: "Customercare Rep",
            link: "/customercare",
            access: "all"
          }
        ]
      },
      {
        name: "Agents",
        icon: <Users size={20} />,
        link: "/users",
        access: "all",
        children: [
          {
            name: "All agents",
            link: "/okoa-agents",
            access: "all"
          },
          {
            name: "Agent Accounts",
            link: "/okoa-agent-accounts",
            access: "all"
          },
          {
            name: "New Agents",
            link: "/breakdown",
            access: "all"
          },
          {
            name: "Agent Limits",
            link: "/okoa-agent-limits",
            access: "all"
          },
        ]
      },
      {
        name: "Loans",
        icon: <DollarSign size={20} />,
        link: "/users",
        access: "all",
        children: [
          {
            name: "All loans",
            link: "/okoa-loans",
            access: "all"
          },
          {
            name: "Pending loans",
            link: "/pending-loans",
            access: "all"
          },
          {
            name: "Unmatured Loans",
            link: "/active-loans",
            access: "all"
          },
          {
            name: "Paid loans",
            link: "/paid-loans",
            access: "all"
          },
          {
            name: "Errored loans",
            link: "/errored-loans",
            access: "all"
          },
          {
            name: "Defaulted loans",
            link: "/defaulted-loans",
            access: "all"
          }
        ]
      },
      // {
      //   name: "Transactions",
      //   icon: <Briefcase size={20} />,
      //   link: "/users",
      //   access: "all",
      //   children: [
      //     {
      //       name: "All transactions",
      //       link: "/okoa-transactions",
      //       access: "all"
      //     }
      //   ]
      // },

      {
        name: "Mpesa",
        icon: <Layout size={20} />,
        link: "/users",
        access: "all",
        children: [
          {
            name: "Money IN",
            link: "/mpesa/inwards",
            access: "all"
          }, {
            name: "Money OUT",
            link: "/mpesa/outwards",
            access: "all"
          }
        ]
      },

      {
        name: "Payments",
        icon: <CreditCard size={20} />,
        link: "/users",
        access: "all",
        children: [
          {
            name: "Transactions Logs",
            link: "/okoa-transactions",
            access: "all"
          }, {
            name: "Payments Logs",
            link: "/okoa-payments-logs",
            access: "all"
          }
        ]
      },
      {
        name: "Reconciliations",
        icon: <Briefcase size={20} />,
        link: "/recons",
        access: "all",
        children: [
          {
            name: "Reconcile M-PESA",
            link: "/store-recon",
            access: "all"
          },
          {
            name: "Suspense Payments",
            link: "/suspense-payments",
            access: "all"
          },
          {
            name: "Merge Reconciliation",
            link: "/recon-missing",
            access: "all"
          },
        ]
      }
      /* {
        name: "SMS",
        icon: <MessageSquare size={20} />,
        link: "/sms",
        access: "all"
      }, */
    ],
    //iF NOT AN ADMINISTRATOR SHOW THIS MENU
    menuone: [
      {
        name: "Dashboard",
        icon: <Grid size={20} />,
        link: "/",
        access: "all"
      },
      // {
      //   name: "Loans Breakdown",
      //   icon: <DollarSign size={20} />,
      //   link: "/summary",
      //   access: "all"
      // },
      {
        name: "Agents",
        icon: <Users size={20} />,
        link: "/users",
        access: "all",
        children: [
          {
            name: "All agents",
            link: "/okoa-agents",
            access: "all"
          },
          {
            name: "Agent Accounts",
            link: "/okoa-agent-accounts",
            access: "all"
          },
          {
            name: "New Agents",
            link: "/breakdown",
            access: "all"
          },
          {
            name: "Agent Limits",
            link: "/okoa-agent-limits",
            access: "all"
          }
        ]
      },
      {
        name: "Loans",
        icon: <DollarSign size={20} />,
        link: "/users",
        access: "all",
        children: [
          {
            name: "All loans",
            link: "/okoa-loans",
            access: "all"
          },
          // {
          //   name: "Pending loans",
          //   link: "/pending-loans",
          //   access: "all"
          // },
          {
            name: "Active loans",
            link: "/active-loans",
            access: "all"
          },
          {
            name: "Paid loans",
            link: "/paid-loans",
            access: "all"
          },
          {
            name: "Errored loans",
            link: "/errored-loans",
            access: "all"
          },
          {
            name: "Defaulted loans",
            link: "/defaulted-loans",
            access: "all"
          }
        ]
      },
    ],
    //search
    menutwo: [
      {
        name: "Dashboard",
        icon: <Grid size={20} />,
        link: "/",
        access: "all"
      },
     
      {
        name: "Agents",
        icon: <Users size={20} />,
        link: "/users",
        access: "all",
        children: [
         
          {
            name: "Agents",
            link: "/okoasearch-agents",
            access: "all"
          },
         
          {
            name: "Agent Accounts",
            link: "/okoasearch-agent-accounts",
            access: "all"
          },
         
          {
            name: "New Agents",
            link: "/breakdownsearch",
            access: "all"
          },
          
          {
            name: "Agent Limits",
            link: "/okoasearch-agent-limits",
            access: "all"
          }
        ]
      },
      // {
      //   name: "Loans",
      //   icon: <DollarSign size={20} />,
      //   link: "/users",
      //   access: "all",
      //   children: [
          
      //     {
      //       name: "Loans",
      //       link: "/okoasearch-loans",
      //       access: "all"
      //     },
        
      //     {
      //       name: "Active loans",
      //       link: "/activesearch-loans",
      //       access: "all"
      //     },
         
      //     {
      //       name: "Paid loans",
      //       link: "/paidsearch-loans",
      //       access: "all"
      //     },
         
      //     {
      //       name: "Errored loans",
      //       link: "/erroredsearch-loans",
      //       access: "all"
      //     },
         
      //     {
      //       name: "Defaulted loans",
      //       link: "/defaultedsearch-loans",
      //       access: "all"
      //     }
      //   ]
      // },
    ],
    currentRoute: window.location.pathname,
    active: 4
  };
  render() {
    const role = localStorage.getItem("role_id");
    return (
      <>
        <div className="p-3 top-header d-md-none align-items-center d-flex flex-row bg-light w-100 text-dark shadow">
          <MenuBars
            className="mr-3"
            onClick={() => {
              this.setState({ showMenu: true });
            }}
          ></MenuBars>
          <div className="font-weight-bold top-bar-text">
            {this.state.title}
          </div>
        </div>
        <div
          className={"menu-overlay " + (this.state.showMenu ? "show" : "")}
          onClick={() => {
            this.setState({ showMenu: false });
          }}
        ></div>
        <div
          className={
            "sidebar-wrapper border-right shadow " +
            (this.state.showMenu ? "show" : "")
          }
          onClick={() => {
            this.setState({ showMenu: false });
          }}
        >
          <ul
            className="navbar-nav bg-light sidebar sidebar-dark accordion position-relative"
            id="accordionSidebar"
          >
            <div>
              <div className="d-flex flex-column user-login-card p-3 my-4 align-items-start">
                <div className="d-flex flex-row align-items-start justify-content-between w-100">
                  <div className="user-icon-parent">
                    <img src={user} className="user-icon" alt="" />
                  </div>
                  <div className="d-flex flex-row">
                    {/* <div>
                      <small className="font-weight-bold" style={{color:"#868181"}}>Welcome,</small><br/>
                      { role == 2?
                        <small className="font-weight-bold">Administrator</small>
                      : 
                        <small className="font-weight-bold">CCR</small>
                      }
                    </div> */}
                    <div
                      className="action-icon"
                      onClick={() => window.logout()}
                    >
                      <LogOut size={20}></LogOut>
                    </div>
                  </div>
                </div>
                <div className="mt-3">

                </div>
                {role == 2 ?
                  <div>
                    Hello {""}
                    <small className="font-weight-bold">Administrator</small>
                  </div>
                  : role == 1 ?
                    <div>
                      Hello {""}
                      <small className="font-weight-bold">CustomerCare</small>
                    </div>
                    : role == 4 ?
                    <div>
                      Hello {""}
                      <small className="font-weight-bold">Debt Collector</small>
                    </div>
                    :
                    <div>
                      Hello {""}
                      <small className="font-weight-bold">Marketer</small>
                    </div>
                }
              </div>
            </div>

            <div className="sidebar-heading text-dark mb-2 text-muted">
            </div>
            {role === '2' ?
              <>
                {this.state.menu.map((d, i) => {
                  return (
                    <li
                      className={
                        "nav-item " +
                        (this.state.active === i && d.children
                          ? "pb-4 no-hover "
                          : "") +
                        (this.state.currentRoute === d.link
                          ? "active"
                          : "text-dark")
                      }
                      key={i}
                    >
                      <Access permission={d.access}>
                        {" "}
                        <Link
                          to={d.link}
                          onClick={e => {
                            if (this.state.active === i) {
                              this.setState({ active: 0 });
                            } else {
                              this.setState({ active: i });
                            }
                            if (d.children) e.preventDefault();
                          }}
                          className={
                            "nav-link " +
                            (this.state.currentRoute === d.link
                              ? "active"
                              : "text-dark")
                          }>
                          <div className="d-flex flex-row align-items-center justify-content-between">
                            <div className="d-flex flex-row align-items-center">
                              {d.icon}
                              <div className="text-dark ml-2 font-weight-bold link-text">
                                {d.name}
                              </div>
                            </div>
                            {d.children && (
                              <div>
                                {this.state.active !== i && (
                                  <ChevronDown size={18}></ChevronDown>
                                )}
                                {this.state.active === i && (
                                  <ChevronUp size={18}></ChevronUp>
                                )}
                              </div>
                            )}
                          </div>
                        </Link>
                      </Access>
                      {this.state.active === i && d.children && (
                        <div
                          id="collapseUtilities"
                          className="collapse show shadow-sm"
                          aria-labelledby="headingUtilities"
                          data-parent="#accordionSidebar"
                        >
                          <div className=" py-2 collapse-inner rounded mb-0">
                            {/* <h6 className='collapse-header'>listing:</h6> */}
                            {d.children.map((d1, i1) => {
                              if (d1.type === "hr")
                                return (
                                  <>
                                    <hr className={`${!d1.title || "mb-1"}`} />
                                    {d1.title && (
                                      <div>
                                        <small className="text-muted text-uppercase font-weight-bold px-4">
                                          {d1.title}
                                        </small>
                                      </div>
                                    )}
                                  </>
                                );

                              return (
                                <Access permission={d1.access} key={i1}>
                                  <Link
                                    className={
                                      "collapse-item font-weight-bold my-1 text-capitalize " +
                                      (this.state.currentRoute === d1.link
                                        ? "active"
                                        : "text-dark")
                                    }
                                    to={d1.link}
                                    key={i1}
                                  >
                                    {d1.name}
                                  </Link>
                                </Access>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </li>
                  );
                })}
              </>
              : role == 4 ?
              <>
              {this.state.menutwo.map((d, i) => {
                  return (
                    <li
                      className={
                        "nav-item " +
                        (this.state.active === i && d.children
                          ? "pb-4 no-hover "
                          : "") +
                        (this.state.currentRoute === d.link
                          ? "active"
                          : "text-dark")
                      }
                      key={i}
                    >
                      <Access permission={d.access}>
                        {" "}
                        <Link
                          to={d.link}
                          onClick={e => {
                            if (this.state.active === i) {
                              this.setState({ active: 0 });
                            } else {
                              this.setState({ active: i });
                            }
                            if (d.children) e.preventDefault();
                          }}
                          className={
                            "nav-link " +
                            (this.state.currentRoute === d.link
                              ? "active"
                              : "text-dark")
                          }
                        >
                          <div className="d-flex flex-row align-items-center justify-content-between">
                            <div className="d-flex flex-row align-items-center">
                              {d.icon}
                              <div className="text-dark ml-2 font-weight-bold link-text">
                                {d.name}
                              </div>
                            </div>
                            {d.children && (
                              <div>
                                {this.state.active !== i && (
                                  <ChevronDown size={18}></ChevronDown>
                                )}
                                {this.state.active === i && (
                                  <ChevronUp size={18}></ChevronUp>
                                )}
                              </div>
                            )}
                          </div>
                        </Link>
                      </Access>
                      {this.state.active === i && d.children && (
                        <div
                          id="collapseUtilities"
                          className="collapse show shadow-sm"
                          aria-labelledby="headingUtilities"
                          data-parent="#accordionSidebar"
                        >
                          <div className=" py-2 collapse-inner rounded mb-0">
                            {/* <h6 className='collapse-header'>listing:</h6> */}
                            {d.children.map((d1, i1) => {
                              if (d1.type === "hr")
                                return (
                                  <>
                                    <hr className={`${!d1.title || "mb-1"}`} />
                                    {d1.title && (
                                      <div>
                                        <small className="text-muted text-uppercase font-weight-bold px-4">
                                          {d1.title}
                                        </small>
                                      </div>
                                    )}
                                  </>
                                );

                              return (
                                <Access permission={d1.access} key={i1}>
                                  <Link
                                    className={
                                      "collapse-item font-weight-bold my-1 text-capitalize " +
                                      (this.state.currentRoute === d1.link
                                        ? "active"
                                        : "text-dark")
                                    }
                                    to={d1.link}
                                    key={i1}
                                  >
                                    {d1.name}
                                  </Link>
                                </Access>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </li>
                  );
                })}
              </>
              :
              <>
                {this.state.menuone.map((d, i) => {
                  return (
                    <li
                      className={
                        "nav-item " +
                        (this.state.active === i && d.children
                          ? "pb-4 no-hover "
                          : "") +
                        (this.state.currentRoute === d.link
                          ? "active"
                          : "text-dark")
                      }
                      key={i}
                    >
                      <Access permission={d.access}>
                        {" "}
                        <Link
                          to={d.link}
                          onClick={e => {
                            if (this.state.active === i) {
                              this.setState({ active: 0 });
                            } else {
                              this.setState({ active: i });
                            }
                            if (d.children) e.preventDefault();
                          }}
                          className={
                            "nav-link " +
                            (this.state.currentRoute === d.link
                              ? "active"
                              : "text-dark")
                          }
                        >
                          <div className="d-flex flex-row align-items-center justify-content-between">
                            <div className="d-flex flex-row align-items-center">
                              {d.icon}
                              <div className="text-dark ml-2 font-weight-bold link-text">
                                {d.name}
                              </div>
                            </div>
                            {d.children && (
                              <div>
                                {this.state.active !== i && (
                                  <ChevronDown size={18}></ChevronDown>
                                )}
                                {this.state.active === i && (
                                  <ChevronUp size={18}></ChevronUp>
                                )}
                              </div>
                            )}
                          </div>
                        </Link>
                      </Access>
                      {this.state.active === i && d.children && (
                        <div
                          id="collapseUtilities"
                          className="collapse show shadow-sm"
                          aria-labelledby="headingUtilities"
                          data-parent="#accordionSidebar"
                        >
                          <div className=" py-2 collapse-inner rounded mb-0">
                            {/* <h6 className='collapse-header'>listing:</h6> */}
                            {d.children.map((d1, i1) => {
                              if (d1.type === "hr")
                                return (
                                  <>
                                    <hr className={`${!d1.title || "mb-1"}`} />
                                    {d1.title && (
                                      <div>
                                        <small className="text-muted text-uppercase font-weight-bold px-4">
                                          {d1.title}
                                        </small>
                                      </div>
                                    )}
                                  </>
                                );

                              return (
                                <Access permission={d1.access} key={i1}>
                                  <Link
                                    className={
                                      "collapse-item font-weight-bold my-1 text-capitalize " +
                                      (this.state.currentRoute === d1.link
                                        ? "active"
                                        : "text-dark")
                                    }
                                    to={d1.link}
                                    key={i1}
                                  >
                                    {d1.name}
                                  </Link>
                                </Access>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </li>
                  );
                })}
              </>
            }
            {/*  */}
            {/* <li className='nav-item'>
            <button
              className='nav-link d-flex flex-row align-items-center bg-transparent btn'
              onClick={() => {
                if (window.confirm("Are you sure you want to logout?")) {
                  localStorage.clear();
                  this.props.history.push("/login");
                }
              }}>
              <LogOut color='white' size={18} />
              <span className='text-white ml-2'>Logout</span>
            </button>
          </li> */}
          </ul>
        </div>
      </>
    );
  }

  componentWillReceiveProps(props) {
    if (props.history) {
      //   //console.log(props.history);
      this.setState({ currentRoute: window.location.pathname });
    }
  }
}

export default withRouter(Menu);
