import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class okoaTransactions extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false
  };

  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="Transaction Logs"
        ></Nav>
        <div className="mt-3 border-0 card shado mx-3">
          <div className="p-4">
            <Filter
              getFilter={filter => {
                setTimeout(() => {
                  this.setState({
                    filter
                  });
                }, 0);
              }}
              dateRange={["startdate", "enddate"]}
            />
            <Table
              search={["dr", "cr", "bal_before", "bal_after", "desc", "created_at"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={params => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchAgents = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query
    };

    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");

    let startdate = moment(this.state.filter.startdate).format("YYYY-MM-DD HH:mm:ss");
    let enddate = moment(this.state.filter.enddate).format("YYYY-MM-DD HH:mm:ss");
    fetch(`${window.server}/transactions-log?created_at[$gte]=${startdate}&&created_at[$lte]=${enddate}&&${urlParams}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        let data = [];
        response.data.map((d, i) => {
          data.push({
            agent_name: d.agent_transaction_log.business_name === "00" ? "N/A" : d.agent_transaction_log.business_name,
            agent_number: d.agent_transaction_log.agent_no === "00" ? "N/A" : d.agent_transaction_log.agent_no,
            debit: "KES." + parseFloat(d.dr).toFixed(2),
            credit: "KES." + parseFloat(d.cr).toFixed(2),
            balance_before: "KES." + parseFloat(d.bal_before).toFixed(2),
            balance_after: "KES." + parseFloat(d.bal_after).toFixed(2),
            description: d.desc,
            reference_number: d.ref_no,
            date_created: moment(d.created_at).format('YYYY-MM-DD HH:mm:ss'),
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false
        });
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchAgents();
      }, 100);
    }
  }
}

export default okoaTransactions;
