import React, { Component } from "react";
import moment from "moment";
import { DollarSign, User, LogOut } from "react-feather";

class Details extends Component {
  randomize = (start, end) => {
    return Math.floor(Math.random() * end) + start;
  };

  state = {
    data: {},
    salaryVisible: false,
    exitInfo: {}
  };
  render() {
    return (
      <div className="p-3">
        <div className="row bg-white">
          <div className="col-md-7 bg-light">
            <ul className="list-group user-details border-0 w-100 bg-white">
              <div className="p-3 bg-white font-weight-bold">
                <User size={18}></User> Agent Details
              </div>
              {Object.keys(this.state.data).map(d => (
                <li className="list-group-item d-flex flex-row text-dark">
                  <div className="user-detail-title font-weight-bold text-capitalize">
                    {d.replace(/_/g, " ")}
                  </div>
                  <div>{this.state.data[d]}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount = () => {
    this.fetch();
  };

  fetch = () => {
    let user_id = this.props.match.params.id;
    fetch(`${window.server}/agents?id=${user_id}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        console.log("agent details", response);
        let data = [];
        response.data.map(d => {
          data.push({
            full_name: `${d.first_name} ${d.other_names}`,
            "Phone Number": d.msisdn,
            status: { 1: "Active", 0: "Inactive" }[d.status],
            wallet: "KES " + parseInt(d.wallet).toLocaleString(),
            created_at: moment(d.created_at).format('DD-MM-YYYY HH:mm:ss'),
            updated_at: moment(d.updated_at).format('DD-MM-YYYY HH:mm:ss')
          });
        });
        response.data = data;
        this.setState({ data: data[0] });
      })
      .catch(d => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };
}

export default Details;
