import React, { Component } from "react";
import moment from "moment";
import { DollarSign, User, LogOut, MapPin, XCircle } from "react-feather";
import { Link } from "react-router-dom";

class Details extends Component {
  state = { data: {}, balanceInfo: {}, salaryVisible: false, exitInfo: {} };
  render() {
    return (
      <div className="p-3 mt-3">
        <div className="row">
          <div className="col-md-6">
            <ul className="list-group user-details loan-card ">
              <div className="p-3 bg-light font-weight-bold border-bottom">
                <DollarSign size={18}></DollarSign> Loan Details
              </div>
              {Object.keys(this.state.data).map(d => (
                <li className="list-group-item d-flex flex-row  text-dark border-0">
                  <div className="user-detail-title font-weight-bold text-capitalize">
                    {d.replace(/_/g, " ")}
                  </div>
                  <div>{this.state.data[d]}</div>
                </li>
              ))}
            </ul>
          </div>
          {this.state.customer && (
            <div className="col-md-6">
              <div className="officer-card-parent mx-3 text-input mb-3">
                <div className=" bg-light font-weight-bold d-flex flex-row justify-content-between align-items-center">
                  <h5 className="p-3 m-0 font-weight-bold">Agent</h5>

                  <Link
                    to={`/agent-view/details/${this.state.customer_id}`}
                    className="btn btn-outline-primary px-3 btn-round mr-3"
                  >
                    View
                  </Link>
                </div>
                <div className=" d-flex flex-row guarantor-card align-items-center">
                  <div className="mx-3">
                    <div className="user-profile">
                      <span className="position-absolute">
                        {this.state.customer.firstname[0]}
                      </span>
                    </div>
                  </div>
                  <div className="py-3 w-100 pr-3">
                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <h5 className="font-weight-bold">
                        {this.state.customer.firstname}{" "}
                        {this.state.customer.middlename}{" "}
                        {this.state.customer.surname}{" "}
                      </h5>
                    </div>
                    <div className="d-flex flex-row align-items-center flex-fill">
                      <div>
                        <small className="font-weight-bold">Nationa ID</small>
                        <div>{this.state.customer.id_number}</div>
                      </div>
                      <div className="mx-3 content-divider"> </div>
                      <div>
                        <small className="font-weight-bold">Phone Number</small>
                        <div>{this.state.customer.msisdn}</div>
                      </div>
                    </div>
                    <div className=" mt-2 d-flex flex-row justify-content-between align-items-center w-100">
                      <div className="badge badge-primary px-3 d-inline-block">
                        client
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {this.state.officer && (
                <div className="officer-card-parent mx-3 text-input mb-3">
                  <div className=" bg-light font-weight-bold d-flex flex-row justify-content-between align-items-center">
                    <h5 className="p-3 m-0 font-weight-bold">Officer</h5>

                    <Link
                      to={`/member-view/`}
                      className="btn btn-outline-primary px-3 btn-round mr-3"
                    >
                      View
                    </Link>
                  </div>
                  <div className=" d-flex flex-row guarantor-card align-items-center">
                    <div className="mx-3">
                      <div className="user-profile">
                        <span>{this.state.officer.firstname[0]}</span>
                      </div>
                    </div>
                    <div className="py-3 w-100 pr-3">
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <h5 className="font-weight-bold">
                          {this.state.officer.firstname}{" "}
                          {this.state.officer.middlename}{" "}
                          {this.state.officer.surname}{" "}
                        </h5>
                      </div>
                      <div className="d-flex flex-row align-items-center flex-fill">
                        <div>
                          <small className="font-weight-bold">Nationa ID</small>
                          <div>{this.state.officer.id_number}</div>
                        </div>
                        <div className="mx-3 content-divider"> </div>
                        <div>
                          <small className="font-weight-bold">
                            Phone Number
                          </small>
                          <div>{this.state.officer.msisdn}</div>
                        </div>
                      </div>
                      <div className=" mt-2 d-flex flex-row justify-content-between align-items-center w-100">
                        <div className="badge badge-primary px-3 d-inline-block">
                          Officer
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  componentDidMount = () => {
    this.fetch();
  };

  fetch = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
      id: this.props.match.params.id
    };

    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");
    fetch(`${window.server}/loans?${urlParams}`, {
      headers: {
        Authorization: localStorage.token
      }
    }).then(response => response.json()).then(response => {
      console.log("LOANS DETAILS", response);

      let data = [];
      response.data.map((d, i) => {
        data.push({
          loan_amount: "KES " +
            parseFloat(
              parseFloat(d.loan_amount)
            ).toLocaleString(),
          loan_balance: "KES " +
            parseFloat(
              parseFloat(d.loan_balance)
            ).toLocaleString(),
          amount_paid: "KES " +
            parseFloat(
              parseFloat(d.amount_paid)
            ).toLocaleString(),
          loan_status: { 3: "Disbursement Failed", 2: "Paid", 1: "Active", 0: "Pending" }[d.status],
          interest_calculated: { 1: "Calculated", 0: "Not Calculated" }[d.interest_calculated],
          last_interest_calculated: moment(d.last_interest_calc_date).format('Do MMMM YYYY, h:mm:ss a'),
          created_at: moment(d.created_at).format('Do MMMM YYYY, h:mm:ss a'),
        });
      });

      this.setState({ data: data[0], ...response.data[0] });
    })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };
}

export default Details;
