import React, { Component } from "react";
import axios from 'axios';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Route, Link, Switch } from "react-router-dom";
// import Details from "./clients/details";
import {
  Plus,
  Edit,
  Check,
  AlertTriangle,
  ThumbsUp,
  RefreshCcw,
  Edit2,
  UserX,
  LogOut,
  LogIn,
  UserPlus,
  CheckCircle,
  Briefcase,
  DollarSign,
  FilePlus,
  RefreshCw,
  XCircle,
  Bell,
  BellOff
} from "react-feather";
import Tabs from "../../components/tabs";
import Details from "./details";
import Modal from "../../components/modal";
import Form from "../../components/form";
import AgentLoans from "./loans";
import AgentAccount from "./account";


class AgentsView extends Component {

  constructor(props) {

    super(props);

    //Edit profile
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeOtherNames = this.onChangeOtherNames.bind(this);
    this.onChangeIdNumber = this.onChangeIdNumber.bind(this);
    this.onChangeMsisdn = this.onChangeMsisdn.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    //Edit account
    this.onChangeAgentNumber = this.onChangeAgentNumber.bind(this);
    this.onChangeStoreNumber = this.onChangeStoreNumber.bind(this);
    this.onChangeTillNumber = this.onChangeTillNumber.bind(this);
    this.onChangeLimit = this.onChangeLimit.bind(this);
    this.onChangeBusinessName = this.onChangeBusinessName.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.onChangeAccountStatus = this.onChangeAccountStatus.bind(this);
    this.onChangeOwnerName = this.onChangeOwnerName.bind(this);
    this.onChangeOwnerMsisdn = this.onChangeOwnerMsisdn.bind(this);
    this.onChangeOperator = this.onChangeOperator.bind(this);
    this.onChangeVerified = this.onChangeVerified.bind(this);
    this.onSubmitAccount = this.onSubmitAccount.bind(this);

    //Reset Password
    this.onChangeAgentPassword = this.onChangeAgentPassword.bind(this);
    this.onSubmitPassword = this.onSubmitPassword.bind(this);

    this.state = {
      first_name: '',
      other_names: '',
      id_number: '',
      msisdn: '',
      status: '',
      agent_no: '',
      store_number: '',
      limit: '',
      business_name: '',
      location: '',
      owner_name: '',
      owner_msisdn: '',
      operator_msisdn: '',
      verified_owner: '',
      password: '',
    }
  }
  onChangeFirstName(e) {
    this.setState({ first_name: e.target.value })
  }
  onChangeOtherNames(e) {
    this.setState({ other_names: e.target.value })
  }
  onChangeIdNumber(e) {
    this.setState({ id_number: e.target.value })
  }
  onChangeMsisdn(e) {
    this.setState({ msisdn: e.target.value })
  }
  onChangeStatus(e) {
    this.setState({ status: e.target.value })
  }
  onChangeAgentNumber(e) {
    this.setState({ agent_no: e.target.value })
  }
  onChangeStoreNumber(e) {
    this.setState({ store_number: e.target.value })
  }
  onChangeTillNumber(e) {
    this.setState({ till_number: e.target.value })
  }
  onChangeLimit(e) {
    this.setState({ limit: e.target.value })
  }
  onChangeBusinessName(e) {
    this.setState({ business_name: e.target.value })
  }
  onChangeLocation(e) {
    this.setState({ location: e.target.value })
  }
  onChangeOwnerName(e) {
    this.setState({ owner_name: e.target.value })
  }
  onChangeOwnerMsisdn(e) {
    this.setState({ owner_msisdn: e.target.value })
  }
  onChangeOperator(e) {
    this.setState({ operator_msisdn: e.target.value })
  }
  onChangeVerified(e) {
    this.setState({ verified_owner: e.target.value })
  }
  onChangeAccountStatus(e) {
    this.setState({ status: e.target.value })
  }
  onChangeAgentPassword(e) {
    this.setState({ password: e.target.value })
  }

  onSubmit(e) {
    e.preventDefault()

    const userObject = {
      first_name: this.state.first_name,
      other_names: this.state.other_names,
      id_number: this.state.id_number,
      msisdn: this.state.msisdn,
      status: this.state.status,
    };

    let agent = this.props.match.params.agent_id;

    axios.patch(`${window.server}/agents/${agent}`, userObject, { headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*", Authorization: localStorage.token } })
      .then((res) => {
        //console.log(res.data)
        ToastsStore.success("Agent Profile Has Been Updated Successfully");
        window.location.reload();
      }).catch((error) => {
        //console.log(error)
        ToastsStore.error("Error. Kindly Try Again.");
      });

    //this.setState({ first_name: ''})
  }
  onSubmitAccount(e) {
    e.preventDefault()

    const userObjectAccount = {
      agent_no: this.state.agent_no,
      store_number: this.state.store_number,
      limit: this.state.limit,
      business_name: this.state.business_name,
      location: this.state.location,
      ownername: this.state.ownername,
      till_number: this.state.till_number,
      ownermsisdn: this.state.ownermsisdn,
      operator: this.state.operator,
      verified: this.state.verified,
      status: this.state.status,
    };

    let agentaccount = this.state.agent_account_id;
    //console.log(agentaccount);

    axios.patch(`${window.server}/agent-accounts/${agentaccount}`, userObjectAccount, { headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*", Authorization: localStorage.token } })
      .then((res) => {
        //console.log(res.data)
        ToastsStore.success("Agent Account Has Been Updated Successfully.");
        window.location.reload();
      }).catch((error) => {
        //console.log(error)
        ToastsStore.error("Error. Kindly Try Again.");
      });

    //this.setState({ first_name: ''})
  }
  onSubmitPassword(e) {
    e.preventDefault()

    const userObject = {
      password: "",
    };

    let agent = this.props.match.params.agent_id;

    axios.patch(`${window.server}/agents/${agent}`, userObject, { headers: { 'Content-Type': 'application/json', Authorization: localStorage.token } })
      .then((res) => {
        //console.log(res.data)
        ToastsStore.success("Password Has Been Reset Successfully");
        window.location.reload();
      }).catch((error) => {
        //console.log(error)
        ToastsStore.error("Error. Kindly Try Again.");
      });

  }
  state = {
    currentRoute: "",
    editModal: false,
    editModalAccount: false,
    leaveModal: false,
    response: { data: [] }
  };
  render() {
    let user = [];
    const role = localStorage.getItem("role_id");

    return (
      <div className="bg-light">
        <div className="card border-0 m-3 user-card bg-light">
          <div className="text-mute pt-3 pl-3">
            <small className="text-mute">Agents {'>'} View</small>
          </div>

          <div className="profile p-3 d-md-flex flex-row align-items-center justify-content-between">
            <div className="d-md-flex flex-row align-items-center">
              <div className="border avatar-lg bg-light d-flex flex-row align-items-center justify-content-center">
                <span className="initials">
                  {this.state.first_name && this.state.first_name[0]}
                </span>
              </div>
              <div className="ml-md-4 my-3 my-md-0">
                <h4 className="text-capitalize">
                  {this.state.first_name} {this.state.other_names} {" "}
                </h4>
                <div>+{this.state.msisdn && this.state.msisdn}</div>
                <div className="ml-2 mt-1">
                  <span className="badge badge-secondary px-2">Agent</span>
                </div>
              </div>
            </div>

            <div className="d-flex flex-row flex-wrap member-view ">
              <div className="d-md-flex flex-row text-center flex-wrap admin-actions justify-content-end">

                <button
                  onClick={() => {
                    this.setState({ editModalPassword: true });
                  }}
                  className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-info mr-3">
                  <RefreshCw size={18} />
                  <span className="pl-1 font-weight-bold">Reset Password</span>
                </button>

                <button
                  onClick={() => {
                    this.setState({ editModal: true });
                  }}
                  className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-success mr-3"
                >
                  <Edit size={18} />
                  <span className="pl-1 font-weight-bold">Edit profile</span>
                </button>

                <button
                  onClick={() => {
                    this.setState({ editModalAccount: true });
                  }}
                  className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary mr-3"
                >
                  <Edit size={18} />
                  <span className="pl-1 font-weight-bold">Edit account</span>
                </button>
               
                {this.state.msisdn && (
                  <div>

                <Link
                      to={"/upload-documents/"+this.state.id+"/"+this.state.agent_id}
                      className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-danger mr-3"
                    >
                      <FilePlus size={18} />
                  <span className="pl-1 font-weight-bold">Upload Documents</span>
                </Link>
               

                <Link
                to={"/view-documents/"+this.state.id+"/"+ this.state.agent_id}
                className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-warning mr-3"
                >
                <BellOff size={18} />
                <span className="pl-1 font-weight-bold">View Documents</span>
                </Link>
                </div>


                
                  )}
                

              </div>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-between">
            {this.state.msisdn && (
              <Tabs
                tabs={[
                  {
                    label: "DETAILS",
                    link: "/agent-view/details/" + this.props.match.params.id + "/" + this.props.match.params.agent_id
                  },
                  {
                    label: "ACCOUNT",
                    link: "/agent-view/account/" + this.props.match.params.id + "/" + this.props.match.params.agent_id
                  },
                  {
                    label: "LOANS",
                    link: "/agent-view/loans/" + this.props.match.params.id + "/" + this.props.match.params.agent_id
                  }


                ]}
              ></Tabs>
            )}
          </div>
        </div>
        <Route path="/agent-view/details/:id/:agent_id" exact component={Details} />
        <Route path="/agent-view/loans/:id/:agent_id" exact component={AgentLoans} />
        <Route path="/agent-view/account/:id/:agent_id" exact component={AgentAccount} />



        <Modal
          visible={this.state.editModal}
          close={() => this.setState({ editModal: false })}
        >
          <h5 className="font-weight-bold" style={{ margin: "15px" }}>Update Agent Details</h5>

          <form onSubmit={this.onSubmit}>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">First Name</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.first_name} onChange={this.onChangeFirstName} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Other Names</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.other_names} onChange={this.onChangeOtherNames} />
            </div>
            {/* <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">ID Number</span>
              <input className= "form-control bg-light text-input"type="text" value={this.state.id_number} onChange={this.onChangeIdNumber}/>
            </div> */}
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Phone Number</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.msisdn} onChange={this.onChangeMsisdn} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Status</span>
              <select className="form-control bg-light" value={this.state.status} onChange={this.onChangeStatus}>
                <option value="1">Active</option>
                <option value="2">In Active</option>
              </select>
            </div>
            <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
              <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Update</button>
            </div>
          </form>
        </Modal>
        <Modal
          visible={this.state.editModalAccount}
          close={() => this.setState({ editModalAccount: false })}
        >
          <h5 className="font-weight-bold" style={{ margin: "15px" }}>Update Agent Account</h5>

          <form onSubmit={this.onSubmitAccount}>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Agent Number</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.agent_no} onChange={this.onChangeAgentNumber} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Store Number</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.store_number} onChange={this.onChangeStoreNumber} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Till Number</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.till_number} onChange={this.onChangeTillNumber} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Limit (Kshs.)</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.limit} onChange={this.onChangeLimit} disabled={role == 1 ? true : false} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Business Name</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.business_name} onChange={this.onChangeBusinessName} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Location</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.location} onChange={this.onChangeLocation} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Owner Name</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.owner_name} onChange={this.onChangeOwnerName} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Owner Phone Number</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.owner_msisdn} onChange={this.onChangeOwnerMsisdn} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Operator Phone Number</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.operator_msisdn} onChange={this.onChangeOperator} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Verified Owner</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.verified_owner} onChange={this.onChangeVerified} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Account Status</span>
              <select className="form-control bg-light" value={this.state.status} onChange={this.onChangeAccountStatus}>
                <option value="1">Active</option>
                <option value="2">In Active</option>
              </select>
            </div>
            <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
              <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Update</button>
            </div>
          </form>
        </Modal>
        <Modal
          visible={this.state.editModalStatements}
          close={() => this.setState({ editModalStatements: false })}
        >
          <h5 className="font-weight-bold" style={{ margin: "15px" }}>Add Agent Statements</h5>
          <form onSubmit={this.onSubmitLimit}>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Month</span>
              <input className="form-control bg-light text-input" type="date" value={this.state.month} onChange={this.onChangeMonth
              } />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Client Value (Kshs.)</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.client_value} onChange={this.onChangeClientValue} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Safaricom Value (Kshs.)</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.safaricom_value} onChange={this.onChangeSafaricomValue} />
            </div>

            <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
              <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Update</button>
            </div>
          </form>
        </Modal>
        <Modal
          visible={this.state.editModalPassword}
          close={() => this.setState({ editModalPassword: false })}
        >
          <h5 className="font-weight-bold" style={{ margin: "15px" }}>Reset Agent Password</h5>

          <form onSubmit={this.onSubmitPassword}>
            {/*  <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Enter New Password</span>
              <input className= "form-control bg-light text-input"type="text" value={this.state.password} onChange={this.onChangeAgentPassword}/>
            </div> */}
            <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
              <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Reset</button>
            </div>
          </form>
        </Modal>
        <ToastsContainer store={ToastsStore} />
      </div>
    );
  }

  componentDidMount = () => {
    this.fetchprofile();
    this.fetchaccount();
  };

  fetchprofile = () => {
    let user_id = this.props.match.params.id;
    fetch(`${window.server}/agents?id=${user_id}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        //console.log(response.data[0]);
        this.setState({ ...response.data[0] });
      })
      .catch(d => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };

  fetchaccount = () => {
    let agent = this.props.match.params.agent_id;
    fetch(`${window.server}/agent-accounts?agent_id=${agent}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        //console.log(response.data[0]);
        this.setState({ ...response.data[0] });
      })
      .catch(d => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };

}

export default AgentsView;
