import React, { Component } from "react";
import moment from "moment";
import { DollarSign, User, LogOut } from "react-feather";

class Details extends Component {
  randomize = (start, end) => {
    return Math.floor(Math.random() * end) + start;
  };

  state = {
    data: {},
    salaryVisible: false,
    exitInfo: {}
  };
  render() {
    return (
      <div className="p-3">
        <div className="row">
          <div className="col-md-7">
            <ul className="list-group user-details table-card shadow border-0 w-100">
              <div className="p-3 bg-light font-weight-bold">
                <User size={18}></User> Admin Details
              </div>
              {Object.keys(this.state.data).map(d => (
                <li className="list-group-item d-flex flex-row  text-dark">
                  <div className="user-detail-title font-weight-bold text-capitalize">
                    {d.replace(/_/g, " ")}
                  </div>
                  <div>{this.state.data[d]}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount = () => {
    this.fetch();
  };

  fetch = () => {
    let user_id = this.props.match.params.id;
    fetch(`${window.server}/users?id=${user_id}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        //console.log(response);
        let data = [];
        response.data.map(d => {
          data.push({
            email: `${d.email}`,
            //ID_number: d.id_number,
            "Date Created": d.createdAt,
            date_updated: moment(d.updatedAt).format('Do MMMM YYYY, h:mm:ss a')
          });
        });
        response.data = data;
        this.setState({ data: data[0] });
      })
      .catch(d => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };
}

export default Details;
