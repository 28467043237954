import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class MemberPayments extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false
  };
  timeout = null;
  render() {
    return (
      <div className="">
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Filter
              getFilter={filter => {
                setTimeout(() => {
                  this.setState({
                    filter
                  });
                  // //console.log(this.state);
                }, 0);
              }}
              dateRange={["startdate", "enddate"]}
            />
            <Table
              search={["full_names"]}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={params => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchPayments = () => {
    this.setState({ table_loading: true });
    let { query } = this.state;
    let f = this.state.filter;
    let filter = f;

    // let str = "";
    if (filter.branch_code) {
      let branch = window.branches.filter(
        d => d.branch_code === this.state.filter.branch_code
      );
      // alert(branch[0].id);
      query.branch_id = branch[0].id;
      // str += "&branch_id=" + branch[0].id;
    }

    //console.log(query);

    delete query.branch_code;

    //console.log(filter.enddate, moment().format("YYYY-MM-DD"));

    if (
      filter.startdate === filter.enddate &&
      filter.enddate === moment().format("YYYY-MM-DD")
    ) {
      filter.startdate = moment()
        .subtract(2, "days")
        .format("YYYY-MM-DD");
    } else {
      query["limit"] = query["$limit"];
      query["skip"] = query["$skip"];
      query.enddate = moment(query.enddate)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }

    delete query["$limit"];
    delete query["$skip"];

    let q = {
      ...filter,
      ...query
    };

    if (!q.branch_code) {
      delete q.branch_id;
    }

    //console.log(q);
    // delete q.branch_code;

    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");
    //console.log(urlParams);
    fetch(
      `${window.server}/utils/paymentsHistory?${urlParams}&user_id=${this.props.match.params.id}`,
      {
        headers: {
          Authorization: localStorage.token
        }
      }
    )
      .then(response => response.json())
      .then(response => {
        //console.log(response);
        let data = [];
        response.data.map((d, i) => {
          data.push({
            // account_id: d.account_id,
            // account_new_bal: d.account_new_bal,
            // account_prev_bal: d.account_prev_bal,
            // cr: d.cr,
            // created_at: d.created_at,
            Time: moment(d.created_at).format("MMM Do YYYY, h:mm:ss a"),
            // created_by: d.created_by,
            // description: d.description,
            full_names: (
              <span className="text-capitalize">
                {d.full_names.toLowerCase()}
              </span>
            ),
            Amount: "KES " + d.cr,
            // mpesa_code: d.mpesa_desc ? d.mpesa_desc.split(" ")[5] : "",
            // guarantor_id: d.guarantor_id,
            // id: d.id,
            // investment_verified: d.investment_verified,
            // jijenge_verified: d.jijenge_verified,
            // last_repayment_date: d.last_repayment_date,
            // loan_amount: d.loan_amount,
            // loan_amount_disbursed: d.loan_amount_disbursed,
            // loan_amount_paid: d.loan_amount_paid,
            // loan_end_date: d.loan_end_date,
            // loan_start_date: d.loan_start_date,
            // loan_id: d.loan_id,
            Installment: "KES " + d.loan_installments.toLocaleString(),
            Arrears: this.calculateArrears(
              d.loan_amount_paid,
              d.loan_installments,
              d.loan_start_date,
              d.loan_end_date,
              d.loan_amount - d.loan_amount_paid
            ),
            // "Daily Balance": "KES " + d.loan_installments.toLocaleString()
            // loan_start_date: d.loan_start_date,
            // loan_status: d.loan_status,
            // officer_id: d.officer_id,
            // "Previous Balance": d.prev_bal,
            "New Loan Balance":
              "KES " + (d.loan_amount - d.new_bal).toLocaleString(),
            // "New Balance": d.new_bal,
            // times_defaulted: d.times_defaulted,
            // times_inactive: d.times_inactive,
            // updated_at: d.updated_at,
            // user_id: d.user_id,
            action: (
              <Link
                to={"/loanView/details/" + d.loan_id}
                className="btn btn-sm btn-primary"
              >
                View
              </Link>
            )
          });
        });
        let dts = {};
        dts.data = data;
        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false
        });
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function() {
        $t.fetchPayments();
      }, 100);
    }
  }

  calculateArrears = (
    loan_amount_paid,
    loan_installments,
    loan_start_date,
    loan_end_date,
    loan_balance
  ) => {
    let start = moment(loan_start_date);
    let end = moment();

    let amount = loan_installments * end.diff(start, "days") - loan_amount_paid;

    if (amount > loan_balance) {
      amount = loan_balance;
    }

    if (amount <= 0) {
      amount = <span className="badge badge-success px-2">Cleared</span>;
    }

    if (typeof amount === "number") amount = "KES " + amount.toLocaleString();

    return amount;
  };
}

export default MemberPayments;
