import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";
import Form from "../../components/form";

class Invoices extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
    InvoiceModal: false,
    inputs: {
      type: "",
      amount: "",
      discount: "",
      due_date: "",
      mode_of_payment: "",
      description: ""
    }
  };
  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="Invoices"
          buttons={[
            {
              text: "Add an Invoice",
              onClick: () => {
                this.setState({ InvoiceModal: true });
              }
            }
          ]}
        ></Nav>
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              search={["firstname"]}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={params => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>

        <Modal
          visible={this.state.InvoiceModal}
          close={() => this.setState({ InvoiceModal: false })}
        >
          <h5 className="font-weight-bold">Add a Invoice</h5>
          <div className="position-relative">
            <Form
              submitText={"Submit"}
              back={false}
              inputs={[
                {
                  label: "Type",
                  name: "type",
                  value: "",
                  type: "select",
                  options: [
                    { name: "Self Guarantee", value: 0 },
                    { name: "Needs Guarantors", value: 1 },
                    { name: "Pool", value: 2 }
                  ]
                },
                {
                  label: "Amount",
                  name: "amount",
                  value: "",
                  type: "number"
                },
                {
                  label: "Discount",
                  name: "discount",
                  value: "",
                  type: "number"
                },
                {
                  label: "Due Date",
                  name: "due_date",
                  value: "",
                  type: "date"
                },
                {
                  label: "Mode Of Payment",
                  name: "mode_of_payment",
                  value: "",
                  type: "select",
                  options: [
                    { name: "Products", value: 0 },
                    { name: "Services", value: 1 }
                  ]
                },
                {
                  label: "Description",
                  name: "description",
                  value: "",
                  type: "textarea"
                }
              ]}
              submit={data => this.addInvoice(data)}
            />
          </div>
        </Modal>
      </div>
    );
  }

  fetchInvoices = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query
    };

    delete q["$sort[id]"];

    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");

    // //console.log(urlParams);
    fetch(`${window.server}/invoices?${urlParams}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        //console.log(response);

        let data = [];
        response.data.map((d, i) => {
          data.push({
            full_name: `${d.firstname} ${d.middlename} ${d.surname}`,
            phone_number: d.msisdn,
            iD_number: d.id_number,
            KRA_pin: d.kra_pin,
            // identity_type: d.identity_type,
            // sub_county_id: d.sub_county_id,
            // ward_id: d.ward_id,
            village: d.village,
            action: (
              <div className="d-flex flex-row">
                <Link
                  to={"/member-view/details/" + d.customer_id}
                  className="btn btn-sm btn-primary px-3 btn-round"
                >
                  Viewinvoices
                </Link>
              </div>
            )
          });
        });

        let dts = {};
        // dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false
        });
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  addInvoice = data => {
    if (!window.confirm("Are you sure that you want add this Invoice?"))
      return false;
    let postData = data;

    if (postData.password !== postData.password_confirm) {
      alert("The passwords provided do not match");
      return false;
    }

    delete postData.password_confirm;
    postData.group_id = parseInt(postData.group_id);

    fetch(`${window.server}/invoices`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(postData)
    })
      .then(response => response.json())
      .then(response => {
        //console.log(response);
        if (response.code) {
          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
        } else {
          //console.log(response);
          this.fetchInvoices();

          this.setState({ InvoiceModal: false });
        }
      })
      .catch(d => {
        //console.log("Error saving the data");
        //console.log(d);
        this.setState({ InvoiceModal: false });
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function() {
        $t.fetchInvoices();
      }, 100);
    }
  }
}

export default Invoices;
