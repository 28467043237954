import React, { Component, setState } from "react";
import axios from 'axios';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Edit, Plus, Target, Circle, Smartphone, PlusCircle, CheckCircle, ArrowRightCircle } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class AgentStep extends Component {
    constructor(props) {
        super(props);
        this.onSubmitAccount = this.onSubmitAccount.bind(this);
        this.handleChangeComment = this.handleChangeComment.bind(this);
    }
    state = {
        fetchData: [],
        tableData: { data: [] },
        response: { data: [] },
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
        agent_id: this.props.match.params.agent_id,
        step: '0',
        comment: ""
    };

    handleChangeComment(e) {
        this.setState({ comment: e.target.value });
    }

    onSubmitAccount(e) {
        e.preventDefault()
        const userRegStatus = {
            agent_id: this.state.agent_id,
            step: this.state.fetchData.length > 0 ? (parseInt(this.state.fetchData[this.state.fetchData.length - 1].step) + 1).toString() : this.state.step,
            comment: this.state.comment,
            date: moment().format('YYYY-MM-DD HH:mm:ss'),
        };
        axios.post(`${window.server}/reg-status`, userRegStatus, { headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*", Authorization: localStorage.token } })
            .then((res) => {
                console.log(res.data)
                ToastsStore.success("Agent Registration steps has Been Updated Successfully.");
                window.location.reload();
            }).catch((error) => {
                console.log(error)
                ToastsStore.error("Error. Kindly Try Again.");
            });
        this.setState({ comment: '' })
    }
    timeout = null;
    render() {
        return (
            <div className="">
                <div className="mt-3 border-0 card shado mx-3 bg-white">
                    <div className="p-4" style={{ display: 'flex', justifyContent: 'space-between', flex: '5' }}>
                        <div style={{ textAlign: 'center', flex: '3', borderRight: '1px SOLID lightgray', margin: '2px', padding: '6px', }} className="bg-white p-4">
                            <Table
                                search={["step"]}
                                sort="id"
                                sortDirection={-1}
                                data={this.state.tableData}
                                fetch={params => {
                                    this.setState({ query: params });
                                }}
                                loading={this.state.table_loading}
                                fetchError={this.state.tableError}
                            />
                        </div>

                        <div style={{ flex: '2', border: '2px solid #fafafa', margin: '2px', padding: '6px', border: '1px dashed #F8F9FA' }}>
                            <span className="pl-1 font-weight-bold font-size-22">ADD AGENT REGISTRATION STATUS</span>
                            <form onSubmit={this.onSubmitAccount}>
                                <div className="mx-3 d-inline-block mb-3" style={{ flex: '1', width: '100%' }}>
                                    <input className="form-control bg-light text-input" type="hidden" value={this.state.fetchData.length} onChange={this.handleChangeComment} name="step" id="step" style={{ minHeight: '100px' }} readOnly />
                                </div>
                                <div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">Comments</span>
                                    <textarea className="form-control bg-light text-input" type="text" value={this.state.comment} onChange={this.handleChangeComment} id="comment" style={{ minHeight: '100px' }} placeholder="Add your comment" required />
                                </div>

                                <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                                    <button className="btn btn-primary px-2 px-md-5 ml-2 float-right font-weight-bold">Next Step <ArrowRightCircle /></button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div >
        );
    }

    fetchMemberWallet = () => {
        this.setState({ table_loading: true });
        let q = {
            ...this.state.query,
            agent_id: this.props.match.params.agent_id,
        };
        let urlParams = Object.entries(q)
            .map(e => e.join("="))
            .join("&");
        fetch(`${window.server}/reg-status?${urlParams}`, {
            headers: {
                Authorization: localStorage.token
            }
        })
            .then(response => response.json())
            .then(response => {
                let data = [];
                this.setState({ fetchData: response.data })
                let gLength = response.data.length;
                response.data.map((d, i) => {
                    data.push({
                        step: gLength - i,
                        comment: d.comment,
                        date: moment(d.date).format('DD-MM-YYYY HH:mm:ss'),
                        status: <CheckCircle style={{ color: 'green ' }} />
                    });
                });

                let dts = {};
                dts.data = data.reverse();

                this.setState({
                    tableData: { ...response, ...dts },
                    response,
                    table_loading: false
                });
            })
            .catch(d => {
                this.setState({ table_loading: false });
                console.error(d);
            });
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
            JSON.stringify({ ...prevState.query, ...prevState.filter })
        ) {
            let $t = this;

            clearTimeout(this.timeout);
            this.timeout = setTimeout(function () {
                $t.fetchMemberWallet();
            }, 100);
        }
    }
}

export default AgentStep;
