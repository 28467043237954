import React, { Component } from "react";
import { Route } from "react-router-dom";
import axios from 'axios';
import { ToastsStore } from 'react-toasts';
import {
  Edit,
  RefreshCw
} from "react-feather";
import Tabs from "../../components/tabs";
import Details from "./details";
import Modal from "../../components/modal";

class AdminView extends Component {
  constructor(props) {

    super(props);

    //Edit profile
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeOtherNames = this.onChangeOtherNames.bind(this);
    this.onChangeIdNumber = this.onChangeIdNumber.bind(this);
    this.onChangeMsisdn = this.onChangeMsisdn.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    //Edit account
    this.onChangeAgentNumber = this.onChangeAgentNumber.bind(this);
    this.onChangeStoreNumber = this.onChangeStoreNumber.bind(this);
    this.onChangeLimit = this.onChangeLimit.bind(this);
    this.onChangeBusinessName = this.onChangeBusinessName.bind(this);
    this.onChangeAccountStatus = this.onChangeAccountStatus.bind(this);
    this.onSubmitAccount = this.onSubmitAccount.bind(this);

    this.state = {
      first_name: '',
      other_names: '',
      id_number: '',
      msisdn: '',
      status: '',
      agent_no: '',
      store_number: '',
      limit: '',
      business_name: '',
      location: '',
    }
  }
  onChangeFirstName(e) {
    this.setState({ first_name: e.target.value })
  }
  onChangeOtherNames(e) {
    this.setState({ other_names: e.target.value })
  }
  onChangeIdNumber(e) {
    this.setState({ id_number: e.target.value })
  }
  onChangeMsisdn(e) {
    this.setState({ msisdn: e.target.value })
  }
  onChangeStatus(e) {
    this.setState({ status: e.target.value })
  }
  onChangeAgentNumber(e) {
    this.setState({ agent_no: e.target.value })
  }
  onChangeStoreNumber(e) {
    this.setState({ store_number: e.target.value })
  }
  onChangeLimit(e) {
    this.setState({ limit: e.target.value })
  }
  onChangeBusinessName(e) {
    this.setState({ business_name: e.target.value })
  }
  onChangeLocation(e) {
    this.setState({ location: e.target.value })
  }
  onChangeAccountStatus(e) {
    this.setState({ status: e.target.value })
  }

  onSubmit(e) {
    e.preventDefault()

    const userObject = {
      first_name: this.state.first_name,
      other_names: this.state.other_names,
      id_number: this.state.id_number,
      msisdn: this.state.msisdn,
      status: this.state.status,
    };

    let agent = this.props.match.params.agent_id;

    axios.patch(`${window.server}/agents/${agent}`, userObject, { headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*", Authorization: localStorage.token } })
      .then((res) => {
        //console.log(res.data)
        ToastsStore.success("Agent Profile Has Been Updated Successfully");
        window.location.reload();
      }).catch((error) => {
        //console.log(error)
        ToastsStore.error("Error. Kindly Try Again.");
      });

    //this.setState({ first_name: ''})
  }
  onSubmitAccount(e) {
    e.preventDefault()

    const userObject = {
      agent_no: this.state.agent_no,
      store_number: this.state.store_number,
      limit: this.state.limit,
      business_name: this.state.business_name,
      location: this.state.location,
      status: this.state.status,
    };

    let agentaccount = this.state.agent_account_id;

    axios.patch(`${window.server}/agent-accounts/${agentaccount}`, userObject, { headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*", Authorization: localStorage.token } })
      .then((res) => {
        //console.log(res.data)
        ToastsStore.success("Agent Account Has Been Updated Successfully.");
        window.location.reload();
      }).catch((error) => {
        //console.log(error)
        ToastsStore.error("Error. Kindly Try Again.");
      });

    //this.setState({ first_name: ''})
  }
  state = {
    currentRoute: "",
    editModal: false,
    leaveModal: false,
    response: { data: [] },
  };
  render() {
    let user = [];

    return (
      <div className="bg-light">
        <div className="card border-0 m-3 user-card">
          <div className="text-mute pt-3 pl-3">
            <small className="text-mute">Admin {'>'} View</small>
          </div>

          <div className="profile p-3 d-md-flex flex-row align-items-center justify-content-between">
            <div className="d-md-flex flex-row align-items-center">
              <div className="border avatar-lg bg-light d-flex flex-row align-items-center justify-content-center">
                <span className="initials">
                  {this.state.email && this.state.email[0].toUpperCase()}
                </span>
              </div>
              <div className="ml-md-4 my-3 my-md-0">
                <h4 className="text-small">
                  {this.state.email}
                </h4>
                <div className="ml-2 mt-1">
                  <span className="badge badge-secondary px-2">Admin</span>
                </div>
              </div>
            </div>

            <div className="d-flex flex-row flex-wrap member-view ">
              <div className="d-md-flex flex-row text-center flex-wrap admin-actions justify-content-end">

                <button className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary mr-3">
                  <RefreshCw size={18} />
                  <span className="pl-1 font-weight-bold">Reset Password</span>
                </button>

                {/* <button
                  onClick={() => {
                    this.setState({ editModal: true });
                    //console.log(this.state.response.data[0]);
                  }}
                  className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3"
                >
                  <Edit size={18} />
                  <span className="pl-1 font-weight-bold">Edit profile</span>
                </button> */}

              </div>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-between">
            {this.state.msisdn && (
              <Tabs
                tabs={[
                  {
                    label: "DETAILS",
                    link: "/admin-view/details/" + this.props.match.params.id
                  }

                ]}
              ></Tabs>
            )}
          </div>
        </div>
        <Route path="/admin-view/details/:id" exact component={Details} />

        <Modal
          visible={this.state.editModal}
          close={() => this.setState({ editModal: false })}
        >
          <h5 className="font-weight-bold" style={{ margin: "15px" }}>Update Agent Details</h5>

          <form onSubmit={this.onSubmit}>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">First Name</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.first_name} onChange={this.onChangeFirstName} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Other Names</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.other_names} onChange={this.onChangeOtherNames} />
            </div>
            {/* <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">ID Number</span>
              <input className= "form-control bg-light text-input"type="text" value={this.state.id_number} onChange={this.onChangeIdNumber}/>        
            </div> */}
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Phone Number</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.msisdn} onChange={this.onChangeMsisdn} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Status</span>
              <select className="form-control bg-light" value={this.state.status} onChange={this.onChangeStatus}>
                <option value="1">Active</option>
                <option value="2">In Active</option>
              </select>
            </div>
            <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
              <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold">Update</button>
            </div>
          </form>
        </Modal>
      </div>
    );
  }

  componentDidMount = () => {
    this.fetch();
  };

  fetch = () => {
    let user_id = this.props.match.params.id;
    fetch(`${window.server}/users?id=${user_id}`, {
      headers: {
        Authorization: localStorage.token
      }
    }).then(response => response.json()).then(response => {
      console.log("users", response.data[0]);
      this.setState({ ...response.data[0] });
    })
      .catch(d => {
        this.setState({ tableError: true });
      });
  };
}

export default AdminView;
