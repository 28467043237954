import React, { Component } from "react";
import Nav from "../../components/Nav";
import {
  Book,
  MessageSquare,
  PlusCircle,
  FilePlus,
  Send,
  Upload
} from "react-feather";
import { Link } from "react-router-dom";

class SMS extends Component {
  state = {
    cards: [
      {
        icon: <MessageSquare size={50}></MessageSquare>,
        title: "All Sent SMS",
        to: "sms-list"
      },
      {
        icon: <Upload size={50}></Upload>,
        title: "Outbox",
        to: "sms-list"
      },
      {
        icon: <Send size={50}></Send>,
        title: "Send SMS",
        to: "sms-create"
      }
    ]
  };
  render() {
    return (
      <div>
        <Nav name="SMS"></Nav>

        <div className="mt-3 table-card sms-table-card  border-0 card shado mx-3 shadow p-4 d-flex flex-column justify-content-center">
          <div className="row justify-content-center w-100">
            {this.state.cards.map((d, i) => (
              <div className="d-inline-block px-3 mb-4">
                <Link
                  to={d.to}
                  className="sms-card p-3 card d-flex flex-column align-items-center py-4"
                >
                  <div className="">{d.icon}</div>
                  <div className="mt-4">
                    <h5 className="font-weight-bold">{d.title}</h5>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default SMS;
