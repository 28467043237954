import React, { Component } from "react";
import Nav from "../../components/Nav";
import { Users } from "react-feather";
import moment from "moment";

class AddressBook extends Component {
  state = {
    addressBooks: [
      {
        name: "Sample address Book",
        description:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit.Earum velit unde recusandae ad maiores quas"
      },
      {
        name: "Sample address Book",
        description:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit.Earum velit unde recusandae ad maiores quas"
      },
      {
        name: "All inactive members",
        description:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit.Earum velit unde recusandae ad maiores quas"
      },
      {
        name: "Sample address Book",
        description:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit.Earum velit unde recusandae ad maiores quas"
      },
      {
        name: "Sample address Book",
        description:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit.Earum velit unde recusandae ad maiores quas"
      }
    ]
  };
  render() {
    return (
      <div>
        <Nav
          name="Address Books"
          buttons={[
            {
              text: "Add an Address Book",
              link: "address-book-create"
            }
          ]}
        ></Nav>
        <div className="mt-3 table-card sms-table-card  border-0 card shado mx-3 shadow p-4 ">
          <div className="">
            {this.state.addressBooks.map((d, i) => (
              <div
                className=" address-card my-3 d-flex flex-row align-items-center w-100"
                key={i}
                onClick={() => {
                  this.setState({ addressModal: false, activeAddressBook: i });
                }}
              >
                <div className="ico ml-3">
                  <Users size={30}></Users>
                </div>
                <div className="content p-3">
                  <h5 className="font-weight-bold">{d.name}</h5>
                  <div>
                    <>{d.description}</>
                  </div>
                  <div className="text-muted">
                    created {moment().format("Do MMMM YYYY, h:mm a")}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default AddressBook;
