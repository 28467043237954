import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class OkoaLoans extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false
  };

  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="All Loans"
        ></Nav>
        <div className="mt-3 border-0 card shado mx-3 bg-white">
          <div className="p-4">
            <Filter
              getFilter={filter => {
                setTimeout(() => {
                  this.setState({
                    filter
                  });
                }, 0);
              }}
              dateRange={["startdate", "enddate"]}
            />
            <Table
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={params => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  fetchLoans = () => {
    this.setState({ table_loading: true });
    function getFormattedDate(date) {
      var year = date.getFullYear();
      var month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : '0' + month;
      var day = date.getDate().toString();
      day = day.length > 1 ? day : '0' + day;
      return (new Date(year + '-' + month + '-' + day));
 }

    let q = {
      // ...this.state.filter,
      ...this.state.query
    };
    let urlParams = Object.entries(q).map(e => e.join("=")).join("&");
    let startdate = moment(this.state.filter.startdate).format("YYYY-MM-DD HH:mm:ss");
    let enddate = moment(this.state.filter.enddate).format("YYYY-MM-DD HH:mm:ss");

    fetch(`${window.server}/loans?created_at[$gte]=${startdate}&&created_at[$lte]=${enddate}&&${urlParams}`, {
      headers: {
        Authorization: localStorage.token
      }
    }).then(response => response.json()).then(response => {
        console.log("ALL LOANS",  response);

        let data = [];
        response.data.map((d, i) => {         
         //let nodays = Math.abs(moment(d.last_interest_calc_date).date()-moment(d.created_at).date()) -7;
        //  let now =moment(d.last_interest_calc_date).format('DD-MM-YYYY HH:mm:ss');
        //  let prev =moment(d.created_at).format('DD-MM-YYYY HH:mm:ss');
        //  let x =new Date(now);
        //  let y =new Date(prev);
        //  let diffTime = Math.abs(x - y);
        let nodays = moment(d.last_interest_calc_date).diff(moment(d.created_at), 'days') - 7;
        let prev = getFormattedDate(new Date(d.created_at));
        let current = getFormattedDate( new Date(d.last_interest_calc_date));
        let diffTime = Math.abs(current - prev);
         
         let activeloantag = "";
          if(nodays < 0){
            activeloantag = "badge badge-success"
          }else if(nodays >= 0 && nodays <= 3){
            activeloantag = "badge badge-myprimary"
          }else if(nodays >=4 && nodays <=7){
            activeloantag = "badge badge-primary"
          }else if(nodays > 7){
            activeloantag = "badge badge-danger"
          }
          data.push({
            //loan_id:d.loan_id,
            Client_Name: this.Capitalize(d.agent.first_name) + " " + this.Capitalize(d.agent.other_names),
            Agent_Number: d.agent_account.agent_no,
            Business_Name: d.agent_account.business_name.toUpperCase(),
            loan_amount: "KES " +
              parseFloat(
                parseFloat(d.loan_amount)
              ).toLocaleString(),
            loan_balance: "KES " +
              parseFloat(
                parseFloat(d.loan_balance)
              ).toLocaleString(),
            amount_paid: "KES " +
              parseFloat(
                parseFloat(d.amount_paid)
              ).toLocaleString(),
            outstanding_principle: "KES." + parseFloat(d.outstanding_principle).toFixed(2),
            interest_earned: "KES." + parseFloat(d.interest_earned).toFixed(2),
            // loan_status: { 3: <span className="badge badge-info">Disbursement Failed</span>, 2: <span className="badge badge-success">Paid</span>, 1: <span className="badge badge-primary">Active</span>, 0: <span className="badge badge-danger">Pending</span> }[d.status],
            loan_status:  { 3: <span className="badge badge-info">Disbursement Failed</span>, 2: <span>{ moment(d.last_interest_calc_date).diff(moment(d.created_at), 'days') > 7? <span className="badge badge-danger">PAID</span>: <span className="badge badge-success">PAID</span>}</span>, 1: <span className={activeloantag}>Active</span>, 0: <span className="badge badge-danger">Pending</span> }[d.status],
            //loan_status:  { 3: <span className="badge badge-info">Disbursement Failed</span>, 2: <span>{ Math.abs(moment(d.last_interest_calc_date).date()-moment(d.created_at).date()) > 7? <span className="badge badge-danger">PAID</span>: <span className="badge badge-success">PAID</span>}</span>, 1: <span className={activeloantag}>Active</span>, 0: <span className="badge badge-danger">Pending</span> }[d.status],
            //No_of_Days: moment(d.last_interest_calc_date).diff(moment(d.created_at), 'days'),
            No_of_Days: Math.ceil(diffTime / (1000 * 60 * 60 * 24)),                  
            loan_start_date: moment(d.created_at).format('DD-MM-YYYY HH:mm:ss'),
            last_interest_calculated: moment(d.last_interest_calc_date).format('DD-MM-YYYY HH:mm:ss'),
            //last_interest_calculated: moment(d.last_interest_calc_date).format('DD-MM-YYYY HH:mm:ss'),

            action: (
              <div className="d-flex flex-row">
                {/* <Link
                  to={"/loan-approve/" + d.request_id}
                  className="btn btn-sm btn-warning px-3 btn-round mr-3"
                >
                  Approve
                </Link> */}
                <Link
                  to={"/loan-view/details/" + d.id + "/" + d.loan_id}
                  className="btn btn-sm btn-primary px-3"
                >
                  View
                </Link>
              </div>
            )
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false
        });
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchLoans();
      }, 100);
    }
  }
}

export default OkoaLoans;
