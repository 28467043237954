import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";
import ReactJson from 'react-json-view';

class DefaultLimit extends Component {
    state = {
        tableData: { data: [] },
        response: { data: [] },
        tableError: false,
        query: {},
        filter: {},
        table_loading: false
    };
    timeout = null;
    render() {
        return (
            <div className="">
                <div className="mt-3 border-0 card shado mx-3 bg-white">
                    <div className="p-4">
                        <Table
                            search={["loan_amount", "loan_balance", "amount_paid", "status", "interest_calculated", "last_interest_calc_date"]}
                            sort="id"
                            sortDirection={-1}
                            data={this.state.tableData}
                            fetch={params => {
                                this.setState({ query: params });
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                        />
                    </div>
                    {this.state.json && (
            <Modal
              visible={true}
              close={() => {
                this.setState({ json: null });
              }}>
              <ReactJson
                displayDataTypes={false}
                displayObjectSize={false}
                src={this.state.json}
              />
            </Modal>
          )}
                </div>
            </div>
        );
    }

    fetchLoanRequests = () => {
        this.setState({ table_loading: true });

        let q = {
            // ...this.state.filter,
            ...this.state.query,
            "agent_account_id": this.props.match.params.agent_account_id
        };

        let urlParams = Object.entries(q)
            .map(e => e.join("="))
            .join("&");

        // //console.log(urlParams);
        fetch(`${window.server}/track-agent-accounts?${urlParams}`, {
            headers: {
                Authorization: localStorage.token
            }
        })
            .then(response => response.json())
            .then(response => {
                //console.log(response);

                let data = [];
                response.data.map((d, i) => {
                    data.push({
                        //loan_id: d.id,
                        agent_account_id: d.agent_account_id,
                        modified_by:d.modified_by,
                        new_limit:d.new_limit,
                        reason:d.reason,
                        new_data: d.new_data && (
                            <button
                              className='btn btn-sm btn-outline-success font-weight-bold'
                              onClick={() =>
                              this.setState({ json: JSON.parse(d.new_data) })
                            }>
                              view
                            </button>
                          ),
                          prev_data: d.prev_data && (
                            <button
                              className='btn btn-sm btn-outline-success font-weight-bold'
                              onClick={() =>
                              this.setState({ json: JSON.parse(d.prev_data) })
                            }>
                              view
                            </button>
                          ),
                      created_at:d.created_at,
                      updated_at:d.updated_at
                      // loan_balance: "KES " +
                        //     parseFloat(
                        //         parseFloat(d.loan_balance)
                        //     ).toLocaleString(),
                        // amount_paid: "KES " +
                        //     parseFloat(
                        //         parseFloat(d.amount_paid)
                        //     ).toLocaleString(),
                        // loan_status: { 3: <span className="badge badge-info">Disbursement Failed</span>, 2: <span className="badge badge-success">Paid</span>, 1: <span className="badge badge-primary">Active</span>, 0: <span className="badge badge-danger">Pending</span> }[d.status],
                        // interest_calculated: { 1: "Calculated", 0: "Not Calculated" }[d.interest_calculated],
                        // loan_start_date: moment(d.created_at).format('DD-MM-YYYY HH:mm:ss'),
                       // last_interest_calculated: moment(d.last_interest_calc_date).format('DD-MM-YYYY HH:mm:ss'),
                //         action: (
                //             <div className="d-flex flex-row">
                //                 <Link
                //                     to={"/loan-view/details/" + d.id + "/" + d.loan_id}
                //                     className="btn btn-sm btn-primary px-3"
                //                 >
                //                     View
                // </Link>
                //             </div>
                //         )
                    });
                });

                let dts = {};
                dts.data = data;

                this.setState({
                    tableData: { ...response, ...dts },
                    response,
                    table_loading: false
                });
            })
            .catch(d => {
                this.setState({ table_loading: false });
                console.error(d);
            });
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
            JSON.stringify({ ...prevState.query, ...prevState.filter })
        ) {
            let $t = this;

            clearTimeout(this.timeout);
            this.timeout = setTimeout(function () {
                $t.fetchLoanRequests();
            }, 100);
        }
    }
}

export default DefaultLimit;
