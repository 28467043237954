import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class MpesaOut extends Component {
    state = {
        tableData: { data: [] },
        response: { data: [] },
        tableError: false,
        query: {},
        filter: {},
        table_loading: false
    };

    timeout = null;
    render() {
        return (
            <div className="">
                <Nav
                    name="MPESA OUT"
                ></Nav>
                <div className="mt-3 border-0 card shado mx-3">
                    <div className="p-4">
                        <Filter
                            getFilter={filter => {
                                setTimeout(() => {
                                    this.setState({
                                        filter
                                    });
                                }, 0);
                            }}
                            dateRange={["startdate", "enddate"]}
                        />
                        <Table
                            search={["dr", "cr", "bal_before", "bal_after", "desc", "created_at"]}
                            sort="id"
                            sortDirection={-1}
                            data={this.state.tableData}
                            fetch={params => {
                                this.setState({ query: params });
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                        />
                    </div>
                </div>
            </div>
        );
    }

    fetchAgents = () => {
        this.setState({ table_loading: true });
        let q = {
            //...this.state.filter,
            ...this.state.query
        };
        let urlParams = Object.entries(q)
            .map(e => e.join("="))
            .join("&");
        let startdate = moment(this.state.filter.startdate).format("YYYY-MM-DD HH:mm:ss");
        let enddate = moment(this.state.filter.enddate).format("YYYY-MM-DD HH:mm:ss");

        fetch(`${window.server}/disburse-results?date_processed[$gte]=${startdate}&&date_processed[$lte]=${enddate}&&${urlParams}`, {
            headers: {
                Authorization: localStorage.token
            }
        }).then(response => response.json()).then(response => {
            let data = [];
            response.data.map((d, i) => {
                data.push({
                    agent_number: d.shortcode,
                    amount: "KES." + parseFloat(d.amount).toFixed(2),
                    transaction_type: d.transaction_type,
                    mpesa_transaction_id: d.mpesa_transaction_id,
                    mpesa_resp_desc: d.mpesa_resp_desc,
                    transaction_id: d.b2bresults.transactionId,
                    result_status: parseInt(d.b2bresults.resultCode) === 0 ? "SUCCESS" : "FAILED",
                    resultDesc: d.b2bresults.resultDesc,
                    Date_created: moment(d.date_processed).format('YYYY-MM-DD HH:mm:ss'),
                });
            });

            let dts = {};
            dts.data = data;

            this.setState({
                tableData: { ...response, ...dts },
                response,
                table_loading: false
            });
        })
            .catch(d => {
                this.setState({ table_loading: false });
                console.error(d);
            });
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
            JSON.stringify({ ...prevState.query, ...prevState.filter })
        ) {
            let $t = this;

            clearTimeout(this.timeout);
            this.timeout = setTimeout(function () {
                $t.fetchAgents();
            }, 100);
        }
    }
}

export default MpesaOut;
