import React, { Component } from 'react';
import Nav from '../../components/Nav';
import logo from '../../img/_logo.png';
import { Calendar } from 'react-feather';
import DatePicker from '../../components/datePicker';
import moment from 'moment';

class BalanceSheet extends Component {
  state = {
    data: {
      assets: {
        'current assets': [
          { name: 'Cash and Cash equivalents', amount: 210000 },
          { name: 'Petty Cash', amount: 10000 },
          { name: 'Temporary Investments', amount: 1000000 },
          { name: 'Accounts Receivable', amount: 4050000 },
          { name: 'Inventory', amount: 3100000 }
        ],
        Investments: [{ name: 'Investments', amount: 3600000 }],
        'Property and Equipment': [
          { name: 'Land', amount: 550000 },
          { name: 'Land Improvements', amount: 650000 },
          { name: 'Buildings', amount: 18000000 },
          { name: 'Equipments', amount: 20100000 },
          { name: 'Less: accum depreciation', amount: -5600000 }
        ]
      },
      liabilities: {
        'Current Liabilities': [
          { name: 'Notes payable', amount: 5000000 },
          { name: 'Accounts payable', amount: 3590000 },
          { name: 'wages payable', amount: 850000 },
          { name: 'Interest payable', amount: 2900000 },
          { name: 'Taxes Payable', amount: 610000 },
          { name: 'Warranty liablity', amount: 11000000 },
          { name: 'Unearned revenues', amount: 15000000 }
        ],
        'Long-term Liabilities': [
          { name: 'Notes payable', amount: 500000 },
          { name: 'Bonds payable', amount: 3590000 }
        ]
      }
    },
    showReport: false,
    dates: {
      startdate: null,
      enddate: null
    }
  };
  render() {
    return (
      <div className='d-flex flex-column justify-content-between page align-items-center print-block'>
        <div className='w-100'>
          <Nav
            name='Balance Sheet'
            buttons={[
              {
                text: 'Choose Date',
                onClick: () => this.setState({ showReport: false })
              },
              { text: 'Print', onClick: window.print }
            ]}></Nav>
        </div>

        {!this.state.showReport && (
          <div className='date-picker-card shadow p-4'>
            <h4 className='text-center font-weight-bold'>
              <Calendar className='icon'></Calendar> Select Date Range
            </h4>

            <div className='my-5 row'>
              <div className='col-md-6 text-center'>
                <div className='font-weight-bold mb-3'>Start Date</div>
                <div className='picker'>
                  <DatePicker
                    placeholder='choose start date'
                    date={this.state.startdate}
                    onChange={date => {
                      let { dates } = this.state;
                      dates.startdate = date;
                      this.setState({ dates });
                    }}></DatePicker>
                </div>
              </div>
              <div className='col-md-6 text-center'>
                <div className='font-weight-bold mb-3'>End Date</div>
                <div className='picker'>
                  <DatePicker
                    placeholder='choose end date'
                    date={this.state.startdate}
                    onChange={date => {
                      let { dates } = this.state;
                      dates.enddate = date;
                      this.setState({ dates });
                    }}></DatePicker>
                </div>
              </div>
            </div>

            <div className=' text-center'>
              <button
                className='btn btn-primary px-5 btn-round'
                onClick={() => this.setState({ showReport: true })}
                disabled={
                  !(this.state.dates.startdate && this.state.dates.enddate)
                }>
                Generate Report
              </button>
            </div>
          </div>
        )}

        {this.state.showReport && (
          <div className='virtual-form trial-balance-form d-flex flex-column justify-content-start page align-items-center'>
            <div className='w-100'>
              <div className='text-center'>
                <img src={logo} alt='' className='logo' />
                <h4 className='font-weight-bold my-3'>
                  SACCO MANAGEMENT SYSTEM
                </h4>
                <h5 className='font-weight-bold'>BALANCE SHEET</h5>
                <p className='font-weight-bold'>
                  {moment(this.state.dates.startdate).format('Do MMMM  YYYY')} -{' '}
                  {moment(this.state.dates.enddate).format('Do MMMM  YYYY')}
                </p>
              </div>
            </div>
            <div className='p-3 balance-sheet  bg-white mt-5'>
              <div className='row'>
                <div className='col-md-6'>
                  {this.generateColumn(this.state.data.assets, 'ASSETS')}
                </div>
                <div className='col-md-6'>
                  {this.generateColumn(
                    this.state.data.liabilities,
                    'LIABILITIES'
                  )}
                </div>
              </div>
            </div>
            <div></div>
          </div>
        )}
        <div></div>
      </div>
    );
  }

  getTotals = i => {
    let total = 0;
    this.state.data.map(d => {
      total += d[i];
    });
    return total;
  };

  generateColumn = (assets, name) => {
    let cumulativeTotal = 0;

    let col = Object.keys(assets).map(d => {
      let data = assets[d];

      let total = 0;

      let o = data.map(d1 => {
        total += d1.amount;
        return (
          <>
            <div className='col-list'>
              <div className='p-2 col-list-item'>
                <div>{d1.name}</div>
                <div className='text-right'>
                  {Math.sign(d1.amount) === -1
                    ? '(' + Math.abs(d1.amount).toLocaleString() + ')'
                    : d1.amount.toLocaleString()}
                </div>
              </div>
            </div>
          </>
        );
      });

      o.unshift(<div className='col-subtitle'>{d}</div>);
      o.push(
        <>
          <div className='p-2 col-list-item cumulative-total border-top'>
            <div>Total {d}</div>
            <div className='text-right'>KES {total.toLocaleString()}</div>
          </div>
          <hr />
        </>
      );

      cumulativeTotal += total;
      return o;
    });

    col.unshift(<div className='col-title'>{name}</div>);

    col.push(
      <>
        <hr />
        <div className='pb-5'></div>
        <div className='p-2 col-list-item cumulative-total border-top global-total'>
          <div>TOTAL {name}</div>
          <div className='text-right'>
            KES {cumulativeTotal.toLocaleString()}
          </div>
        </div>
      </>
    );

    return col;
  };
}

export default BalanceSheet;
