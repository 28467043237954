import React, { Component } from "react";
import LButton from "../../components/loadingButton";
import Nav from "../../components/Nav";

class Petty extends Component {
  state = {
    msisdn: 0,
    amount: 0,
    verifyStatus: 0,
    reason: "",
    other: { reason: false }
  };
  render() {
    return (
      <div className="">
        <Nav name="Petty Cash"></Nav>

        <form
          className="py-5 mt-3 table-card  border-0 card shado mx-3 shadow"
          onSubmit={e => {
            e.preventDefault();
            this.sendPettiness();
          }}
        >
          <div className="container">
            <div className="mx-3  mb-3">
              <span className="ml-1 mb-2 d-block">ID number</span>
              <input
                required={true}
                placeholder="..."
                className="form-control text-input bg-light"
                onChange={e => {
                  //   if (parseInt(e)) {
                  this.setState({ id: e.target.value });
                  //   }
                }}
                // value={}
                type="number"
              />
            </div>
            <div className="mx-3  mb-3">
              <span className="ml-1 mb-2 d-block">Full name</span>
              <input
                required={true}
                placeholder="..."
                className="form-control text-input bg-light"
                onChange={e => {
                  //   if (parseInt(e)) {
                  this.setState({ name: e.target.value });
                  //   }
                }}
                // value={}
              />
            </div>
            <div className="mx-3  mb-3">
              <span className="ml-1 mb-2 d-block">Phone number</span>
              <input
                required={true}
                placeholder="..."
                className="form-control text-input bg-light"
                onChange={e => {
                  //   if (parseInt(e)) {
                  this.setState({ msisdn: e.target.value });
                  //   }
                }}
                // value={}
              />
            </div>
            <div className="mx-3  mb-3">
              <span className="ml-1 mb-2 d-block">Amount</span>
              <input
                required={true}
                placeholder="..."
                className="form-control text-input bg-light"
                onChange={e => {
                  this.setState({ amount: e.target.value });
                }}
                // value={}
              />
            </div>

            <div className="mx-3  mb-3">
              <div className="d-flex flex-row justify-content-between align-items-center text-input position-relative">
                <span className="ml-1 mb-2 d-block">Reason</span>
                {this.state.other.reason && (
                  <button
                    className="badge badge-primary"
                    onClick={() => {
                      this.setState({
                        other: { ...this.state.other, reason: false }
                      });
                    }}
                  >
                    <small>Choose category</small>
                  </button>
                )}
              </div>
              {this.state.other.reason && (
                <textarea
                  name=""
                  rows="5"
                  className="form-control text-input bg-light"
                  onChange={e => {
                    this.setState({ reason: e.target.value });
                  }}
                  value={this.state.reason}
                ></textarea>
              )}
              {!this.state.other.reason && (
                <select
                  name=""
                  className="form-control text-input bg-light"
                  required={true}
                  onChange={e => {
                    //console.log(e.target.value === "other");
                    if (e.target.value === "other") {
                      this.setState({
                        other: { ...this.state.other, reason: true }
                      });
                    } else {
                      this.setState({ reason: e.target.value });
                    }
                  }}
                >
                  <option value="choose option" selected hidden>
                    Choose a category
                  </option>
                  <option value="Airtime">Airtime</option>
                  <option value="Disturbance">Disturbance</option>
                  <option value="Fare to field">Fare to field</option>
                  <option value="Fare to client">Fare to client</option>
                  <option value="Accommodation">Accommodation</option>
                  <option value="Stationery">Stationery</option>
                  <option value="Toiletries">Toiletries</option>
                  <option value="Security">Security</option>
                  <option value="Electricity">Electricity</option>
                  <option value="Rent">Rent</option>
                  <option value="Water">Water</option>
                  <option value="Admin">Admin</option>
                  <option value="Furniture">Furniture</option>
                  <option value="Recovery">Recovery</option>
                  <option value="other">other</option>
                </select>
              )}
            </div>

            <div className="m-3 pt-3 text-center bas display-inline-block">
              <div className="display-inline-block">
                <LButton
                  text="send"
                  status={this.state.verifyStatus}
                  className="text-center"
                >
                  Send
                </LButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  sendPettiness = () => {
    if (
      !window.confirm(
        `Are you sure that you want to disburse KES ${this.state.amount.toLocaleString()} to ${
          this.state.name
        }`
      )
    )
      return false;
    this.setState({ verifyStatus: 1 });
    fetch(`${window.server}/utils/expenses`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: this.state.id,
        name: this.state.name,
        msisdn: window.verifyNumber(this.state.msisdn) + "",
        amount: this.state.amount,
        reason: this.state.reason
      })
    })
      .then(response => response.json())
      .then(response => {
        // //console.log(response);
        if (response.code) {
          this.setState({ verifyStatus: 0 });

          alert(
            response.message +
              " \n " +
              (response.errors[0] ? response.errors[0].message : "")
          );
          //console.log(response);
          this.setState({ modalVisible: false });
        } else {
          this.setState({ verifyStatus: 2 });
          //console.log(response);
        }
      })
      .catch(d => {
        this.setState({ verifyStatus: 0 });

        //console.log("There was an error sending petty cash. please try again");
        //console.log(d);
        this.setState({ modalVisible: false });
      });
  };
}

export default Petty;
