import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class MemberTransactions extends Component{
    state = { 
       tableData: { data: [] },
       response: { data: [] },
       tableError: false,
       query: {},
       filter: {},
       table_loading: false
    };
    timeout = null;
    
    render(){
      return (
           <div className="">
            <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
              <div className="p-4">
                <Table
                  search={["firstname"]}
                  // sort="created_at"
                  // sortDirection={-1}
                  data={this.state.tableData}
                  fetch={params => {
                    this.setState({ query: params });
                  }}
                  loading={this.state.table_loading}
                  fetchError={this.state.tableError}
                />
              </div>
            </div>
            </div>
        );
    }

    fetchTransactions = () => {
     this.setState({ table_loading: true });

     let q = {
        // ...this.state.filter,
        ...this.state.query,
        customer_id: this.props.match.params.id
      };

    };

    componentDidUpdate(prevProps, prevState) {
      if (
        JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
        JSON.stringify({ ...prevState.query, ...prevState.filter })
      ) {
        let $t = this;

        clearTimeout(this.timeout);
        this.timeout = setTimeout(function() {
          $t.fetchShares();
        }, 100);
      }
  }
}

export default MemberTransactions;



