import React, { Component } from "react";
import moment from "moment";
import Chart from '../components/chart';
import Loans from '../components/loans';
import Agents from '../components/agents';
import { Link } from 'react-router-dom';

import {
  DollarSign,
  Users,
} from "react-feather";

class Home extends Component {
  state = {
    chartDefaults: {
      label: 'Total users',
      fill: true,
      borderColor: '#4e73df',
      borderCapStyle: 'square'
    },
    totalsEndDate: moment().format('YYYY-MM-DD'),
    totalsStartDate: moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD'),
    totalClients: 0,
    totalLoans: 0,
    sumLoans: 0,
    totalInterest: 0,
    records: [],
    loanBalance: 0,
    filter: {
      enddate: moment()
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      starttdate: moment().format('YYYY-MM-DD')
    },
    performanceFilter: {},
    SalesDataset: [],
    LoansDataset: [],
    AgentsDataset: []
  };
  render() {
    return (
      <div className="bg-white">
        <div className="p-4 d-flex flex-row justify-content-between align-items-center d-flex flex-row align-items-center bg-white">
          <h5 className='font-weight-normal text-uppercase'><b>Dashboard</b></h5>
          <p><b>REPORT ANALYSIS</b></p>
        </div>

        <div className="px-3">
          <div className="row mb-5">
            <div className='col-md-3 intro tab bg-white'>
              <div className='card p-3 d-flex flex-row align-items-center text-white material-blue'>
                <div className='icon-holder p-3 mr-3'>
                  <DollarSign />
                </div>
                <div>
                  <div className="text-capitalize">Total Principal</div>
                  <div className='mt-1 font-weight-bold'>
                    <small>
                      <small> KES</small>
                    </small>{' '}
                    {this.state.sumLoans.toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-3 intro tab'>
              <div className='card p-3 d-flex flex-row align-items-center text-white material-green'>
                <div className='icon-holder p-3 mr-3'>
                  <DollarSign />
                </div>
                <div>
                  <div className="text-capitalize">Total Interest</div>
                  <div className='mt-1 font-weight-bold'>
                    <small>
                      <small> KES</small>
                    </small>{' '}
                    {this.state.totalInterest.toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-3 intro tab'>
              <div className='card p-3 d-flex flex-row align-items-center text-white material-red'>
                <div className='icon-holder p-3 mr-3'>
                  <DollarSign />
                </div>
                <div>
                  <div className="text-capitalize">Total Loan Balance</div>
                  <div className='mt-1 font-weight-bold'>
                    <small>
                      <small> KES</small>
                    </small>{' '}
                    {this.state.loanBalance.toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-3 intro tab'>
              <div className='card p-3 d-flex flex-row align-items-center text-white material-deep-purple'>
                <div className='icon-holder p-3 mr-3'>
                  <Users />
                </div>
                <div>
                  <div className="text-capitalize">Agents</div>
                  <div className='mt-1 font-weight-bold'>
                    <small className='opacity-0'>
                      <small> </small>
                    </small>{' '}
                    {this.state.totalClients.toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <Loans
                title='Daily Loans Taken'
                updateFilter={filter => {
                  this.setState({ performanceFilter: filter });
                  //console.log(filter);
                }}
                datasets={this.state.LoansDataset}
              />
            </div>
            <div className='col-md-6'>
              <Chart
                title='Daily Interest Gained'
                updateFilter={filter => {
                  this.setState({ performanceFilter: filter });
                  //console.log(filter);
                }}
                datasets={this.state.SalesDataset}
              />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-md-12'>
              <Agents
                title='New Agents'
                updateFilter={filter => {
                  this.setState({ performanceFilter: filter });
                }}
                datasets={this.state.AgentsDataset}
              />
            </div>
          </div>
          {/* comment here */}
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.fetchTotalAgents();
    this.fetchLoansBreakdown();
    this.fetchChart();
    this.fetchLoans();
    this.fetchAgents();
  }

  fetchTotalAgents = () => {
    fetch(`${window.server}/agents?roles_id=1`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {

        if (response.code) {
        } else {
          this.setState({ totalClients: response.total });
        }
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };


  fetchLoansBreakdown = () => {
    fetch(`${window.server}/utils/api/loans-breakdown`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {

        if (response.code) {

        } else {
          let totalinterest = response.reduce(function (prev, current) {
            return prev + +current.interest_earned
          }, 0);

          let sum = response.reduce(function (prev, current) {
            return prev + +current.principal
          }, 0);

          let balance = response.reduce(function (prev, current) {
            return prev + +current.loan_balance
          }, 0);
          this.setState({ totalInterest: totalinterest, sumLoans: sum, loanBalance: balance, records: response });
        }
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };




  fetchChart = () => {

    fetch(`${window.server}/utils/api/fetch-interest`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);

        if (response.code) {
        } else {
          let interest_gained = [];

          response.map(d => {
            interest_gained.push({ t: new Date(d.date), y: d.interest_gained });
          });

          let datasets = [
            {
              label: 'Interest',
              fill: false,
              data: interest_gained,
              backgroundColor: '#4caf50',
              borderColor: '#4caf50',
              borderWidth: 1
            }
          ];

          this.setState({ SalesDataset: datasets });
        }
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  fetchLoans = () => {

    fetch(`${window.server}/utils/api/fetch-loans`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);

        if (response.code) {
        } else {
          let total = [];

          response.map(d => {
            total.push({ t: new Date(d.date), y: d.total });
          });

          let loansets = [
            {
              label: 'Loans',
              fill: false,
              data: total,
              backgroundColor: '#007bff',
              borderColor: '#007bff',
              borderWidth: 1
            }
          ];

          this.setState({ LoansDataset: loansets });
        }
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  fetchAgents = () => {

    fetch(`${window.server}/utils/api/fetch-users`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        //alert(JSON.stringify(response))

        if (response.code) {
        } else {
          let total = [];

          response.map(d => {
            total.push({ t: new Date(d.date), y: d.total });
          });

          let agentsets = [
            {
              label: 'Agents',
              fill: false,
              data: total,
              backgroundColor: '#673ab7',
              borderColor: '#673ab7',
              borderWidth: 1
            }
          ];

          this.setState({ AgentsDataset: agentsets });
        }
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };


  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.performanceFilter }) !==
      JSON.stringify({ ...prevState.performanceFilter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchChart();
      }, 100);
    }
  }
}

export default Home;
