import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import moment from "moment";
import Nav from "../../components/Nav";

class Summary extends Component {
  state = {
    tableData: [],
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
    end: moment().add(1, 'days').format('YYYY-MM-DD'),
    start: moment().format('YYYY-MM-DD'),
  };

  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="Loans Breakdown"
        ></Nav>
        <div className="mt-3 shado border-1 mx-3 bg-white">
          <div className="p-4">
            <Table
              search={["total", "principal", "interest_earned", "amount_payable", "amount_paid", "loan_balance"]}
              sortDirection={-1}
              data={this.state.tableData}
              fetch={params => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchLoans = () => {
    this.setState({ table_loading: true });

    let q = {
      ...this.state.filter,
      ...this.state.query
    };

    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");

    fetch(`${window.server}/utils/api/loans-breakdown?${urlParams}`, {
      headers: {
        Authorization: localStorage.token
      },
    }).then(response => response.json()).then(response => {
      console.log("RESULTS", response);
      let data = [];
      response.map((d, i) => {
        data.push({
          loan_count: d.total,
          principal: "KES " + parseFloat(d.principal).toLocaleString(),
          interest_earned: "KES " + parseFloat(d.interest_earned).toLocaleString(),
          amount_payable: "KES " + parseFloat(d.amount_payable).toLocaleString(),
          amount_paid: "KES " + parseFloat(d.amount_paid).toLocaleString(),
          loan_balance: "KES " + parseFloat(d.loan_balance).toLocaleString(),
          loan_status: d.status.toLocaleString() === '0' ? <span className="badge badge-danger">PENDING</span> : (d.status.toLocaleString() === '1' ? <span className="badge badge-primary">ACTIVE</span> : <span className="badge badge-success">PAID</span>),
        });
      });
      let dts = {};
      dts.data = data;
      this.setState({
        tableData: { ...dts },
        table_loading: false
      });
    }).catch(d => {
      this.setState({ table_loading: false });
      console.error(d);
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchLoans();
      }, 100);
    }
  }
}

export default Summary;
