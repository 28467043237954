import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Edit, Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class okoaAgentAccounts extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false
  };
  timeout = null;
  render() {
    return (
      <div className="">
        <div className="mt-3 border-0 card mx-3 bg-white">
          <div className="p-4">
            <Table
              search={["agent_no", "store_number", "limit","till_number", "business_name", "location", "owner_name", "owner_msisdn", "operator_msisdn"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={params => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchMemberWallet = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
      agent_id: this.props.match.params.agent_id,

    };

    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");

    // //console.log(urlParams);
    fetch(`${window.server}/agent-accounts?${urlParams}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        let data = [];
        response.data.map((d, i) => {
          localStorage.setItem("agentLimitValue", parseInt(d.limit));
          data.push({
            //id: d.id,
            agent_number: d.agent_no,
            store_number: d.store_number,
            till_number: d.till_number,
            owner_name: d.owner_name,
            owner_msisdn: d.owner_msisdn,
            operator_msisdn: d.operator_msisdn,
            verified_owner: d.verified_owner,
            limit: "KES." + parseInt(d.limit).toLocaleString(),
            wallet: "KES." + parseInt(d.agent.wallet).toFixed(2),
            business_name: d.business_name,
            location: d.location
            // action: (
            //   <div className="d-flex flex-row">
            //     <button
            //       onClick={() => {
            //         this.setState({ editModal: true });
            //       }}
            //       className="btn btn-sm btn-primary px-3 btn-round">
            //       <span className="pl-1 font-weight-bold">Update Account</span>
            //     </button>
            //   </div>
            // )
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false
        });
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchMemberWallet();
      }, 100);
    }
  }
}

export default okoaAgentAccounts;
