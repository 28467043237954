import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class OkoaTransactions extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false
  };
  timeout = null;
  render() {
    return (
      <div className="">
        <div className="mt-3  border-0 card shado mx-3">
          <div className="p-4">
            <Table
              search={["dr", "cr", "bal_before", "bal_after", "desc", "created_at"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={params => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchLoanRequests = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
      loan_id: this.props.match.params.loan_id
    };

    //console.log("maryyyyy");
    //console.log(this.props.match.params.loan_id);

    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");

    // //console.log(urlParams);
    fetch(`${window.server}/transactions-log?${urlParams}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        //console.log(response);

        let data = [];
        response.data.map((d, i) => {
          data.push({
            //loan_id: d.id,
            //account_type: d.account_type,
            debit: "KES " +
              parseFloat(
                parseFloat(d.dr)
              ).toLocaleString(),
            credit: "KES " +
              parseFloat(
                parseFloat(d.cr)
              ).toLocaleString(),
            balance_before: "KES " +
              parseFloat(
                parseFloat(d.bal_before)
              ).toLocaleString(),
            balance_after: "KES " +
              parseFloat(
                parseFloat(d.bal_after)
              ).toLocaleString(),
            description: d.desc,
            created_at: moment(d.created_at).format('Do MMMM YYYY, h:mm:ss a')
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false
        });
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchLoanRequests();
      }, 100);
    }
  }
}

export default OkoaTransactions;
