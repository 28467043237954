import React, { Component } from "react";
import moment from "moment";
import { DollarSign, User, LogOut } from "react-feather";

class Details extends Component {
  randomize = (start, end) => {
    return Math.floor(Math.random() * end) + start;
  };

  state = {
    data: {},
    balanceInfo: {
      "SAVINGS BALANCE": "",
      "Available Balance":
        "KES " ,
      "Actual Balance": "KES ",
      break: "br",
      "WALLET BALANCE": "",
      "Loan Cash": "KES " ,
      "Non-Loan Cash": "KES " ,
      break: "br",
      "Account status": "",
      "Member Status": "",
      "TOTAL GUARANTEED": "",
      "Total Guaranteed by others": "KES" ,
      "Total Guarantee": "KES",
      break: "br",
      "SHARE BALANCE" : "",
      "Share Status": "",
      "Share Value": " KES",
      "Share Balance": "KES"
    },
    salaryVisible: false,
    exitInfo: {}
  };
  render() {
    return (
      <div className="p-3">
        <div className="row">
          <div className="col-md-7">
            <ul className="list-group user-details table-card shadow border-0 w-100">
              <div className="p-3 bg-light font-weight-bold">
                <User size={18}></User> Member Details
              </div>
              {Object.keys(this.state.data).map(d => (
                <li className="list-group-item d-flex flex-row  text-dark">
                  <div className="user-detail-title font-weight-bold text-capitalize">
                    {d.replace(/_/g, " ")}
                  </div>
                  <div>{this.state.data[d]}</div>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-md-5">
            <ul className="list-group user-details table-card shadow border-0 w-100">
              <div className="p-3 bg-light font-weight-bold">
                <DollarSign size={18}></DollarSign> Account Details
              </div>
              {Object.keys(this.state.balanceInfo).map(d => {
                if (d === "break" || d === "brea")
                  return <div className="p-1 bg-light"></div>;

                return (
                  <li className="list-group-item d-flex flex-row text-dark">
                    <div className="user-detail-title font-weight-bold text-capitalize">
                      {d.replace(/_/g, " ")}
                    </div>
                    <div>{this.state.balanceInfo[d]}</div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount = () => {
    this.fetch();
  };

  fetch = () => {
    let user_id = this.props.match.params.id;
    fetch(`${window.server}/customer?id=${user_id}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        //console.log(response);
        let data = [];
        response.data.map(d => {
          data.push({
            full_name: `${d.firstname} ${d.middlename} ${d.surname}`,
            date_of_birth: moment(d.date_of_birth).format("Do MMMM YYYY"),
            "Phone Number": d.msisdn.msisdn,
            KRA_pin: d.details.kra_pin,
            gender: { M: "Male", F: "Female" }[d.details.gender],
            // marital_status: {1:"Single", 2:"Married"}[d.marital_status],
            ID_number: d.details.id_number,
            // village: d.village,
            address: d.details.address,
            nationality: {1:"Kenyan", 2:"Nigerian"}[d.details.nationality],
            pledge: "KES " + parseInt(d.pledge).toLocaleString()
          });
        });
        response.data = data;
        this.setState({ data: data[0] });
      })
      .catch(d => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };
}

export default Details;
