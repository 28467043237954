import React, { Component } from "react";
import { Route, Link, Switch } from "react-router-dom";

import Tabs from "../../components/tabs";
import loanDetails from "./details";
import loanTransactions from "./transactions";

class LoanView extends Component {
  state = { data: [{}], tableData: [] };
  render() {
    return (
      <div className="card loan-parent-card border-0 m-3">
        <Tabs
          tabs={[
            {
              label: "LOAN details",
              link: "/loan-view/details/" + this.props.match.params.id + "/" + this.props.match.params.loan_id
            },
            {
              label: "transaction logs",
              link: "/loan-view/transactions/" + this.props.match.params.id + "/" + this.props.match.params.loan_id
            }
          ]}
        >
          <Route path="/loan-view/details/:id/:loan_id" exact component={loanDetails} />
          <Route path="/loan-view/transactions/:id/:loan_id" exact component={loanTransactions} />
        </Tabs>
      </div>
    );
  }
}

export default LoanView;
