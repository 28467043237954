import React, { Component } from "react";
import Nav from "../../components/Nav";
import { Send, Users, Plus, Search } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";

class SMSCreate extends Component {
  state = {
    addressBooks: [
      {
        name: "Sample address Book",
        description:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit.Earum velit unde recusandae ad maiores quas"
      },
      {
        name: "Sample address Book",
        description:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit.Earum velit unde recusandae ad maiores quas"
      },
      {
        name: "All inactive members",
        description:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit.Earum velit unde recusandae ad maiores quas"
      },
      {
        name: "Sample address Book",
        description:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit.Earum velit unde recusandae ad maiores quas"
      },
      {
        name: "Sample address Book",
        description:
          "Lorem ipsum dolor sit, amet consectetur adipisicing elit.Earum velit unde recusandae ad maiores quas"
      }
    ],
    addressModal: false,
    activeAddressBook: false,
    msisdn: null
  };
  render() {
    return (
      <div>
        <Nav name="Create an SMS"></Nav>
        <div className="mt-3 3 table-card sms-table-card  border-0 card shado mx-3 shadow p-4">
          <form
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <div class="mx-3 d-inline-block mb-3">
              <span class="ml-1 mb-2 d-block font-weight-bold">Message</span>
              <textarea
                placeholder="..."
                class="form-control bg-light sms-input"
                rows={5}
                required={true}
              ></textarea>
            </div>
            <hr />

            <div class="mx-3 d-inline-block mb-3">
              <span class="ml-1 mb-2 d-block font-weight-bold">Receiver</span>
              <select
                placeholder="..."
                class="form-control bg-light sms-input"
                required=""
                onChange={e => {
                  this.setState({ receiver: e.target.value });
                }}
              >
                <option value="0" selec>
                  Choose a receiver
                </option>
                <option value="1">Individual</option>
                <option value="2">Group</option>
              </select>
            </div>

            {this.state.receiver === "1" && (
              <div>
                <div class="mx-3 d-inline-block mb-3">
                  <span class="ml-1 mb-2 d-block font-weight-bold">
                    Enter Phone Number
                  </span>
                  <input
                    placeholder="..."
                    class="form-control bg-light sms-input"
                    rows={5}
                    type="number"
                    required={true}
                  />
                </div>
              </div>
            )}

            {this.state.receiver === "2" && (
              <div className="d-flex flex-row flex-wrap align-items-center">
                {this.state.activeAddressBook === false && (
                  <div
                    className="m-3 address-card m-3 d-flex flex-row align-items-center"
                    onClick={() => {
                      this.setState({ addressModal: true });
                    }}
                  >
                    <div className="ico ml-3 my-3">
                      <Plus size={30}></Plus>
                    </div>
                    <div className="content p-3">
                      <h5 className="font-weight-bold">
                        Choose An Address Book
                      </h5>
                      <div>
                        <small>
                          If you Do not have one, You can create one{" "}
                          <Link to="/address-book-create">Here</Link>
                        </small>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.activeAddressBook !== false && (
                  <div className="m-3 address-card m-3 d-flex flex-row align-items-center">
                    <div className="ico ml-3">
                      <Users size={30}></Users>
                    </div>
                    <div className="content p-3">
                      <div className="font-weight-bold">
                        {
                          this.state.addressBooks[this.state.activeAddressBook]
                            .name
                        }
                      </div>
                      <div>
                        <small>
                          {
                            this.state.addressBooks[
                              this.state.activeAddressBook
                            ].description
                          }
                        </small>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {typeof this.state.receiver !== "undefined" && (
              <div>
                <hr />
                <button className="btn btn-primary btn-round pl-2 pr-4">
                  <Send size={20} className="mr-2"></Send>
                  Send Message
                </button>
              </div>
            )}
          </form>
        </div>

        <Modal
          visible={this.state.addressModal}
          close={() => this.setState({ addressModal: false })}
        >
          <h5 className="font-weight-bold">Find An AddressBook</h5>
          <hr />
          <form
            className="d-flex flex-column justify-content-center position-relative"
            onSubmit={e => {
              e.preventDefault();
              this.findOfficer();
            }}
          >
            <Search className="search-icon cursor-pointer" color="grey" />
            <input
              type="search"
              className="form-control pl-5 rounded bg-light"
              placeholder="search"
              onChange={e => {
                this.setState({ officerSearch: `%${e.target.value}%` });
              }}
            />
          </form>
          <div className="">
            {this.state.addressBooks.map((d, i) => (
              <div
                className=" address-card my-3 d-flex flex-row align-items-center w-100"
                key={i}
                onClick={() => {
                  this.setState({ addressModal: false, activeAddressBook: i });
                }}
              >
                <div className="ico ml-3">
                  <Users size={30}></Users>
                </div>
                <div className="content p-3">
                  <div className="font-weight-bold">{d.name}</div>
                  <div>
                    <small>{d.description}</small>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Modal>
      </div>
    );
  }
}

export default SMSCreate;
