import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class LoanProducts extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false
  };
  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="Loan Products"
          buttons={[
            {
              text: "Create a loan Product",
              link: "product-create"
            }
          ]}
        ></Nav>
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              search={["firstname"]}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={params => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchLoanProducts = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query
    };

    delete q["$sort[id]"];

    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");

    // //console.log(urlParams);
    fetch(`${window.server}/products?${urlParams}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        //console.log(response);

        let data = [];
        response.data.map((d, i) => {
          data.push({
            id: d.id,
            // product_code: d.product_code,
            product_name: d.product_name,
            // product_line: d.product_line,
            interest_rate: d.interest_rate + "%",
            commission: d.commission + "%",
            guarantor_status: ["No Guarantor", "Has Guarantor", "Pool"][
              d.guarantor_status
            ],
            status: ["", "Enabled", "Disabled"][d.status],
            description: d.description,
            duration_to_qualify: d.duration_to_qualify + " months",
            // date_created: d.date_created,
            // date_modified: d.date_modified,
            // created_by: d.created_by,
            // modified_by: d.modified_by,
            // charge_type: d.charge_type,
            qualification_percentage: d.qualification_percentage + "%",
            repayment_mode: ["", "Reducing Balance", "Compound"][
              d.repayment_mode
            ]
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false
        });
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function() {
        $t.fetchLoanProducts();
      }, 100);
    }
  }
}

export default LoanProducts;
