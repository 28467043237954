import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Home from "./pages/home";
import Login from "./pages/login";

import _403 from "./img/403.png";
import _500 from "./img/500.jpg";
import {
  progressBarFetch,
  setOriginalFetch,
  ProgressBar
} from "react-fetch-progressbar";
import Menu from "./components/Menu";
import config from "./config.js";
import Alert from "./components/alert";
import Members from "./pages/members/members";
import MembersView from "./pages/members/membersView";
import stamp from "./img/dark_logo.png";
import { LogOut, Info } from "react-feather";
import memberAdd from "./pages/members/memberAdd";
import agentAdd from "./pages/agents/agentAdd";
import LoanSummaries from "./pages/loans/loanSummaries";
import LoanAdd from "./pages/loans/loanAdd";
import Loans from "./pages/loans/loans";
import OkoaLoans from "./pages/loans/okoaLoans";
import OkoaLoanssearch from "./pages/loans/okoaLoanssearch";
import Breakdown from "./pages/loans/breakdown";
import Breakdownsearch from "./pages/loans/breakdownsearch";
import Summary from "./pages/loans/summary";
import ActiveLoans from "./pages/loans/activeLoans";
import ActiveLoanssearch from "./pages/loans/activeLoansearch";
import PendingLoans from "./pages/loans/pendingLoans";
import PaidLoans from "./pages/loans/paidLoans";
import PaidLoanssearch from "./pages/loans/paidLoanssearch";
import ErroredLoans from "./pages/loans/erroredLoans";
import ErroredLoanssearch from "./pages/loans/erroredLoanssearch";
import DefaultedLoans from "./pages/loans/defaultedLoans";
import DefaultedLoanssearch from "./pages/loans/defaultedLoanssearch";
import OkoaAgents from "./pages/agents/okoaAgents";
import OkoasearchAgents from "./pages/agents/okoasearchAgents";
import OkoaAgentAccounts from "./pages/agents/okoaAgentAccounts";
import OkoasearchAgentAccounts from "./pages/agents/okoasearchAgentAccounts";
import OkoaAgentLimits from "./pages/agents/okoaAgentLimits";
import OkoasearchAgentLimits from "./pages/agents/okoasearchAgentLimits";
import UploadDocuments from "./pages/agents/documentsUpload";
import viewDocuments from "./pages/agents/documentsViewer";
import okoaTransactions from "./pages/transactions/okoaTransactions";
import PaymentLogs from "./pages/transactions/paymentLogs";
import AgentsView from "./pages/agents/agentsView";
import AccountView from "./pages/agents/accountView";
import OkoaAdmins from "./pages/admin/okoaAdmins";
import AdminView from "./pages/admin/adminView";
import Employees from "./pages/employees/employees";
import EmployeeAdd from "./pages/employees/employeeAdd";
import LoanRequests from "./pages/loans/loanRequests";
import loanView from "./pages/loans/loansView";
import LoanApprove from "./pages/loans/loanApprove";
import TrialBalance from "./pages/finance/trialBalance";
import BalanceSheet from "./pages/finance/balanceSheet";
import IncomeStatement from "./pages/finance/incomeStatement";
import CashFlowStatement from "./pages/finance/cashFlowStatement";
import Payments from "./pages/finance/payments";
import Petty from "./pages/finance/petty";
import PettyRecords from "./pages/finance/pettyRecords";
import MpesaDeposits from "./pages/finance/mpesaDeposits";
import Disbursements from "./pages/finance/disbursements";
import SMS from "./pages/sms/sms";
import SMSCreate from "./pages/sms/smsCreate";
import AddressBook from "./pages/sms/addressBooks";
import AddressBookCreate from "./pages/sms/addressBookCreate";
import Savings from "./pages/finance/savings";
import Shares from "./pages/finance/shares";
import LoanProducts from "./pages/loans/loanProducts";
import SMSList from "./pages/sms/smsList";
import Suspense from "./pages/finance/suspense";
import LoanProductCreate from "./pages/loans/loanProductCreate";
import AccountsChart from "./pages/finance/accountsChart";
import Vendors from "./pages/finance/vendors";
import Invoices from "./pages/finance/invoices";
import MembersPending from "./pages/members/membersPending";
import MemberApprove from "./pages/members/memberApprove";
import StoreReconciliation from "./pages/admin/reconciliation";
import MissingReconciliation from "./pages/admin/reconciliationmissing";
import MpesaIn from "./pages/mpesa/mpesaIn";
import MpesaOut from "./pages/mpesa/mpesaOut";
import CustomerCare from "./pages/admin/customerCare";
import MarketRep from "./pages/admin/marketRep";
import suspensePayment from "./pages/admin/suspensePayment";
import missingPayment from "./pages/admin/missingPayment";


// import MemberTransactions from "./pages/members/transactions"

// import Petty from "./pages/finance/petty";
// import Payments from "./pages/finance/payments";

// Configs
window.server = config.server_url;

// create a fetch that will no trigger the progreebar in the background
window.silentFetch = fetch;

// check token validity
let checkTokenValidity = () => {
  window
    .silentFetch(`${window.server}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
    .then(response => response.json())
    .then(response => {
      // //console.log(response);
      if (response.ErrorCode === 401) {
        window.logout(true);
        setTimeout(() => {
          alert("Your Session has expired");
        }, 0);
      } else {
        setTimeout(() => {
          // //console.log("checking validity");
          if (localStorage.token) {
            checkTokenValidity();
          }
        }, 30000);
      }
    })
    .catch(d => {
      //console.log(d);
    });
};

window.logout = force => {
  let logout = () => {
    let path = window.location.pathname;
    localStorage.clear();
    localStorage.previousPath = path;
    window.location = "/login";
  };

  if (force) {
    logout();
    return false;
  }

  window.alert2.confirm({
    icon: <Info size={70}></Info>,
    title: "Are you sure that you want to Logout ?",
    message: "If you agree your session will be terminated",
    confirmText: "Log Out",
    onSubmit: () => {
      logout();
    }
  });

  // window.alert2.onConfirm = () => {
  //   logout();
  // };

  // let path = window.location.pathname;
  // localStorage.clear();
  // localStorage.previousPath = path;
  // window.location = "/login";
};

// set Progress Bar
setOriginalFetch(window.fetch);
window.standardFetch = progressBarFetch;

// set app globals

// end Configs

// check if logged in
if (localStorage.token && localStorage.user) {
  window.user = JSON.parse(localStorage.user);
  window.user.group = { id: 1, name: "Admin" };
} else {
}

class App extends Component {
  state = { loaded: false, status: 0, errorload: false, blur: false };

  render() {
    return (
      <>
        <div className={!this.state.blur || "blur-alert "}>
          <Switch>
            <Route path="/login" component={Login} />
            {(this.state.loaded || !localStorage.token) && (
              <Route path="/" component={Portal} />
            )}
            {this.getStatus(this.state.status, this.state.errorload)}
          </Switch>
        </div>
        <Alert
          onRef={ref => {
            window.alert2 = ref;
          }}
          toggle={blur => {
            this.setState({ blur });
          }}
        ></Alert>
      </>
    );
  }

  componentDidMount = () => {
    // this.get("products", response => {
    //   //console.log(response);
    //   window.products = response.data;
    //   window.productsList = {};
    //   response.data.map(d => {
    //     window.productsList[d.product_code] = d.product_name;
    //   });
    // });

    this.get("loans", response => {
      window.loans = response.data;
      window.loansList = {};
      response.data.map(d => {
        window.loansList[d.loan_amount] = d.loan_balance;
      });
    });

    let roles = {};

    roles = { CUSTOMER_READ: [1], CUSTOMER_CREATE: [1] };

    window.roles = roles;

    this.checkStatus();
  };

  checkStatus = () => {
    // if (window.wards && window.subCounties)
    this.setState({ loaded: true, errorload: true });
  };

  get = (url, callback) => {
    fetch(`${window.server}/${url}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.ErrorCode === 401) {
          localStorage.clear();
          this.props.history.push("/login");
        }
        if (response.ErrorCode) {
          this.setState({ status: response.ErrorCode, errorload: true });
        }
        callback(response);
        this.checkStatus();
      })
      .catch(d => {
        //console.log(d);
      });
  };

  inactivityTime = function () {
    let time;
    let logout = () => {
      if (localStorage.token) {
        localStorage.clear();
        window.location = "/login";
      }
    };

    let resetTimer = () => {
      clearTimeout(time);
      time = setTimeout(window.logout, 900000);
    };

    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
  };

  getStatus = () => {
    if (!this.state.errorload)
      return (
        <div className="loader h-100 w-100 d-flex flex-row align-items-center justify-content-center show-loader">
          <div className="lds-roller">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      );

    if (this.state.status !== 0 && this.state.status !== 0) {
      if (this.state.status === 403) {
        return (
          <div className="loader h-100 w-100 d-flex flex-row align-items-center justify-content-center show-loader">
            <img src={_403} alt="" />
          </div>
        );
      } else {
        return (
          <div className="loader h-100 w-100 d-flex flex-row align-items-center justify-content-center show-loader">
            <img src={_500} alt="" />
          </div>
        );
      }
    }
  };
}

class Portal extends Component {
  state = { showMenu: false };
  render() {
    if (typeof localStorage.token === "undefined") {
      this.props.history.push("/login");
    }
    const role = localStorage.getItem("role_id");
    return (
      <>
        <div id="wrapper" className="pt-5 mt-md-0 pt-md-0 mt-2">
          <Menu></Menu>
          <div id="content-wrapper" className="d-flex flex-column bg-light">
            <div className="preloader-container w-100">
              <ProgressBar />
            </div>
            <div className="d-flex flex-fill flex-column">
              {role === '2' ?
                <>
                  <Route path="/" exact component={Home} />
                  <Route path="/okoa-loans" exact component={OkoaLoans} />
                  <Route path="/breakdown" exact component={Breakdown} />
                  <Route path="/summary" exact component={Summary} />
                  <Route path="/active-loans" exact component={ActiveLoans} />
                  <Route path="/pending-loans" exact component={PendingLoans} />
                  <Route path="/paid-loans" exact component={PaidLoans} />
                  <Route path="/errored-loans" exact component={ErroredLoans} />
                  <Route path="/defaulted-loans" exact component={DefaultedLoans} />
                  <Route path="/okoa-agents" exact component={OkoaAgents} />
                  <Route path="/okoa-agent-accounts" exact component={OkoaAgentAccounts} />
                  <Route path="/okoa-agent-limits" exact component={OkoaAgentLimits} />
                  <Route path="/okoa-transactions" exact component={okoaTransactions} />
                  <Route path="/okoa-payments-logs" exact component={PaymentLogs} />
                  <Route
                    path={[
                      "/agent-view/:path/:id/:agent_id"
                    ]}
                    exact
                    component={AgentsView}
                  />
                  <Route path="/upload-documents/:id/:agent_id" exact component={UploadDocuments}/>
                  <Route path="/view-documents/:id/:agent_id" exact component={viewDocuments}/>
                  <Route
                    path={[
                      "/account-view/:path/:id/:agent_id",
                      "/account-view/:path/:id/:agent_id/:agent_account_id"
                    ]}
                    exact
                    component={AccountView}
                  />
                  <Route path="/customercare" exact component={CustomerCare} />
                  <Route path="/marketrep" exact component={MarketRep} />
                  <Route path="/okoa-admin" exact component={OkoaAdmins} />
                  <Route path="/suspense-payments" exact component={suspensePayment} />
                  <Route path="/missing-payments/:id" exact component={missingPayment} />
                  <Route
                    path={[
                      "/admin-view/:path/:id"
                    ]}
                    exact
                    component={AdminView}
                  />
                  <Route path="/member-add/:step" exact component={memberAdd} />
                  <Route path="/agent-add/:step" exact component={agentAdd} />
                  <Route path="/loan-summarries" exact component={LoanSummaries} />
                  <Route path="/loans/:status" exact component={Loans} />
                  <Route path="/loans" exact component={Loans} />
                  <Route path="/loan-requests" exact component={LoanRequests} />
                  <Route path="/loan-add/:step/:id" exact component={LoanAdd} />
                  <Route path="/loan-approve/:id" exact component={LoanApprove} />
                  <Route path="/loan-view/:path/:id/:loan_id" exact component={loanView} />
                  <Route path="/employees" exact component={Employees} />
                  <Route path="/employee-add/:step" exact component={EmployeeAdd} />
                  <Route path="/trial-balance" exact component={TrialBalance} />
                  <Route path="/balance-sheet" exact component={BalanceSheet} />
                  <Route path="/payments" exact component={Payments} />
                  <Route path="/petty" exact component={Petty} />
                  <Route path="/petty-records" exact component={PettyRecords} />
                  <Route path="/mpesa-deposits" exact component={MpesaDeposits} />
                  <Route path="/disbursements" exact component={Disbursements} />
                  <Route path="/sms" exact component={SMS} />
                  <Route path="/sms-create" exact component={SMSCreate} />
                  <Route path="/address-books" exact component={AddressBook} />
                  <Route
                    path="/address-book-create/:step"
                    exact
                    component={AddressBookCreate}
                  />
                  <Route
                    path="/income-statement"
                    exact
                    component={IncomeStatement}
                  />
                  <Route
                    path="/cash-flow-statement"
                    exact
                    component={CashFlowStatement}
                  />

                  <Route path="/savings" exact component={Savings} />
                  <Route path="/shares" exact component={Shares} />
                  <Route path="/loan-products" exact component={LoanProducts} />
                  <Route path="/sms-list" exact component={SMSList} />
                  <Route path="/suspense" exact component={Suspense} />
                  <Route path="/accounts-chart" exact component={AccountsChart} />
                  <Route
                    path="/product-create"
                    exact
                    component={LoanProductCreate}
                  />
                  <Route path="/vendors" exact component={Vendors} />
                  <Route path="/invoices" exact component={Invoices} />
                  <Route
                    path="/member-approve/:id"
                    exact
                    component={MemberApprove}
                  />
                  <Route path="/store-recon" exact component={StoreReconciliation} />
                  <Route path="/recon-missing" exact component={MissingReconciliation} />
                  <Route path="/mpesa/inwards" exact component={MpesaIn} />
                  <Route path="/mpesa/outwards" exact component={MpesaOut} />
                </>
                : role == 4 ?
                <>
                  <Route path="/" exact component={Home} />
                  {/* <Route path="/okoa-agents" exact component={OkoaAgents} /> */}
                  <Route path="/okoasearch-agents" exact component={OkoasearchAgents} />
                  {/* <Route path="/okoa-agent-accounts" exact component={OkoaAgentAccounts} /> */}
                  <Route path="/okoasearch-agent-accounts" exact component={OkoasearchAgentAccounts} />
                  {/* <Route path="/okoa-agent-limits" exact component={OkoaAgentLimits} /> */}
                  <Route path="/okoasearch-agent-limits" exact component={OkoasearchAgentLimits} />
                  {/* <Route path="/breakdown" exact component={Breakdown} /> */}
                   <Route path="/breakdownsearch" exact component={Breakdownsearch} /> 
                  <Route path="/summary" exact component={Summary} />
                  {/* <Route path="/active-loans" exact component={ActiveLoans} /> */}
                  <Route path="/activesearch-loans" exact component={ActiveLoanssearch} />
                  <Route path="/pending-loans" exact component={PendingLoans} />
                  {/* <Route path="/paid-loans" exact component={PaidLoans} /> */}
                  <Route path="/paidsearch-loans" exact component={PaidLoanssearch} />
                  {/* <Route path="/errored-loans" exact component={ErroredLoans} /> */}
                  <Route path="/erroredsearch-loans" exact component={ErroredLoanssearch} />
                  {/* <Route path="/defaulted-loans" exact component={DefaultedLoans} /> */}
                  <Route path="/defaultedsearch-loans" exact component={DefaultedLoanssearch} />
                  {/* <Route path="/okoa-loans" exact component={OkoaLoans} /> */}
                  <Route path="/okoasearch-loans" exact component={OkoaLoanssearch} />

                  <Route
                    path={[
                      "/agent-view/:path/:id/:agent_id"
                    ]}
                    exact
                    component={AgentsView}
                  />
                  <Route
                    path={[
                      "/account-view/:path/:id/:agent_id",
                      "/account-view/:path/:id/:agent_id/:agent_account_id"
                    ]}
                    exact
                    component={AccountView}
                  />
                  <Route path="/loan-view/:path/:id/:loan_id" exact component={loanView} />
                </>
                :
                <>
                 <Route path="/" exact component={Home} />
                  <Route path="/okoa-agents" exact component={OkoaAgents} />                
                  <Route path="/okoa-agent-accounts" exact component={OkoaAgentAccounts} />                
                  <Route path="/okoa-agent-limits" exact component={OkoaAgentLimits} />                
                  <Route path="/breakdown" exact component={Breakdown} />                
                  <Route path="/summary" exact component={Summary} />
                  <Route path="/active-loans" exact component={ActiveLoans} />                 
                  <Route path="/pending-loans" exact component={PendingLoans} />
                  <Route path="/paid-loans" exact component={PaidLoans} />                 
                  <Route path="/errored-loans" exact component={ErroredLoans} />                
                  <Route path="/defaulted-loans" exact component={DefaultedLoans} />                
                  <Route path="/okoa-loans" exact component={OkoaLoans} />
                 

                  <Route
                    path={[
                      "/agent-view/:path/:id/:agent_id"
                    ]}
                    exact
                    component={AgentsView}
                  />   
                  <Route path="/upload-documents/:id/:agent_id" exact component={UploadDocuments}/>
                  <Route path="/view-documents/:id/:agent_id" exact component={viewDocuments}/>              


                  <Route
                    path={[
                      "/account-view/:path/:id/:agent_id",
                      "/account-view/:path/:id/:agent_id/:agent_account_id"
                    ]}
                    exact
                    component={AccountView}
                  />
                  <Route path="/loan-view/:path/:id/:loan_id" exact component={loanView} />
                </>
              }

            </div>
            <img src={stamp} className="stamp" alt="" />
          </div>
        </div>
      </>
    );
  }
  componentDidMount() {
    if (localStorage.token) {
      checkTokenValidity();
    }
  }
}

window.verifyNumber = n => {
  n = n + "";
  if (n[0] + n[1] + n[2] === "254") {
    return parseInt(n);
  } else {
    return parseInt("254" + parseInt(n));
  }
};

window.fetch = (url, options) => {
  return new Promise((resolve, reject) => {
    window
      .standardFetch(url, options)
      .then(response => {
        resolve(response);
        return response.clone().json();
      })
      .then(response => {
        // //console.log(response);
        // response.json = () => {
        //   return response;
        // };
        // resolve(response);

        if (response.ErrorCode === 401 && localStorage.token) {
          window.alert2.confirm({
            title: "Your Session has Expired",
            description: "Please Login once more to continue using the system",
            color: "material-deep-orange",
            autoClose: false,
            onClose: () => {
              window.logout(true);
            }
          });
        }
      })
      .catch(error => {
        // window.alert2.notify("Your session has expired");
        reject(error);

        // window.alert2.notify({
        //   title: "Connection error",
        //   description:
        //     "There seems like there's a connectio problem, please try again later",
        //   color: "material-deep-orange",
        //   autoClose: false,
        //   onClose: () => {
        //     window.logout(true);
        //   }
        // });

        // //console.log(error);
        reject(error);
      });
  });
};

export default withRouter(App);
