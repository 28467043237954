import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";
import {
  Edit
} from "react-feather";
import axios from 'axios';
import { ToastsContainer, ToastsStore } from 'react-toasts';

class okoaAgentAccounts extends Component {
  state = {
    editModalAccount: false,
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false
  };

  onChangeAgentNumber(e) {
    this.setState({ agent_no: e.target.value })
  }
  onChangeStoreNumber(e) {
    this.setState({ store_number: e.target.value })
  }
  onChangeLimit(e) {
    this.setState({ limit: e.target.value })
  }
  onChangeBusinessName(e) {
    this.setState({ business_name: e.target.value })
  }
  onChangeLocation(e) {
    this.setState({ location: e.target.value })
  }
  onChangeAccountStatus(e) {
    this.setState({ status: e.target.value })
  }
  onSubmitAccount(e) {
    e.preventDefault()

    const userObjectAccount = {
      agent_no: this.state.agent_no,
      store_number: this.state.store_number,
      limit: this.state.limit,
      business_name: this.state.business_name,
      location: this.state.location,
      status: this.state.status,
    };

    let agentaccount = this.state.agent_account_id;

    axios.patch(`${window.server}/agent-accounts/${agentaccount}`, userObjectAccount, { headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*", Authorization: localStorage.token } })
      .then((res) => {
        ToastsStore.success("Agent Account Has Been Updated Successfully.");
        window.location.reload();
      }).catch((error) => {
        //console.log(error)
        ToastsStore.error("Error. Kindly Try Again.");
      });

    //this.setState({ first_name: ''})
  }

  timeout = null;
  render() {
    const role = localStorage.getItem("role_id");
    return (
      <div className="">
        <Nav
          name="Agent Accounts"
        ></Nav>
        <div className="mt-3  border-0 shado mx-3 bg-white">
          <div className="p-4">
            <Table
              search={["agent_no", "agent_account_id","agent_id","store_number", "business_name", "location", "owner_name", "owner_msisdn", "operator_msisdn"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={params => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
            <Modal
              visible={this.state.editModalAccount}
              close={() => this.setState({ editModalAccount: false })}
            >
              <h5 className="font-weight-bold" style={{ margin: "15px" }}>Update Agent Account</h5>

              <form onSubmit={this.onSubmitAccount}>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Agent Number</span>
                  <input className="form-control bg-light text-input" type="text" value={this.state.agent_no} onChange={this.onChangeAgentNumber} />
                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Store Number</span>
                  <input className="form-control bg-light text-input" type="text" value={this.state.store_number} onChange={this.onChangeStoreNumber} />
                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Limit (Kshs.)</span>
                  <input className="form-control bg-light text-input" type="text" value={this.state.limit} onChange={this.onChangeLimit} disabled={role == 1 ? true : false} />
                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Business Name</span>
                  <input className="form-control bg-light text-input" type="text" value={this.state.business_name} onChange={this.onChangeBusinessName} />
                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Location</span>
                  <input className="form-control bg-light text-input" type="text" value={this.state.location} onChange={this.onChangeLocation} />
                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Account Status</span>
                  <select className="form-control bg-light" value={this.state.status} onChange={this.onChangeAccountStatus}>
                    <option value="1">Active</option>
                    <option value="2">In Active</option>
                  </select>
                </div>
                <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                  <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Update</button>
                </div>
              </form>
            </Modal>
            <ToastsContainer store={ToastsStore} />
          </div>
        </div>
      </div>
    );
  }

  fetchAgents = () => {
    this.setState({ table_loading: true });
    let q = {
      // ...this.state.filter,
      ...this.state.query
    };


    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");

    fetch(`${window.server}/agent-accounts?${urlParams}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.token
      }
    }).then(response => response.json()).then(response => {
      console.log("AGENT ACCOUNTS------------------", response);
      let data = [];
      response.data.map((d, i) => {
        data.push({
          //id: d.id,
          agent_number: d.agent_no,
          store_number: d.store_number,
          owner_name: d.owner_name,
          owner_msisdn: d.owner_msisdn,
          operator_msisdn: d.operator_msisdn,
          limit: "KES." + parseInt(d.limit).toLocaleString(),
          wallet: "KES." + parseInt(d.agent.wallet).toFixed(2),
          business_name: d.business_name,
          location: d.location,
          agent_type: d.agent_type== 1 ?"Agent":"SubAgent",
          date_created: moment(d.created_at).format('DD-MM-YYYY HH:mm:ss'),
          status: parseFloat(d.status) === 1 ?<span className="badge badge-success">ACTIVE</span> :
          parseFloat(d.status) === 2 ? <span className="badge badge-primary">INACTIVE</span>:<span className="badge badge-danger">SUSPENDED</span>,


          // action: (
          //   <button
          //       onClick={() => {
          //         this.setState({ editModalAccount: true });
          //       }}
          //       className="btn btn-primary btn-round pc-3"
          //     >
          //       <Edit size={18} />
          //       <span className="pl-1 font-weight-bold">Edit account</span>
          //   </button>
          // ),
          action: (
            <div className="d-flex flex-row">
              <Link
                to={"/account-view/account/" + d.id + "/" + d.agent_id}
                className="btn btn-sm btn-primary"
              >
                View
                </Link>
            </div>
          )
        });
      });

      let dts = {};
      dts.data = data;

      this.setState({
        tableData: { ...response, ...dts },
        response,
        table_loading: false
      });
    })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  // fetchaccount = () => {
  //   let agent = this.props.match.params.agent_id;
  //   fetch(`${window.server}/agent-accounts?agent_id=${agent}`, {
  //     headers: {
  //       Authorization: localStorage.token
  //     }
  //   })
  //     .then(response => response.json())
  //     .then(response => {
  //       //console.log(response.data[0]);
  //       this.setState({ ...response.data[0] });
  //     })
  //     .catch(d => {
  //       this.setState({ tableError: true });
  //       //console.log(d);
  //     });
  // };

  // componentDidMount = () => {
  //   this.fetchaccount();
  // };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchAgents();
      }, 100);
    }
  }
}

export default okoaAgentAccounts;
