import React, { Component } from "react";
import Nav from "../../components/Nav";
import { Route, Link } from "react-router-dom";
import Form from "../../components/form";
import {
  X,
  User,
  Search,
  XCircle,
  Printer,
  MapPin,
  CheckCircle
} from "react-feather";
import Modal from "../../components/modal";
import logo from "../../img/_logo.png";
import LButton from "../../components/loadingButton";
import moment from "moment";
import TableSM from "../../components/tableSM";

class LoanAdd extends Component {
  state = {
    userDetails: {},
    details: {
      customer_id: this.props.match.params.id,
      product_id: "PAOIZH",
      amount: "15000",
      repayment_duration: "8",
      description: "Repair my leaking roof",
      officer_id: null,
      comments: ""
    },
    security: [
      {
        name: "",
        description: "",
        original_value: "",
        current_value: "",
        files: ""
      }
    ],
    officers: [],
    guarantors: [
      {
        status: 2,
        msisdn: "254717638548",
        firstname: "gabriel",
        middlename: "kamau",
        surname: "muhangia",
        id_number: "2324234",
        customer_id: "CVQXMTFX0CPEYHC",
        savings: {
          actual_balance: "17500.00",
          customer_id: "CVQXMTFX0CPEYHC",
          available_balance: "0.00"
        },
        guaranteed_amount: "3000"
      }
    ],
    officerModal: false,
    officerSearch: "",
    officerDetails: {},
    acceptAgreement: false,
    commentsEdit: false
  };
  render() {
    return (
      <div className="page">
        <Nav name="Create a new Loan"></Nav>
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow mb-5">
          <div className="p-md-4 p-3">
            <Route
              path={`/loan-add/1/${this.props.match.params.id}`}
              exact
              render={props => (
                <div className="mt-2 ">
                  <h4>1. Loan Details</h4>
                  <div className="my-3 pt-5">
                    <form
                      className="d-flex flex-row align-items-start flex-wrap"
                      onSubmit={e => {
                        e.preventDefault();

                        props.history.push(
                          `/loan-add/2/${this.props.match.params.id}`
                        );
                      }}
                    >
                      <div className="mx-3 d-inline-block mb-3">
                        <span className="ml-1 mb-2 d-block">
                          Loan Amount (Maximum 20,000)
                        </span>
                        <input
                          type="number"
                          placeholder="..."
                          className="form-control bg-light text-input"
                          value={this.state.details.amount}
                          onChange={e => {
                            this.setState({
                              details: {
                                ...this.state.details,
                                amount: e.target.value
                              }
                            });
                          }}
                          required={true}
                        />
                      </div>
                      <div className="mx-3 d-inline-block mb-3">
                        <span className="ml-1 mb-2 d-block">
                          Loan Duration (Days)
                        </span>
                        <input
                          type="number"
                          placeholder="..."
                          className="form-control bg-light text-input"
                          value={this.state.details.repayment_duration}
                          onChange={e => {
                            this.setState({
                              details: {
                                ...this.state.details,
                                repayment_duration: e.target.value
                              }
                            });
                          }}
                          required={true}
                        />
                      </div>

                      <div className="w-100"></div>

                      <div className="d-inline-block">
                        <div className="mx-3 d-inline-block mb-3">
                          <span className="ml-1 mb-2 d-block">
                            Loan Product
                          </span>
                          <select
                            className="form-control bg-light text-input"
                            value={this.state.details.product_id}
                            onChange={e => {
                              this.setState({
                                details: {
                                  ...this.state.details,
                                  product_id: e.target.value
                                }
                              });
                            }}
                            required={true}
                          >
                            <option hidden="" selected>
                              Select loan product
                            </option>
                            {window.products.map((d, i) => (
                              <option key={i} value={d.product_code}>
                                {d.product_name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div></div>
                        <div className="mx-3 d-inline-block mb-3">
                          <span className="ml-1 mb-2 d-block">
                            Loan Purpose
                          </span>
                          <textarea
                            type="textarea"
                            placeholder="..."
                            className="form-control bg-light text-input"
                            value={this.state.details.description}
                            onChange={e => {
                              this.setState({
                                details: {
                                  ...this.state.details,
                                  description: e.target.value
                                }
                              });
                            }}
                            required={true}
                          ></textarea>
                        </div>
                      </div>

                      {/* officer card */}

                      <div className="officer-holder">
                        {!this.state.details.officer_id && (
                          <div className=" mx-3">
                            <span className="ml-1 mb-2 d-block">
                              Loan Officer
                            </span>
                            <div
                              className="form-control btn btn-outline-primary text-input d-flex flex-row align-items-center"
                              onClick={() => {
                                this.setState({ officerModal: true });
                              }}
                            >
                              <User className="mr-2"></User>{" "}
                              <span>Choose an Officer</span>
                            </div>
                          </div>
                        )}

                        {this.state.details.officer_id &&
                          this.state.officers
                            .filter(
                              d => d.user_id === this.state.details.officer_id
                            )
                            .map((d, i) => (
                              <div
                                key={i}
                                className="officer-card-parent mx-3 text-input pr-3 mt-3"
                              >
                                <div className=" d-flex flex-row guarantor-card align-items-center">
                                  <div className="mx-3">
                                    <div className="user-profile">
                                      <span>{d.firstname[0]}</span>
                                    </div>
                                  </div>
                                  <div className="py-3 w-100">
                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                      <h5 className="font-weight-bold">
                                        {d.firstname} {d.middlename} {d.surname}{" "}
                                      </h5>
                                      <div
                                        className="action-icon"
                                        onClick={() => {
                                          let { details } = this.state;
                                          details.officer_id = null;
                                          this.setState({ details });
                                        }}
                                      >
                                        <XCircle></XCircle>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row align-items-center flex-fill">
                                      <div>
                                        <small className="font-weight-bold">
                                          Nationa ID
                                        </small>
                                        <div>{d.id_number}</div>
                                      </div>
                                      <div className="mx-3 content-divider">
                                        {" "}
                                      </div>
                                      <div>
                                        <small className="font-weight-bold">
                                          Phone Number
                                        </small>
                                        <div>{d.msisdn}</div>
                                      </div>
                                    </div>
                                    <div className=" mt-2 d-flex flex-row justify-content-between align-items-center w-100">
                                      <div className="center">
                                        <MapPin
                                          size={16}
                                          className="mr-2"
                                        ></MapPin>
                                        <span className="font-weight-bold">
                                          {
                                            window.wardsList[
                                              parseInt(d.ward_id)
                                            ]
                                          }
                                        </span>
                                      </div>
                                      <div className="badge badge-primary px-3 d-inline-block">
                                        Officer
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                      </div>

                      {/* end officer card */}

                      <div className="w-100"></div>

                      <div className=" my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                        <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">
                          Next
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            />

            <Route
              path={`/loan-add/2/${this.props.match.params.id}`}
              exact
              render={props => (
                <div className="mt-2 ">
                  <h4>2. Loan Security</h4>
                  <hr className="mb-4" />
                  <form
                    className="container"
                    onSubmit={e => {
                      e.preventDefault();
                    }}
                  >
                    {this.state.security.map((d, i) => (
                      <div
                        key={i}
                        className="p-3 kin-container mb-4 position-relative"
                      >
                        <div className="position-absolute icon-hover modal-close-icon">
                          <X
                            className="icon modal-close-icon m-2 mr-4"
                            onClick={() => {
                              let { security } = this.state;
                              security.splice(i, 1);
                              this.setState({ security });
                            }}
                          />
                        </div>
                        <h5 className="ml-3 mb-3 font-weight-bold text-capitalize">
                          Item {i + 1}
                        </h5>
                        {Object.keys(d).map((d1, i1) => {
                          if (d1 === "files") {
                            return (
                              <div
                                className="mx-3 d-inline-block mb-3"
                                key={i1}
                              >
                                <span className="ml-1 mb-2 d-block text-capitalize">
                                  {d1.replace(/_/g, " ")}
                                </span>
                                <input
                                  value={this.state.security[i][d1]}
                                  placeholder="..."
                                  className="form-control text-input bg-light"
                                  onChange={e => {
                                    let { security } = this.state;
                                    security[i][d1] = e.target.value;
                                    this.setState({ security });
                                  }}
                                  type={"file"}
                                  multiple={true}
                                />
                              </div>
                            );
                          }

                          return (
                            d1 !== "type" && (
                              <div
                                className="mx-3 d-inline-block mb-3"
                                key={i1}
                              >
                                <span className="ml-1 mb-2 d-block text-capitalize">
                                  {d1.replace(/_/g, " ")}
                                </span>
                                <input
                                  value={this.state.security[i][d1]}
                                  placeholder="..."
                                  className="form-control text-input bg-light"
                                  required={true}
                                  onChange={e => {
                                    let { security } = this.state;
                                    security[i][d1] = e.target.value;
                                    this.setState({ security });
                                  }}
                                  type={d1 === "birth_date" ? "date" : "text"}
                                />
                              </div>
                            )
                          );
                        })}
                      </div>
                    ))}

                    <div className="d-flex flex-row justify-content-center">
                      <button
                        type="button"
                        onClick={() => {
                          let { security } = this.state;
                          security.push({
                            name: "",
                            description: "",
                            original_value: "",
                            current_value: "",
                            files: ""
                          });
                          // //console.log(e.target.value);
                          this.setState({ security });
                        }}
                        className="btn btn-lg btn-round btn-primary mt-5 text-center px-5"
                      >
                        Add an Item
                      </button>
                    </div>

                    <div className=" my-3 d-flex flex-row justify-content-between mt-5">
                      <button
                        className="btn btn-outline-primary px-5  btn-round"
                        type="button"
                      >
                        Back
                      </button>

                      <button
                        onClick={() => {
                          props.history.push(
                            `/loan-add/3/${this.props.match.params.id}`
                          );
                        }}
                        className="btn btn-primary px-5  btn-round"
                        disabled={this.state.security.length < 1}
                        type="submit"
                      >
                        Next
                      </button>
                    </div>
                  </form>
                </div>
              )}
            />

            <Route
              path={`/loan-add/3/${this.props.match.params.id}`}
              exact
              render={props => (
                <div className="mt-2 ">
                  <h4>3. Loan Guarantors</h4>
                  <hr className="mb-4" />
                  <form
                    className="container"
                    onSubmit={e => {
                      e.preventDefault();
                      //console.log(this.state.guarantors);
                      props.history.push(
                        `/loan-add/4/${this.props.match.params.id}`
                      );
                    }}
                  >
                    {this.state.guarantors.map((d, i) => {
                      if (typeof d.status === "undefined") d.status = 0;

                      return (
                        <div
                          key={i}
                          className="guarantor kin-container mb-4 position-relative"
                        >
                          <button
                            className="position-absolute btn btn-outline-primary btn-round pr-3 modal-close-button py-1 pl-2"
                            type="button"
                            onClick={() => {
                              let { guarantors } = this.state;
                              guarantors.splice(i, 1);
                              //console.log(guarantors);
                              this.setState({ guarantors });
                            }}
                          >
                            <XCircle
                              size={20}
                              className="icon modal-close-icon mr-2"
                            />
                            <span>Remove</span>
                          </button>

                          {!d.customer_id && (
                            <div className="p-3">
                              <div className="mx-3 d-inline-block mb-3">
                                <span className="ml-1 mb-2 d-block text-capitalize">
                                  Enter Guarantor's Phone Number
                                </span>
                                <input
                                  placeholder="..."
                                  className="form-control text-input bg-light"
                                  value={d.msisdn}
                                  required={true}
                                  onChange={e => {
                                    let { guarantors } = this.state;
                                    guarantors[i].msisdn = e.target.value;
                                    this.setState({ guarantors });
                                  }}
                                  type={"text"}
                                />
                              </div>
                              <div className="d-inline-block">
                                <LButton
                                  status={d.status}
                                  className="btn btn-primary px-4  btn-round"
                                  text="Next"
                                  onClick={() => this.getGuarantor(i)}
                                ></LButton>
                              </div>
                            </div>
                          )}

                          {d.customer_id && (
                            <div className=" d-flex flex-row guarantor-card align-items-center flex-wrap">
                              <div className="mx-4">
                                <div className="user-profile">
                                  <span>G</span>
                                </div>
                              </div>
                              <div className="py-3">
                                <h5 className="font-weight-bold text-capitalize">
                                  {d.firstname} {d.middlename} {d.surname}{" "}
                                </h5>
                                <div className="d-flex flex-row align-items-center">
                                  <div>
                                    <small className="font-weight-bold">
                                      Nationa ID
                                    </small>
                                    <div>{d.id_number}</div>
                                  </div>
                                  <div className="mx-3 content-divider"> </div>
                                  <div>
                                    <small className="font-weight-bold">
                                      Phone Number
                                    </small>
                                    <div>{d.msisdn}</div>
                                  </div>
                                </div>
                                <div className=" mt-2">
                                  Available Balance :{" "}
                                  <span className="font-weight-bold">
                                    KES{" "}
                                    {parseFloat(
                                      d.savings.actual_balance
                                    ).toLocaleString()}
                                  </span>
                                </div>
                              </div>

                              <div className="d-none d-md-inline-block mx-4 guarantor-divider"></div>

                              <div className="flex-column justify-content-center">
                                <div className="mx-3 d-inline-block mb-3">
                                  <span className="ml-1 mb-2 d-block">
                                    Amount Guaranteed
                                  </span>
                                  <input
                                    type="number"
                                    placeholder="..."
                                    className="form-control bg-light text-input"
                                    value={d.guaranteed_amount}
                                    onChange={e => {
                                      let { guarantors } = this.state;
                                      guarantors[i].guaranteed_amount =
                                        e.target.value;
                                      this.setState({ guarantors });
                                    }}
                                    required={true}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}

                    <div className="d-flex flex-row justify-content-center">
                      <button
                        type="button"
                        onClick={() => {
                          let { guarantors } = this.state;
                          guarantors.push({});
                          // //console.log(e.target.value);
                          this.setState({ guarantors });
                        }}
                        className="btn btn-lg btn-round btn-primary mt-5 text-center px-5"
                      >
                        Add a Guarantor
                      </button>
                    </div>

                    <div className=" my-3 d-flex flex-row justify-content-between mt-5">
                      <button
                        className="btn btn-outline-primary px-5  btn-round"
                        type="button"
                      >
                        Back
                      </button>

                      <button
                        className="btn btn-primary px-5  btn-round"
                        disabled={this.state.guarantors.length < 1}
                        type="submit"
                      >
                        Next
                      </button>
                    </div>
                  </form>
                </div>
              )}
            />

            <Route
              path={`/loan-add/4/${this.props.match.params.id}`}
              exact
              render={props => (
                <>
                  <div className="d-flex flex-row align-items-center justify-content-between mb-5 verification-title">
                    <h4>4. Verification</h4>

                    <button
                      className="btn btn-primary btn-round pr-3"
                      onClick={this.print}
                    >
                      <Printer size={18}></Printer> Print Document
                    </button>
                  </div>
                  <div className="d-flex flex-row justify-content-">
                    <div className="virtual-form w-100" id="virtual-form">
                      <div className="text-center">
                        <img src={logo} alt="" className="logo" />
                        <h4 className="font-weight-bold my-3">
                          KACOMA SACCO MANAGEMENT SYSTEM
                        </h4>
                        <h5 className="font-weight-bold">
                          LOAN APPRAISAL FORM
                        </h5>
                      </div>

                      <div className="mt-5">
                        <div className="mt-5">
                          <div className="document-passport position-relative">
                            {this.state.userDetails.passport_path && (
                              <img
                                src={
                                  window.server +
                                  "/" +
                                  this.state.userDetails.passport_path
                                }
                                className="w-100 h-100"
                                alt=""
                              />
                            )}
                          </div>

                          <div className="mb-5">
                            <div className="page-title">
                              APPLICANT'S DETAILS
                            </div>
                            <div className="">
                              <div className="page-card p-3">
                                <div className="row">
                                  {this.state.userDetails.firstname &&
                                    this.generateUserInputs()}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mb-5">
                          <div className="page-title">LOAN DETAILS</div>
                          <div className="">
                            <div className="page-card p-3">
                              <div className="row">
                                {this.state.details.officer_id &&
                                  this.generateLoanInputs()}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mb-5">
                          <div className="page-title">LOAN SECURITIES</div>
                          <div className="">
                            <div className="page-card">
                              <table className="table table-striped">
                                <thead>
                                  <th> Name </th>
                                  <th> Description </th>
                                  <th> Original Value </th>
                                  <th> Current Value </th>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>MOBILE PHONE</td>
                                    <td>
                                      Lorem ipsum, dolor sit amet consectetur
                                      adipisicing elit.
                                    </td>
                                    <td>KES 1,000</td>
                                    <td>
                                      <b>KES 3,000</b>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div className="mb-5">
                          <div className="page-title">GUARANTOR DETAILS</div>
                          <div className="">
                            <div className="page-card">
                              <TableSM
                                data={this.state.guarantors.map(d => {
                                  return {
                                    full_name: `${d.firstname} ${d.middlename} ${d.surname}`,
                                    ID_number: d.id_number,
                                    savings_balance:
                                      "KES " +
                                      parseFloat(
                                        d.savings.actual_balance
                                      ).toLocaleString(),
                                    guaranteed_amount:
                                      "KES " +
                                      parseFloat(
                                        d.guaranteed_amount
                                      ).toLocaleString()
                                  };
                                })}
                              ></TableSM>
                            </div>
                          </div>
                        </div>

                        <div className="text-uppercase">
                          <h5 className="font-weight-bold">LOAN APPRAISAL</h5>
                        </div>

                        <div className="mb-5">
                          <div className="page-title">CREDIT HISTORY</div>
                          <div className="">
                            <div className="page-card">
                              <table className="table table-striped">
                                <thead>
                                  <th> Amount </th>
                                  <th> Period </th>
                                  <th> Loan Type </th>
                                  <th> Amount </th>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>SIMON KIHARA</td>
                                    <td>23443534</td>
                                    <td>07122323432</td>
                                    <td>
                                      <b>KES 3,000</b>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <hr className="print-hide mt-5 mb-4" />

                        {this.state.acceptAgreement && (
                          <h5 className="font-weight-bold mb-4 mt-2">
                            APPROVED BY
                          </h5>
                        )}

                        {this.state.acceptAgreement && (
                          <div className="row d-flex align-items-center">
                            <div className=" d-flex flex-row guarantor-card align-items-center col-md-4">
                              <div className="mx-3">
                                <div className="user-profile">
                                  <span>{window.user.user.firstname[0]}</span>
                                </div>
                              </div>
                              <div className="py-3 w-100">
                                <div className="d-flex flex-row align-items-center justify-content-between">
                                  <h5 className="font-weight-bold">
                                    {window.user.user.firstname}{" "}
                                    {window.user.user.middlename}{" "}
                                    {window.user.user.surname}{" "}
                                  </h5>
                                </div>
                                <div className="d-flex flex-row align-items-center flex-fill">
                                  <div>
                                    <small className="font-weight-bold">
                                      Nationa ID
                                    </small>
                                    <div>{window.user.user.id_number}</div>
                                  </div>
                                  <div className="mx-3 content-divider"> </div>
                                  <div>
                                    <small className="font-weight-bold">
                                      Phone Number
                                    </small>
                                    <div>{window.user.user.msisdn}</div>
                                  </div>
                                </div>
                                <div className=" mt-3 d-flex flex-row  align-items-center w-100">
                                  <div className="badge badge-primary px-3 d-inline-block">
                                    Loan Officer
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-1"></div>

                            <div className="col-md-7">
                              <div className="d-flex flex-row mb-2 justify-content-between align-items-center">
                                <h5 className="font-weight-bold m-0">
                                  Comments
                                </h5>
                                {this.state.commentsEdit && (
                                  <button
                                    onClick={() => {
                                      this.setState({
                                        commentsEdit: !this.state.commentsEdit
                                      });
                                    }}
                                    className="btn btn-primary btn-sm btn-round px-3"
                                  >
                                    Done
                                  </button>
                                )}

                                {!this.state.commentsEdit && (
                                  <button
                                    onClick={() => {
                                      this.setState({
                                        commentsEdit: !this.state.commentsEdit
                                      });
                                    }}
                                    className="btn btn-outline-primary btn-sm btn-round px-3"
                                  >
                                    Edit
                                  </button>
                                )}
                              </div>
                              <div className="form-comments">
                                {!this.state.commentsEdit && (
                                  <div className="text-data">
                                    {this.state.details.comments}
                                    {this.state.details.comments.trim() ===
                                      "" && (
                                      <div className="h-100 flex-fill d-flex flex-row justify-content-center align-items-center">
                                        <div className="text-muted">
                                          Click Edit to add comment
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                                {this.state.commentsEdit && (
                                  <div className="print-hide">
                                    <div>
                                      <textarea
                                        rows="10"
                                        className="w-100 bg-light form-control"
                                        value={this.state.details.comments}
                                        rows={4}
                                        onChange={e => {
                                          let { details } = this.state;
                                          details.comments = e.target.value;
                                          this.setState({
                                            details
                                          });
                                        }}
                                      ></textarea>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="text-center print-hide">
                          {!this.state.acceptAgreement && (
                            <div className="d-flex flex-row align-items-center">
                              <label>
                                <input
                                  type="checkbox"
                                  className="mr-3 styled-checkbox"
                                  id="342"
                                  name=""
                                  checked={this.state.acceptAgreement}
                                  onChange={() => {
                                    this.setState({
                                      acceptAgreement: !this.state
                                        .acceptAgreement
                                    });
                                  }}
                                />
                                <div></div>
                              </label>
                              <div className="text-left">
                                I declare that the information given herein is
                                true to the best of my knowledge and belief: I
                                further authorise the SACCO to verify the
                                information given herein and make reference from
                                any person(s)/institution(s) named herein.
                              </div>
                            </div>
                          )}

                          <button
                            className="btn btn-primary btn-round px-5 mt-5 print-hide"
                            onClick={this.addLoan}
                            disabled={!this.state.acceptAgreement}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            />
          </div>
        </div>

        {/* end */}

        <Modal
          visible={this.state.officerModal}
          close={() => this.setState({ officerModal: false })}
        >
          <h5 className="font-weight-bold">Find An officer</h5>
          <hr />
          <form
            className="d-flex flex-column justify-content-center position-relative"
            onSubmit={e => {
              e.preventDefault();
              this.findOfficer();
            }}
          >
            <Search className="search-icon cursor-pointer" color="grey" />
            <input
              type="search"
              className="form-control pl-5 rounded bg-light"
              placeholder="search"
              onChange={e => {
                this.setState({ officerSearch: `%${e.target.value}%` });
              }}
            />
          </form>
          <div className="officers-list w-100 list-group list-group-flush mt-3 border-top">
            {this.state.officers.map(d => (
              <div
                className="list-group-item list-group-item-action d-flex flex-row align-items-center cursor-pointer"
                onClick={() => {
                  let { details } = this.state;
                  details.officer_id = d.user_id;
                  //console.log(details);
                  this.setState({
                    details,
                    officerModal: false
                  });
                }}
              >
                <User size={18} className="mr-3"></User>
                <span>
                  {d.firstname} {d.middlename} {d.surname}{" "}
                </span>
              </div>
            ))}
          </div>
        </Modal>
      </div>
    );
  }

  getGuarantor = index => {
    let { guarantors } = this.state;
    guarantors[index].status = 1;
    this.setState({ guarantors });

    let guarantor = guarantors[index];

    this.setState({ table_loading: true });

    let q = {
      "$or[firstname][$like]": `%${guarantor.msisdn}%`,
      "$or[middlename][$like]": `%${guarantor.msisdn}%`,
      "$or[surname][$like]": `%${guarantor.msisdn}%`,
      ...this.state.query
    };

    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");

    // //console.log(urlParams);
    fetch(`${window.server}/customer?${urlParams}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response.code || !response.data.length) {
          guarantors[index].status = 0;
          this.setState({ guarantors });

          window.alert2.notify({
            title: "No Guarantor found",
            description: "No User was found with the specified fields",
            color: "material-deep-orange"
          });
        } else {
          let d = response.data[0];

          guarantors[index] = {
            ...guarantors[index],
            ...{
              status: 2,
              msisdn: d.msisdn.msisdn,
              firstname: d.firstname,
              middlename: d.middlename,
              surname: d.surname,
              id_number: d.details.id_number,
              customer_id: d.id,
              savings: {
                actual_balance: "17500.00",
                customer_id: "CVQXMTFX0CPEYHC",
                available_balance: "0.00"
              }
            }
          };

          //console.log(guarantors);

          guarantors[index].status = 2;
          this.setState({ guarantors });
        }
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  print = () => {
    window.print();
  };

  findOfficer = () => {
    let q = {
      "$or[firstname][$like]": this.state.officerSearch,
      "$or[middlename][$like]": this.state.officerSearch,
      "$or[surname][$like]": this.state.officerSearch,
      "$or[msisdn][$like]": this.state.officerSearch,
      ...this.state.query
    };

    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");

    // //console.log(urlParams);
    fetch(`${window.server}/users?${urlParams}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        this.setState({
          officers: response.data
        });
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidMount() {
    let user_id = this.props.match.params.id;
    fetch(`${window.server}/customer?id=${user_id}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        //console.log(response.data[0]);
        let d = response.data[0];
        this.setState({
          userDetails: {
            firstname: d.firstname,
            middlename: d.middlename,
            surname: d.surname,
            msisdn: d.msisdn.msisdn,
            date_of_birth: d.details.date_of_birth,
            gender: d.details.gender === "M" ? 0 : 1,
            marital_status: d.details.marital_status,
            identity_type: d.details.identity_type,
            id_number: d.details.id_number,
            kra_pin: d.details.kra_pin,
            sub_county_id: d.details.sub_county_id,
            ward_id: d.details.ward_id,
            village: d.details.village,
            address: d.details.address,
            nationality: d.details.nationality,
            pledge: d.pledge
          }
        });
      })
      .catch(d => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  }

  generateUserInputs = () => {
    let data = {
      full_name: `${this.state.userDetails.firstname} ${this.state.userDetails.middlename} ${this.state.userDetails.surname}`,
      date_of_birth: moment(this.state.userDetails.date_of_birth).format(
        "Do MMMM YYYY"
      ),
      "Phone Number": this.state.userDetails.msisdn,
      KRA_pin: this.state.userDetails.kra_pin,
      gender: ["Male", "Female"][this.state.userDetails.gender],
      sub_county:
        window.subCountiesList[parseInt(this.state.userDetails.sub_county_id)],
      ward: window.wardsList[parseInt(this.state.userDetails.ward_id)],
      village: this.state.userDetails.village,
      ID_number: this.state.userDetails.id_number,
      address: this.state.userDetails.address,
      nationality: this.state.userDetails.nationality,
      pledge: "KES " + parseInt(this.state.userDetails.pledge).toLocaleString()
    };

    // //console.log(data);

    return Object.keys(data).map(d => (
      <div className="col-md-3 pb-3">
        <div className="page-label text-capitalize">{d.replace(/_/g, " ")}</div>
        <div>{data[d] || "-"}</div>
      </div>
    ));
  };

  generateLoanInputs = () => {
    let data = {
      "Loan Amount": "KES " + this.state.details.amount,
      product: this.state.details.product_id,
      repayment_duration: this.state.details.repayment_duration + " days",
      description: this.state.details.description
      // officer_id: this.state.details.officer_id
    };

    //console.log(data);

    let obj = Object.keys(data).map(d => (
      <div className="col-md-3 pb-3">
        <div className="page-label text-capitalize">{d.replace(/_/g, " ")}</div>
        <div>{data[d] || "-"}</div>
      </div>
    ));

    let d = this.state.officers.filter(
      d => d.user_id === this.state.details.officer_id
    )[0];

    obj.push(
      <>
        <div className="px-3">
          <div className="mb-2 font-weight-bold">Loan Officer</div>
          <div className="officer-card-parent text-input pr-3">
            <div className=" d-flex flex-row guarantor-card align-items-center">
              <div className="mx-3">
                <div className="user-profile">
                  <span>{d.firstname[0]}</span>
                </div>
              </div>
              <div className="py-3 w-100">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <h5 className="font-weight-bold">
                    {d.firstname} {d.middlename} {d.surname}{" "}
                  </h5>
                  <div
                    className="action-icon"
                    onClick={() => {
                      let { details } = this.state;
                      details.officer_id = null;
                      this.setState({ details });
                    }}
                  >
                    <XCircle></XCircle>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center flex-fill">
                  <div>
                    <small className="font-weight-bold">Nationa ID</small>
                    <div>{d.id_number}</div>
                  </div>
                  <div className="mx-3 content-divider"> </div>
                  <div>
                    <small className="font-weight-bold">Phone Number</small>
                    <div>{d.msisdn}</div>
                  </div>
                </div>
                <div className=" mt-2 d-flex flex-row justify-content-between align-items-center w-100">
                  <div className="center">
                    <MapPin size={16} className="mr-2"></MapPin>
                    <span className="font-weight-bold">
                      {window.wardsList[parseInt(d.ward_id)]}
                    </span>
                  </div>
                  <div className="badge badge-primary px-3 d-inline-block">
                    Officer
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );

    return obj;
  };

  addLoan = () => {
    //console.log();

    let postData = {
      ...this.state.details,
      guarantors: this.state.guarantors
    };

    window.alert2.show({
      loader: true,
      title: "Adding User",
      buttons: true
    });

    fetch(`${window.server}/utils/new-loan-request`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(postData)
    })
      .then(response => response.json())
      .then(response => {
        //console.log(response);
        if (response.code) {
          window.alert2.show({
            loader: false,
            icon: <CheckCircle size={60}></CheckCircle>,
            title: "Loan Created Successfully",
            buttons: true,
            onSubmit: () => {
              this.props.history.push(`/loans`);
            }
          });
        } else {
          window.alert2.show({
            loader: false,
            icon: <CheckCircle size={60}></CheckCircle>,
            title: "Loan Created Successfully",
            buttons: true,
            onSubmit: () => {
              this.props.history.push(
                `/member-view/loans/${this.props.match.params.id}`
              );
            }
          });
        }
      })
      .catch(d => {
        //console.log("Error saving the data");
        //console.log(d);
        this.setState({ modalVisible: false });
      });
  };
}

export default LoanAdd;
