import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filterall";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class suspensePayment extends Component {
    state = {
        tableData: { data: [] },
        response: { data: [] },
        tableError: false,
        query: {},
        filter: {},
        table_loading: false
    };

    timeout = null;
    render() {
        return (
            <div className="">
                <Nav
                    name="Merged Reconciliation"
                ></Nav>
                <div className="mt-3 border-0 mx-3 bg-white">
                    <div className="p-4">

            <Filter
             filter={[
                {
                    label: 'Status',
                    name: 'status',                                                                                                            
                    options: [
                         {
                            label: 'Select Status',
                            value: '',

                        },

                        {
                            label: 'Pending',
                            value: '0',

                        },
                        {
                            label: 'Successful',
                            value: '1',
                        }, 
                                         
                       
                        
                    ],

                },

                  
               
              
  

            ]}  

                onChange={(filter) => {
                  console.log(filter)
                  this.setState({ filter });
                  setTimeout(() => {
                    this.fetchStatement();
                  }, 0);
                }}
            />
                        <Table
                            search={["ReceiptNo", "Details"]}
                            sort="id"
                            sortDirection={-1}
                            data={this.state.tableData}
                            fetch={params => {
                                this.setState({ query: params });
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                        />
                    </div>
                </div>
            </div>
        );
    }

    fetchStatement = () => {
        
        this.setState({ table_loading: true });

        let { query, filter } = this.state;
        let q = { ...query, ...filter,uploadRequestId:this.props.match.params.id, };
        // let q = {
        //     uploadRequestId:this.props.match.params.id,
        //     ...this.state.query,
        // };

        let urlParams = Object.entries(q)
            .map(e => e.join("="))
            .join("&");

        fetch(`${window.server}/upload_service/upload-request-records?${urlParams}`, {
            headers: {
                Authorization: localStorage.token
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log("users", response);
                let data = [];
                response.data.map((d, i) => {
                    data.push({
                        '#': d.col1,
                        ReceiptNo: d.col2,
                        CompletionTime:d.col3,
                        InitiationTimeDetails:d.col4,
                        Details:d.col5,
                        TransactionStatus:d.col6,
                        PaidIn:d.col7,
                        Withdrawn:d.col8,                       
                        status:d.status==1?'Success':d.status==0?'Pending':'Failed',
                        response:d.response,
                        
                    });
                });

                let dts = {};
                dts.data = data;

                this.setState({
                    tableData: { ...response, ...dts },
                    response,
                    table_loading: false
                });
            })
            .catch(d => {
                this.setState({ table_loading: false });
                console.error(d);
            });
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
            JSON.stringify({ ...prevState.query, ...prevState.filter })
        ) {
            let $t = this;

            clearTimeout(this.timeout);
            this.timeout = setTimeout(function () {
                $t.fetchStatement();
            }, 100);
        }
    }
}

export default suspensePayment;
