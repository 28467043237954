import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { CheckCircle } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class Reconciliation extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
    Base64:null,
    setFileName:''
  };

  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="Reconcialiation"
       
        ></Nav>
         <div className="p-6 container-fluid">
          <div className="row mt-5 px-3">
            <div className="col-md-6">
              <div class="form-group">
                <label for="fileInput1" className="p-2">Upload an M-PESA statement file</label>
                <input
                  type="file"
                  class="form-control"
                  id="fileInput1"
                  aria-describedby="emailHelp"
                  placeholder="Enter file"
                  onChange={e => this.setState({ file: e.target.files[0] })}
                />
                <button
                  className="btn btn-primary mt-5 px-5"
                  disabled={!this.state.file}
                  onClick={this.reconcile}>
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 border-0 card mx-3 bg-white">
          <div className="p-4">
            <Table
              search={["status", "file_name"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={params => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />



         <Modal
          visible={this.state.modalVisible}
          dismiss={false}
          close={() => this.setState({ modalVisible: false })}>
          <div>
            <div className="d-flex flex-row align-items-center">
              {this.state.uploadSuccessful ? (
                <CheckCircle color="#4caf50" size={64} />
              ) : (
                <div className="lds-spinner">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              )}
              <div className="ml-5 text-dark">
                {!this.state.uploadSuccessful ? (
                  <h3>Uploading ...</h3>
                ) : (
                  <h3>Upload Successful. System will take care of the rest</h3>
                )}
              </div>
            </div>
            {this.state.uploadSuccessful && (
              <div className="text-center">
                <button
                  className="btn btn-primary mt-4 px-5"
                  onClick={() => this.setState({ modalVisible: false })}>
                  Done
                </button>
              </div>
            )}
          </div>
        </Modal>
          </div>
        </div>
      </div>
    );
  }

  reconcile = async (e) => {
    if (this.state.file) {           
      const base64 = await this.createBase64Image(this.state.file);
      console.log('base64', base64);
      this.setState({Base64:base64});
      this.setState({ modalVisible: true });



      let formData = new FormData();
      formData.append("file", this.state.file);
      this.setState({ modalVisible: true });
      fetch(`${window.server}/upload_service/upload_raw`, {
        method: "POST",
        headers: {
          Authorization: localStorage.token
        },
        body: formData
      })
  
      // fetch(`http://192.168.5.222:3030/upload_service/upload_raw`, {
      //   method: "POST",
      //   headers: {
      //     Authorization: localStorage.token
      //   },
      //   body: this.state.Base64
      // })
        .then(response => response.json())
        .then(response => {
          if (response.code) {
            alert(response );
            this.setState({ modalVisible: false });
          } else {
            console.log(response);
            //this.setState({ user_id: response.user_id });
            setTimeout(() => {
              this.fetchStatement();
            }, 100);
  
            this.setState({ uploadSuccessful: true });
          }
        })
        .catch(d => {
          console.log("Error saving the data");
          //console.log(d);
          this.setState({ modalVisible: false });
        });
    }
  };

  

  createBase64Image = (file) => {
    const reader = new FileReader();
    return new Promise(function (resolve, reject) {
      reader.onload = function (event) {
        resolve(event.target.result)
      }
      reader.readAsDataURL(file);
    })
  }

  fetchStatement = () => {
    this.setState({ table_loading: true });
   
    let q = {    
      ...this.state.query,
    
    };

    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");

    fetch(`${window.server}/upload_service/upload-requests?${urlParams}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        console.log("reconcialtion", response);

        let data = [];
        response.data.map((d, i) => {
          data.push({
            
            file_name: d.file_name,
            file_link: <Link  to={`${d.file_link}`}>File Link</Link>,
            progress_success: d.progress_success,
            progress_failed: d.progress_failed,
            status:d.status,
            response:d.response,
            uploadedBy:d.uploadedBy,
            date_created: moment(d.createdAt).format('YYYY-MM-DD HH:mm:ss'),
             
          action: (
     
            <Link
              className='btn btn-sm btn-round btn-outline-primary px-3'
              to={`/missing-payments/${d.id}`}>
              View More
            </Link>
    
           ),
           
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false
        });
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchStatement();
      }, 100);
    }
  }
}

export default Reconciliation;
