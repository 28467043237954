import React, { Component } from "react";
import { Route, Link, Switch } from "react-router-dom";
// import Details from "./clients/details";
import {
  Plus,
  Edit,
  Check,
  AlertTriangle,
  ThumbsUp,
  RefreshCcw,
  Edit2,
  UserX,
  LogOut,
  LogIn,
  UserPlus,
  CheckCircle,
  Briefcase,
  DollarSign,
  FilePlus,
  RefreshCw,
  XCircle,
  Bell,
  BellOff
} from "react-feather";
import Tabs from "../../components/tabs";
import Details from "./details";
import MemberPayments from "./payments";
import Modal from "../../components/modal";
import Form from "../../components/form";
import MemberLoans from "./loans";
import MemberDeposits from "./deposits";
import MemberSMS from "./sms";
import MemberSavings from "./savings";
import MemberShares from "./shares";
import MemberWallet from "./wallet";
import MemberWithdrawals from "./withdrawals";
import Guarantors from "./guarantors";
import Guarantees from "./guarantees";
import MemberTransactions from "./transactions";

class MembersView extends Component {
  state = {
    currentRoute: "",
    editModal: false,
    leaveModal: false,
    response: {data: []},
    details: {
      passport: null,
      firstname: "gabriel",
      middlename: "kamau",
      surname: "muhangia",
      msisdn: "0717638548",
      date_of_birth: "2020-01-08",
      gender: "1",
      marital_status: "1",
      identity_type: "1",
      id_number: "2324234",
      kra_pin: "KSDNF32324234",
      // sub_county_id: "1",
      // ward_id: "2",
      village: "KIJABE",
      address: "17 kingstion road",
      nationality: "1",
      pledge: "3000"
    }
  };
  render() {
    let user = [];

    return (
      <div className="bg-light">
        <div className="card table-card border-0 shadow m-3 user-card">
          <div className="text-mute pt-3 pl-3">
            <small className="text-mute">Members > View</small>
          </div>

          <div className="profile p-3 d-md-flex flex-row align-items-center justify-content-between">
            <div className="d-md-flex flex-row align-items-center">
              <div className="border avatar-lg bg-light d-flex flex-row align-items-center justify-content-center">
                <span className="initials">
                  {this.state.firstname && this.state.firstname[0]}
                </span>
                <img
                  src={`${window.server}${this.state.passport_path &&
                    this.state.passport_path.replace("public", "")}`}
                  className="avatar"
                  alt=""
                />
              </div>
              <div className="ml-md-4 my-3 my-md-0">
                <h4 className="text-capitalize">
                  {this.state.firstname} {this.state.middlename}{" "}
                  {this.state.surname}
                </h4>
                <div>+ {this.state.msisdn && this.state.msisdn.msisdn}</div>
                <div className="ml-2 mt-1">
                  <span className="badge badge-secondary px-2">Member</span>
                </div>
              </div>
            </div>

            <div className="d-flex flex-row flex-wrap member-view ">
              <div className="d-md-flex flex-row text-center flex-wrap admin-actions justify-content-end">
                <button className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3">
                  <LogOut size={18} />
                  <span className="pl-1 font-weight-bold">Exit Member</span>
                </button>

                <button className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3">
                  <CheckCircle size={18} />
                  <span className="pl-1 font-weight-bold">Unblock Account</span>
                </button>

                <button className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3">
                  <XCircle size={18} />
                  <span className="pl-1 font-weight-bold">Suspend Account</span>
                </button>

                <button className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3">
                  <Bell size={18} />
                  <span className="pl-1 font-weight-bold">
                    show notifications
                  </span>
                </button>

                <button className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3">
                  <BellOff size={18} />
                  <span className="pl-1 font-weight-bold">
                    remove notifications
                  </span>
                </button>

                <button className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3">
                  <RefreshCw size={18} />
                  <span className="pl-1 font-weight-bold">Reset pin</span>
                </button>

                <button className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3">
                  <RefreshCw size={18} />
                  <span className="pl-1 font-weight-bold">Reset pin</span>
                </button>

                <button
                  onClick={() => {
                    this.setState({ editModal: true });
                    //console.log(this.state.response.data[0]);
                  }}
                  className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3"
                >
                  <Edit size={18} />
                  <span className="pl-1 font-weight-bold">Edit profile</span>
                </button>

                <Link
                  to={`/loan-add/1/${this.props.match.params.id}`}
                  className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3"
                >
                  <FilePlus size={18} />
                  <span className="pl-1 font-weight-bold">Add a Loan</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-between">
            {this.state.msisdn && (
              <Tabs
                tabs={[
                  {
                    label: "DETAILS",
                    link: "/member-view/details/" + this.props.match.params.id
                  },
                  {
                    label: "LOANS",
                    link: "/member-view/loans/" + this.props.match.params.id
                  },
                  {
                    label: "DEPOSITS",
                    link: "/member-view/deposits/" + this.props.match.params.id
                  },
                  {
                    label: "WALLET",
                    link: "/member-view/wallet/" + this.props.match.params.id
                  },
                  {
                    label: "MESSAGES",
                    link:
                      "/member-view/sms/" +
                      this.props.match.params.id +
                      "/" +
                      this.state.msisdn.msisdn
                  },
                  {
                    label: "WITHDRAWS",
                    link: "/member-view/withdraws/" + this.props.match.params.id
                  },
                  {
                    label: "SAVINGS",
                    link: "/member-view/savings/" + this.props.match.params.id
                  },
                  {
                    label: "SHARES",
                    link: "/member-view/shares/" + this.props.match.params.id
                  },
                  {
                    label: "GUARANTORS",
                    link:
                      "/member-view/guarantors/" + this.props.match.params.id
                  },
                  {
                    label: "GUARANTEES",
                    link:
                      "/member-view/guarantees/" + this.props.match.params.id
                  },
                  {
                    label: "TRANSACTIONS",
                    link:
                    "/member-view/transactions/" + this.props.match.params.id
                  }
                 
                ]}
              ></Tabs>
            )}
          </div>
        </div>
        <Route path="/member-view/details/:id" exact component={Details} />
        <Route path="/member-view/loans/:id" exact component={MemberLoans} />
        <Route
          path="/member-view/deposits/:id"
          exact
          component={MemberDeposits}
        />
        <Route
          path="/member-view/sms/:id/:msisdn"
          exact
          component={MemberSMS}
        />
        <Route
          path="/member-view/savings/:id"
          exact
          component={MemberSavings}
        />
        <Route path="/member-view/shares/:id" exact component={MemberShares} />
        <Route path="/member-view/wallet/:id" exact component={MemberWallet} />
        <Route
          path="/member-view/withdraws/:id"
          exact
          component={MemberWithdrawals}
        />
        <Route
          path="/member-view/guarantors/:id"
          exact
          component={Guarantors}
        />
        <Route
          path="/member-view/guarantees/:id"
          exact
          component={Guarantees}
        />

        <Modal
          visible={this.state.editModal}
          close={() => this.setState({ editModal: false })}
        >
          <h5 className="font-weight-bold">Update Member details</h5>

          <Form
            submitText={"Update"}
            back={false}
            inputs={[
              // {
              //   label: "Passport photo",
              //   name: "passport",
              //   value: this.state.details.passport,
              //   type: "passport"
              // },
              {
                label: "First name",
                name: "firstname",
                value: this.state.details.firstname
              },
              {
                label: "Middle name",
                name: "middlename",
                value: this.state.details.middlename
              },
              {
                label: "Surname",
                name: "surname",
                value: this.state.details.surname
              },
              {
                label: "Phone number",
                name: "msisdn",
                value: this.state.details.msisdn,
                type: "tel",
                readonly: "readonly"
              },
              {
                label: "Date Of Birth",
                name: "date_of_birth",
                value: this.state.details.date_of_birth,
                type: "date"
              },
              {
                label: "Gender",
                name: "gender",
                value: this.state.details.gender,
                type: "select",
                options: [
                  { name: "Male", value: 1 },
                  { name: "Female", value: "2" }
                ]
              },
              {
                label: "Marital Status",
                name: "marital_status",
                value: this.state.details.marital_status,
                type: "select",
                options: [
                  { name: "Single", value: 1 },
                  { name: "Married", value: "2" }
                ]
              },

              {
                label: "Address",
                name: "address",
                value: this.state.details.address
              },
              {
                label: "nationality",
                name: "nationality",
                value: this.state.details.nationality,
                type: "select",
                options: [
                  { name: "Kenyan", value: 1 },
                  { name: "Nigerian", value: 2 }
                ]
              },
              {
                label: "Pledge (Kshs)",
                name: "pledge",
                value: this.state.details.pledge,
                type: "number"
              },
              { type: "break" },
              {
                label: "Identification Type",
                name: "identity_type",
                value: this.state.details.identity_type,
                type: "select",
                options: [
                  { name: "Identity Card", value: 1 },
                  { name: "Passport Number", value: 2 }
                ]
              },
              {
                label: "ID number",
                name: "id_number",
                type: "number",
                value: this.state.details.id_number
              },
              {
                label: "KRA pin",
                name: "kra_pin",
                value: this.state.details.kra_pin
              }
            ]}
            submit={data => {
              this.setState({ details: data });
              //console.log(data);
            }}
          />
        </Modal>
      </div>
    );
  }

  componentDidMount = () => {
    this.fetch();
  };

  fetch = () => {
    let user_id = this.props.match.params.id;
    fetch(`${window.server}/customer?id=${user_id}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        //console.log(response.data[0]);
        this.setState({ ...response.data[0] });
      })
      .catch(d => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };
}

export default MembersView;
