import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class LoanRequests extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false
  };
  timeout = null;
  render() {
    return (
      <div className="">
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              search={["firstname", "middlename", "surname", "msisdn"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={params => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchLoanRequests = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query,
      customer_id: this.props.match.params.id
    };

    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");

    // //console.log(urlParams);
    fetch(`${window.server}/loan-request?${urlParams}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        //console.log(response);

        let data = [];
        response.data.map((d, i) => {
          data.push({
            load_id: d.id,
            //customer_name: `${d.customer.firstname} ${d.customer.middlename} ${d.customer.surname}`,
            loan_type: d.product.product_name,
            // loan_type: d.product_name,
            amount: "KES " +
              parseFloat(
                parseFloat(d.amount)
              ).toLocaleString(),
            repayment_duration: d.repayment_duration + " months",
            description: d.description,
            // status: d.status,
            status: parseFloat(d.status) === 1 ? "Active" : "Pending",
            guarantor_status: parseFloat(d.guarantor_status) === 0 ? "Active" : "In active",
            guaranteed_amount: "KES " +
              parseFloat(
                parseFloat(d.guaranteed_amount)
              ).toLocaleString(),
            // loan_products: d["loan-products"],
            loan_date: moment(d.date_processed).format('Do MMMM YYYY, h:mm:ss a'),

            action: (
              <div className="d-flex flex-row">
                <Link
                  to={"/loan-view/details/" + d.id}
                  className="btn btn-sm btn-primary px-3 btn-round"
                >
                  View
                </Link>
              </div>
            )
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false
        });
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function() {
        $t.fetchLoanRequests();
      }, 100);
    }
  }
}

export default LoanRequests;
