import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class Disbursements extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false
  };
  timeout = null;
  render() {
    return (
      <div className="">
        <Nav name="Disbursements"></Nav>
        <div className="mt-3 table-card  border-0 card shado mx-3 shadow">
          <div className="p-4">
            <Table
              search={["firstname", "middlename", "surname", "msisdn"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={params => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchDisbursements = () => {
    this.setState({ table_loading: true });

    let q = {
      // ...this.state.filter,
      ...this.state.query
    };

    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");

    // //console.log(urlParams);
    fetch(`${window.server}/disbursements?${urlParams}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        //console.log(response);

        let data = [];
        response.data.map((d, i) => {
          data.push({
            id: d.id,
            member:
              !d.customer ||
              `${d.customer.firstname} ${d.customer.middlename} ${d.customer.surname}`,
            // id: d.id,
            // customer_id: d.customer_id,
            amount: "KES " + parseFloat(d.amount).toLocaleString(),
            processed: d.processed,
            mpesaId: d.mpesaId,
            resultstatus: d.resultstatus,
            date_created: moment(d.date_created).format('Do MMMM YYYY, h:mm:ss a')
            // date_modified: d.date_modified
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false
        });
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function() {
        $t.fetchDisbursements();
      }, 100);
    }
  }
}

export default Disbursements;
