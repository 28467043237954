import React, { Component } from 'react';
import { ChevronDown, User, X, Settings, Bell } from 'react-feather';
import { Link } from 'react-router-dom';

class Nav extends Component {
  state = {};
  render() {
    return (
      <div className='py-2 px-3 d-flex flex-row justify-content-between align-items-center d-flex flex-row nav' style={{ borderBottom: "1px dashed #FF8F00" }}>
        <h5 className='font-weight-normal m-0 text-uppercase'>{this.props.name}</h5>
        <div className='position-relative d-flex flex-row align-items-center '>
          {this.props.buttons &&
            this.props.buttons.map((d, i) => (
              <Link
                to={d.link}
                key={i}
                onClick={d.onClick}
                className='mx-2 btn btn-primary rounded px-4 font-weight-bold'>
                {d.text}
              </Link>
            ))}
          <div style={{ display: 'flex' }}>
            <div
              className={
                'action-icon align-items-center d-md-flex flex-row d-none'
              }>
              <div className='d-flex flex-row align-items-center justify-content-center'>
                <Bell></Bell>
              </div>
            </div>
            <div
              className={
                'action-icon align-items-center d-md-flex flex-row d-none ' +
                (this.state.showProfile ? 'active' : '')
              }
              onClick={() =>
                this.setState({ showProfile: !this.state.showProfile })
              }>
              <div className='user-avatar d-flex flex-row align-items-center justify-content-center'>
                <User></User>
              </div>
              <ChevronDown size={17} className='ml-2' />
            </div>
            {window.user && (
              <div
                className={
                  'profile-card card shadow ' +
                  (this.state.showProfile ? 'active' : '')
                }>
                <div className='p-3 position-relative'>
                  {/* <div
                    className='close-action'
                    onClick={() =>
                      this.setState({ showProfile: !this.state.showProfile })
                    }>
                    <X className='m-2' size={17}></X>
                  </div>
                  <div className='font-weight-bold'>
                    {window.user.user.full_names}
                  </div>
                  <div>
                    <small className='text-capitalize'>
                      |{' '}
                      {window.user.user.roles.name
                        .toLowerCase()
                        .replace('_', ' ')}
                    </small>
                  </div> */}
                </div>
                <button
                  className='btn btn-primary text-center'
                  onClick={() => window.logout()}>
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Nav;
