import React, { Component } from "react";
import axios from 'axios';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { Route } from "react-router-dom";
import {
  Edit,
  Edit2,
  DollarSign,
  FilePlus
} from "react-feather";
import Tabs from "../../components/tabs";
import Modal from "../../components/modal";
import Form from "../../components/form";
import AgentLoans from "./loans";
import AgentAccount from "./account";
import AgentLimit from "./limit";
import AgentStep from "./step";
import DefaultLimit from './defaultLimit';
import Changes from './changes';

class AccountView extends Component {

  constructor(props) {

    super(props);

    //Edit profile
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeOtherNames = this.onChangeOtherNames.bind(this);
    this.onChangeIdNumber = this.onChangeIdNumber.bind(this);
    this.onChangeMsisdn = this.onChangeMsisdn.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    //Edit account
    this.onChangeAgentNumber = this.onChangeAgentNumber.bind(this);
    this.onChangeStoreNumber = this.onChangeStoreNumber.bind(this);
    this.onChangeLimit = this.onChangeLimit.bind(this);
    this.onChangeBusinessName = this.onChangeBusinessName.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.onChangeAccountStatus = this.onChangeAccountStatus.bind(this);
    this.onChangeAgentType = this.onChangeAgentType.bind(this);
    this.onChangeOwnerName = this.onChangeOwnerName.bind(this);
    this.onChangeOwnerMsisdn = this.onChangeOwnerMsisdn.bind(this);
    this.onChangeOperator = this.onChangeOperator.bind(this);
    this.onChangeVerified = this.onChangeVerified.bind(this);
    this.onSubmitAccount = this.onSubmitAccount.bind(this);

    //add statements
    //this.onChangeAgentAccountId = this.onChangeAgentAccountId.bind(this);
    this.onChangeMonth = this.onChangeMonth.bind(this);
    this.onChangeClientValue = this.onChangeClientValue.bind(this);
    this.onChangeSafaricomValue = this.onChangeSafaricomValue.bind(this);
    this.onSubmitLimit = this.onSubmitLimit.bind(this);

    //Reset Password
    this.onChangeAgentPassword = this.onChangeAgentPassword.bind(this);
    this.onSubmitPassword = this.onSubmitPassword.bind(this);

    //this.onFileChange = this.onFileChange.bind(this);
    //this.importCSV = this.importCSV.bind(this);

    this.state = {
      first_name: '',
      other_names: '',
      id_number: '',
      msisdn: '',
      idafter:'',
      status: '',
      agent_type:'',
      agent_no: '',
      store_number: '',
      limit: '',
      business_name: '',
      location: '',
      owner_name: '',
      owner_msisdn: '',
      operator_msisdn: '',
      verified_owner: '',
      password: '',
      agent_account_id: '',
      month: '',
      client_value: null,
      safaricom_value: '',
      month1: '',
      client_value1: '',
      safaricom_value1: '',
      month2: '',
      client_value2: '',
      safaricom_value2: '',
      month3: '',
      client_value3: '',
      safaricom_value3: '',
      month4: '',
      client_value4: '',
      safaricom_value4: '',
      month5: '',
      client_value5: '',
      safaricom_value5: '',
      month6: '',
      client_value5: '',
      safaricom_value5: '',
      selectedFile: null,
    }
  }

  ///data = [];

  onFileChange = event => {
    console.log("file", event.target.files[0]);
    this.setState({ client_value: event.target.files[0] })
    //data.push(event.target.files[0]);
  };

  onChangeFirstName(e) {
    this.setState({ first_name: e.target.value })
  }
  onChangeOtherNames(e) {
    this.setState({ other_names: e.target.value })
  }
  onChangeIdNumber(e) {
    this.setState({ id_number: e.target.value })
  }
  onChangeMsisdn(e) {
    this.setState({ msisdn: e.target.value })
  }
  onChangeStatus(e) {
    this.setState({ status: e.target.value })
  }
  onChangeAgentNumber(e) {
    this.setState({ agent_no: e.target.value })
  }
  onChangeStoreNumber(e) {
    this.setState({ store_number: e.target.value })
  }
  onChangeLimit(e) {
    this.setState({ limit: e.target.value })
  }
  onChangeBusinessName(e) {
    this.setState({ business_name: e.target.value })
  }
  onChangeLocation(e) {
    this.setState({ location: e.target.value })
  }
  onChangeOwnerName(e) {
    this.setState({ owner_name: e.target.value })
  }
  onChangeOwnerMsisdn(e) {
    this.setState({ owner_msisdn: e.target.value })
  }
  onChangeOperator(e) {
    this.setState({ operator_msisdn: e.target.value })
  }
  onChangeVerified(e) {
    this.setState({ verified_owner: e.target.value })
  }
  onChangeAccountStatus(e) {
    this.setState({ status: e.target.value })
  }
  onChangeAgentType(e) {
    this.setState({ agent_type: e.target.value })
  }
  onChangeAgentPassword(e) {
    this.setState({ password: e.target.value })
  }

  onChangeAgentAccountId(e) {
    this.setState({ agent_account_id: e.target.value })
  }
  onChangeMonth(e) {
    this.setState({ month: e.target.value })
  }
  onChangeClientValue(e) {
    this.setState({ client_value: e.target.value })
  }
  onChangeSafaricomValue(e) {
    this.setState({ safaricom_value: e.target.value })
  }

  onSubmit(e) {
    e.preventDefault()

    const userObject = {
      first_name: this.state.first_name,
      other_names: this.state.other_names,
      id_number: this.state.id_number,
      msisdn: this.state.msisdn,
      status: this.state.status,
    };

    let agent = this.props.match.params.agent_id;

    axios.patch(`${window.server}/agents/${agent}`, userObject, { headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*", Authorization: localStorage.token } })
      .then(() => {
        //console.log(res.data)
        ToastsStore.success("Agent Profile Has Been Updated Successfully");
        window.location.reload();
      }).catch(() => {
        //console.log(error)
        ToastsStore.error("Error. Kindly Try Again.");
      });

    //this.setState({ first_name: ''})
  }
  onSubmitAccount(e) {
    e.preventDefault()

    const userObjectAccount = {
      agent_no: this.state.agent_no,
      store_number: this.state.store_number,
      limit: this.state.limit,
      business_name: this.state.business_name,
      location: this.state.location,
      owner_name: this.state.owner_name,
      agent_type: this.state.agent_type,
      owner_msisdn: this.state.owner_msisdn,
      operator_msisdn: this.state.operator_msisdn,
      verified_owner: this.state.verified_owner,
      status: this.state.status,
    };

    let agentaccount = this.state.agent_account_id;

    axios.patch(`${window.server}/agent-accounts/${agentaccount}`, userObjectAccount, { headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*", Authorization: localStorage.token } })
      .then(() => {
        //console.log(res.data)
        ToastsStore.success("Agent Account Has Been Updated Successfully.");
        window.location.reload();
      }).catch(() => {
        //console.log(error)
        ToastsStore.error("Error. Kindly Try Again.");
      });

    //this.setState({ first_name: ''})
  }
  onSubmitLimit(e) {
    e.preventDefault()
    let agentaccount = this.state.agent_account_id;

    const userObjectAccount = {
      agent_account_id: agentaccount,
      month: this.state.month,
      client_value: this.state.client_value,
      safaricom_value: this.state.safaricom_value
    };

    //let agentaccount = this.state.agent_account_id;

    axios.post(`${window.server}/statement`, userObjectAccount, { headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*", Authorization: localStorage.token } })
      .then(() => {
        //console.log(res.data)
        ToastsStore.success("Agent Account Statement Added Successfully.");
        //window.location.reload();
        this.setState({ editModalStatements: false });
        this.setState({ table_loading: true });

      }).catch(() => {
        //console.log(error)
        ToastsStore.error("Error. Kindly Try Again.");
      });

    //this.setState({ first_name: ''})
  }

  onSubmitPassword(e) {
    e.preventDefault()

    const userObject = {
      password: "",
    };

    let agent = this.props.match.params.agent_id;

    axios.patch(`${window.server}/agents/${agent}`, userObject, { headers: { 'Content-Type': 'application/json', Authorization: localStorage.token } })
      .then(() => {
        //console.log(res.data)
        ToastsStore.success("Password Has Been Reset Successfully");
        window.location.reload();
      }).catch(() => {
        //console.log(error)
        ToastsStore.error("Error. Kindly Try Again.");
      });

  }
  state = {
    currentRoute: "",
    editModal: false,
    editModalAccount: false,
    leaveModal: false,
    response: { data: [] }
  };
  render() {
    const role = localStorage.getItem("role_id");

    return (
      <div className="bg-light">
        <div className="card border-0 m-3 user-card bg-white">
          <div className="text-mute pt-3 pl-3">
            <small className="text-mute">Agents Accounts {'>'} View</small>
          </div>

          <div className="profile p-3 d-md-flex flex-row align-items-center justify-content-between">
            <div className="d-md-flex flex-row align-items-center">
              <div className="border avatar-lg bg-light d-flex flex-row align-items-center justify-content-center">
                <span className="initials text-capitalize">
                  {this.state.first_name && this.state.first_name[0]}
                </span>
              </div>
              <div className="ml-md-4 my-3 my-md-0">
                <h4 className="text-capitalize">
                  {this.state.first_name} {this.state.other_names} {" "}
                </h4>
                <div>+{this.state.msisdn && this.state.msisdn}</div>
                <div className="ml-2 mt-1">
                  <span className="badge badge-secondary px-2">Agent</span>
                </div>
              </div>
            </div>

            <div className="d-flex flex-row flex-wrap member-view ">
              <div className="d-md-flex flex-row text-center flex-wrap admin-actions justify-content-end">

             
                <button
                  onClick={() => {
                    this.setState({ editModalAccount: true });
                  }}
                  className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-success mr-3">
                  <Edit size={18} />
                  <span className="pl-1 font-weight-bold">Edit account</span>
                </button>

                <button
                  onClick={() => {
                    this.setState({ addModalStatements: true });
                  }}
                  className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary mr-3"
                >
                  <DollarSign size={18} />
                  <span className="pl-1 font-weight-bold">Add Statements</span>
                </button>
                <button
                 
                  onClick={ () => {
                    this.props.history.push(`/agent-view/details/${this.state.idafter}/${this.state.agent_id}`);
                  }}
                  className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-info mr-3"
                >
                  <FilePlus size={18} />
                  <span className="pl-1 font-weight-bold">View Agent Information</span>
                </button>


              </div>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-between">
            {this.state.msisdn && (
              <Tabs
                tabs={[
                  {
                    label: "ACCOUNT",
                    link: "/account-view/account/" + this.props.match.params.id + "/" + this.props.match.params.agent_id
                  },
                  {
                    label: "LOANS",
                    link: "/account-view/loans/" + this.props.match.params.id + "/" + this.props.match.params.agent_id
                  },
                  {
                    label: "STATEMENTS",
                    link: "/account-view/limit/" + this.props.match.params.id + "/" + this.props.match.params.agent_id + "/" + this.state.agent_account_id
                  },
                  {
                    label: "STEPS",
                    link: "/account-view/step/" + this.props.match.params.id + "/" + this.props.match.params.agent_id
                  },
                  // {
                  //   label: "DEFAULT HISTORY",
                  //   link: "/account-view/default/" + this.props.match.params.id + "/" + this.props.match.params.agent_id
                  // },
                  {
                    label: "LIMIT HISTORY",
                    link: "/account-view/limit-history/" + this.props.match.params.id + "/" + this.props.match.params.agent_id
                  },
                  {
                    label: "CHANGES",
                    link: "/account-view/changes/" + this.props.match.params.id + "/" + this.props.match.params.agent_id + "/" + this.state.agent_account_id
                  },

                ]}
              ></Tabs>
            )}
          </div>
        </div>
        {/* <Route path="/agent-view/details/:id/:agent_id" exact component={Details} /> */}
        <Route path="/account-view/loans/:id/:agent_id" exact component={AgentLoans} />
        <Route path="/account-view/account/:id/:agent_id" exact component={AgentAccount} />
        <Route path="/account-view/limit/:id/:agent_id/:agent_account_id" exact component={AgentLimit} />
        <Route path="/account-view/step/:id/:agent_id" exact component={AgentStep} />

        <Route path="/account-view/default/:id/:agent_id" exact component={DefaultLimit} />
        <Route path="/account-view/limit-history/:id/:agent_id" exact component={DefaultLimit} />
        <Route path="/account-view/changes/:id/:agent_id/:agent_account_id" exact component={Changes} />

        <Modal
          visible={this.state.editModal}
          close={() => this.setState({ editModal: false })}
        >
          <h5 className="font-weight-bold" style={{ margin: "15px" }}>Update Agent Details</h5>

          <form onSubmit={this.onSubmit}>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">First Name</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.first_name} onChange={this.onChangeFirstName} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Other Names</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.other_names} onChange={this.onChangeOtherNames} />
            </div>
            {/* <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">ID Number</span>
              <input className= "form-control bg-light text-input"type="text" value={this.state.id_number} onChange={this.onChangeIdNumber}/>        
            </div> */}
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Phone Number</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.msisdn} onChange={this.onChangeMsisdn} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Status</span>
              <select className="form-control bg-light" value={this.state.status} onChange={this.onChangeStatus}>
                <option value="1">Active</option>
                <option value="2">In Active</option>
              </select>
            </div>
            <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
              <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold">Update</button>
            </div>
          </form>
        </Modal>
        <Modal
          visible={this.state.editModalAccount}
          close={() => this.setState({ editModalAccount: false })}
        >
          <h5 className="font-weight-bold" style={{ margin: "15px" }}>Update Agent Account</h5>

          <form onSubmit={this.onSubmitAccount}>

          <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Agent Type</span>
              <select className="form-control bg-light text-input" value={this.state.agent_type} onChange={this.onChangeAgentType}>                
                <option value="1">Agent</option>
                <option value="2">Sub Agent</option>
              </select>
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Agent Number</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.agent_no} onChange={this.onChangeAgentNumber} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Store Number</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.store_number} onChange={this.onChangeStoreNumber} disabled={role == 1 ? true : false} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Limit (Kshs.)</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.limit} onChange={this.onChangeLimit} disabled={role == 1 ? true : false} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Business Name</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.business_name} onChange={this.onChangeBusinessName} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Location</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.location} onChange={this.onChangeLocation} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Owner Name</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.owner_name} onChange={this.onChangeOwnerName} />
            </div>

            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Owner Phone Number</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.owner_msisdn} onChange={this.onChangeOwnerMsisdn} />
            </div>
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Operator Phone Number</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.operator_msisdn} onChange={this.onChangeOperator} />
            </div>
            
            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Verified Owner</span>
              <input className="form-control bg-light text-input" type="text" value={this.state.verified_owner} onChange={this.onChangeVerified} />
            </div>       


            <div className="mx-3 d-inline-block mb-3">
              <span className="ml-1 mb-2 d-block text-capitalize">Account Status</span>
              <select className="form-control bg-light text-input" value={this.state.status} onChange={this.onChangeAccountStatus}>
                <option value="1">Active</option>
                <option value="2">In Active</option>
                <option value="3">Suspended</option>
              </select>
            </div>
          
            <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
              <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold">Update</button>
            </div>
          </form>
        </Modal>
        {/* <Modal
          width={500}
          visible={this.state.addModalStatements}
          close={() => this.setState({ addModalStatements: false })}>
          <div className="d-flex flex-row align-items-center">
            <UploadCloud className="mr-3" />
            <h5 className="m-0 mt-1">Upload Agent Statements</h5>
          </div>
          <div className="mt-3">

            <input
              type="file"
              style={{ margin: '15px', border: '1px dotted #CC8433', minHeight: '25px', display: 'block' }}
              accept="csv/*"
              placeholder="..."
              className="form-control bg-light text-input"
              value={this.state.selectedFile}
              required={true}
              onChange={this.onFileChange}
            />

            <button type="submit" className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round" onClick={
              e => this.importCSV(e)
            }>
              Upload CSV
            </button>
          </div>
        </Modal> */}
        <Modal
          width={1200}
          visible={this.state.addModalStatements}
          close={() => this.setState({ addModalStatements: false })}>
          <div className="d-flex flex-row align-items-center" style={{ backgroundColor: 'white', }}>
            <Edit2 className="mr-3 mt-1"></Edit2>
            <h5 className="m-0 mt-1">Add Agent Statements</h5>
          </div>
          <div className="mt-0">
            <Form
              submitText={"Push Data"}
              back={false}
              inputs={[
                {
                  label: "Month",
                  name: "month",
                  type: "date",
                  value: this.state.month
                },
                {
                  label: "Client Value",
                  name: "client_value",
                  type: "text",
                  value: this.state.client_value
                },
                {
                  label: "Safaricom Value",
                  name: "safaricom_value",
                  type: "text",
                  value: this.state.safaricom_value
                },
                {
                  label: "Month",
                  name: "month1",
                  type: "date",
                  value: this.state.month1
                },
                {
                  label: "Client Value",
                  name: "client_value1",
                  type: "text",
                  value: this.state.client_value1
                },
                {
                  label: "Safaricom Value",
                  name: "safaricom_value1",
                  type: "text",
                  value: this.state.safaricom_value1
                },
                {
                  label: "Month",
                  name: "month2",
                  type: "date",
                  value: this.state.month2
                },
                {
                  label: "Client Value",
                  name: "client_value2",
                  type: "text",
                  value: this.state.client_value2
                },
                {
                  label: "Safaricom Value",
                  name: "safaricom_value2",
                  type: "text",
                  value: this.state.safaricom_value2
                },
                {
                  label: "Month",
                  name: "month3",
                  type: "date",
                  value: this.state.month3
                },
                {
                  label: "Client Value",
                  name: "client_value3",
                  type: "text",
                  value: this.state.client_value3
                },
                {
                  label: "Safaricom Value",
                  name: "safaricom_value3",
                  type: "text",
                  value: this.state.safaricom_value3
                },
                {
                  label: "Month",
                  name: "month4",
                  type: "date",
                  value: this.state.month4
                },
                {
                  label: "Client Value",
                  name: "client_value4",
                  type: "text",
                  value: this.state.client_value4
                },
                {
                  label: "Safaricom Value",
                  name: "safaricom_value4",
                  type: "text",
                  value: this.state.safaricom_value4
                },
                {
                  label: "Month",
                  name: "month5",
                  type: "date",
                  value: this.state.month5
                },
                {
                  label: "Client Value",
                  name: "client_value5",
                  type: "text",
                  value: this.state.client_value5
                },
                {
                  label: "Safaricom Value",
                  name: "safaricom_value5",
                  type: "text",
                  value: this.state.safaricom_value5
                }

              ]}
              submit={data => {
                this.setState({ details: data });
                setTimeout(() => {
                  this.pushData(data);
                }, 0);
              }}
            />

          </div>
        </Modal>
        <Modal
          visible={this.state.editModalPassword}
          close={() => this.setState({ editModalPassword: false })}
        >
          <h5 className="font-weight-bold" style={{ margin: "15px" }}>Reset Agent Password</h5>

          <form onSubmit={this.onSubmitPassword}>
            <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
              <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Reset</button>
            </div>
          </form>
        </Modal>
        <ToastsContainer store={ToastsStore} />
      </div>
    );
  }

  componentDidMount = () => {
    this.fetchprofile();
    this.fetchaccount();
  };

  fetchprofile = () => {
    let user_id = this.props.match.params.agent_id;
    fetch(`${window.server}/agents?agent_id=${user_id}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        console.log('moraa',response.data[0].id);
        this.setState({ ...response.data[0] });
      })
      .catch(() => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };

  fetchaccount = () => {
    let agent = this.props.match.params.agent_id;
    fetch(`${window.server}/agent-accounts?agent_id=${agent}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        console.log('moraa2',response.data[0].agent.id);
        this.setState({ ...response.data[0], idafter:response.data[0].agent.id });
      })
      .catch(() => {
        this.setState({ tableError: true });
        //console.log(d);
      });
  };

  pushData = data => {
    this.setState({ modalVisible: true });
    let sdata = JSON.stringify(data);
    let obj = JSON.parse(sdata);

    let month = obj.month;
    let month1 = obj.month1;
    let month2 = obj.month2;
    let month3 = obj.month3;
    let month4 = obj.month4;
    let month5 = obj.month5;
    let client_value = obj.client_value;
    let client_value1 = obj.client_value1;
    let client_value2 = obj.client_value2;
    let client_value3 = obj.client_value3;
    let client_value4 = obj.client_value4;
    let client_value5 = obj.client_value5;
    let safaricom_value = obj.safaricom_value;
    let safaricom_value1 = obj.safaricom_value1;
    let safaricom_value2 = obj.safaricom_value2;
    let safaricom_value3 = obj.safaricom_value3;
    let safaricom_value4 = obj.safaricom_value4;
    let safaricom_value5 = obj.safaricom_value5;
    let agent_account_id = this.props.match.params.agent_account_id;

    let arrayGlobal = [];

    arrayGlobal.push({ agent_account_id: agent_account_id, month: month, client_value: client_value, safaricom_value: safaricom_value });
    arrayGlobal.push({ agent_account_id: agent_account_id, month: month1, client_value: client_value1, safaricom_value: safaricom_value1 });
    arrayGlobal.push({ agent_account_id: agent_account_id, month: month2, client_value: client_value2, safaricom_value: safaricom_value2 });
    arrayGlobal.push({ agent_account_id: agent_account_id, month: month3, client_value: client_value3, safaricom_value: safaricom_value3 });
    arrayGlobal.push({ agent_account_id: agent_account_id, month: month4, client_value: client_value4, safaricom_value: safaricom_value4 });
    arrayGlobal.push({ agent_account_id: agent_account_id, month: month5, client_value: client_value5, safaricom_value: safaricom_value5 });

    //alert(JSON.stringify(arrayGlobal));

    console.log(arrayGlobal);

    fetch(`${window.server}/statement`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(arrayGlobal)
    })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        if (response.code) {
          alert(
            response.message +
            " \n " +
            (response.errors[0] ? response.errors[0].message : "")
          );
          this.setState({ modalVisible: false });
        } else {
          console.log(response);
          alert(
            "Agent Account Statement Updated Successfully"
          );
          this.setState({ uploadSuccessful: true });
          this.setState({ addModalStatements: false });
        }
      })
      .catch(d => {
        console.log("Error saving the data");
        console.log(d);
        this.setState({ addModalStatements: false });
      });
  };

  // importCSV = (event) => {
  //   event.preventDefault();
  //   var data = new FormData();
  //   data.append("file", this.state.client_value);
  //   var Papa = require("papaparse/papaparse.min.js");
  //   Papa.parse(this.state.client_value, {
  //     header: true,
  //     download: true,
  //     skipEmptyLines: true,
  //     complete: this.updateData
  //   });
  // }

  // updateData = async (result) => {
  //   let newJson = [];
  //   result.data.map(async (value) => {
  //     newJson.push({ agent_account_id: this.state.agent_account_id, client_value: value.client_value, safaricom_value: value.safaricom_value, month: value.month })
  //   })
  //   console.log(JSON.stringify(newJson));
  //   fetch(`${window.server}/statement`, {
  //     method: "POST",
  //     headers: {
  //       Authorization: localStorage.token,
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify(newJson)
  //   })
  //     .then(response => response.json())
  //     .then(response => {
  //       console.log("data from api", JSON.stringify(response));
  //       if (response.code) {
  //         alert(
  //           response.message +
  //           " \n " +
  //           (response.errors[0] ? response.errors[0].message : "")
  //         );
  //         this.setState({ modalVisible: false });
  //       } else {
  //         console.log(response);
  //         alert(
  //           "Agent Account Statement Updated Successfully"
  //         );
  //         this.setState({ uploadSuccessful: true, modalVisible: false, addModalStatements: false });
  //       }
  //     })
  //     .catch(d => {
  //       console.log("Error saving the data");
  //       console.log(d);
  //       this.setState({ addModalStatements: false });
  //     });
  // };

}

export default AccountView;
