import React, { Component } from "react";
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import { DownloadCloud, ArrowRightCircle, ArrowLeftCircle, CheckCircle, Delete } from "react-feather";
import axios from 'axios';



class documentsView extends Component {

  constructor(props) {

    super(props);
    this.state = {
      docs: [{}],     
      

    };
    this.imageRef = React.createRef();
  }
  render() {
    

    const myHeader: IHeaderOverride = (state, previousDocument, nextDocument) => {
      if (!state.currentDocument || state.config?.header?.disableFileName) {
        return null;
      }

     

      return (
        
        
        <>      
        
       
          <div>{state.currentDocument.fileName || ""}</div>
          
          <button onClick={() => {
            saveAs(state.currentDocument.uri, state.currentDocument.fileName);
          }}
            className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary mr-3" style={{ marginLeft: '1000px' }}>
            <DownloadCloud size={18} />
            <span className="pl-1 font-weight-bold">Download</span>
          </button>

          <button onClick={() => this.sayHello(state.currentDocument.docId)}
            className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-info mr-3" style={{ marginLeft: '1000px' }}>
            <Delete size={18} />
            <span className="pl-1 font-weight-bold">Delete</span>
          </button>
        

         


          <div style={{ marginLeft: '1098px' }}>
            <button style={{ border: 'none' }}>Doc {state.currentFileNo + 1} of  {state.documents.length}  </button>
            <button style={{ border: 'none' }}
              onClick={previousDocument}
              disabled={state.currentFileNo === 0}
            >
              <ArrowLeftCircle size={40} />
            </button>
            <button style={{ border: 'none' }}
              onClick={nextDocument}
              disabled={state.currentFileNo >= state.documents.length - 1}
            >
              <ArrowRightCircle size={40} />
            </button>

          </div>

        </>
      );
    };
  

   




    return (
      <div className="App">
        <h1>Documents</h1>

       
       
           
        {this.state.docs && this.state.docs.length > 0 ? <>
        <DocViewer
        documents={this.state.docs.map((file) => ({
          uri: file.uri,
          fileName: file.fileName,
          docId:file.docId,
        }))}
        pluginRenderers={DocViewerRenderers}
        config={{header: { overrideComponent: myHeader }, }}
      />
           </>: <h2>Loading......</h2>} 
     
      </div>
    );
  }

  componentDidMount() {
    this.fetchDocuments();
  }

  sayHello = (id) =>{
    const editObject = {      
      status: 0,
    };   

    axios.patch(`${window.server}/agent-account-files/${id}`, editObject, { headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*", Authorization: localStorage.token } })
      .then(() => {
        
       
        window.alert2.show({
          loader: false,
          icon: <CheckCircle size={60}></CheckCircle>,
          title: "Delete",
          message:'Document Has Been Updated Successfully',
          buttons: true,
          onSubmit: () => {
            window.location.reload();
          }
         
        });
      
      }).catch(() => {
        window.alert2.show({
          loader: false,
          icon: <CheckCircle size={60}></CheckCircle>,
          title: "Erro",
          message:"Error. Kindly Try Again.",
          buttons: true,
          
        });
      
       
      });

 
  }

  fetchDocuments = () => {


    let q = {

      ...this.state.query,
      agent_id: this.props.match.params.agent_id,
    };

    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");

    // console.log(urlParams);
    fetch(`${window.server}/agent-files?agent_id=${this.props.match.params.agent_id}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {        
        if(Object.entries(response).length === 0){
          window.alert2.show({
            loader: false,
            icon: <CheckCircle size={60}></CheckCircle>,
            title: "No Document",
            message:'No Uploaded documents for this agent',
            buttons: true,
            onSubmit: () => {
              this.props.history.push(`/agent-view/details/${this.props.match.params.id}/${this.props.match.params.agent_id}`);
            }
          });
        }

       let p= []
        for (let index = 0; index < response.length; index++) {
          const element = response[index];
          
          fetch(`${window.server}/agent-files/${element.id}`, {
            method: "GET",
            headers: { Authorization: localStorage.token },}).then(res => res.blob()).then(blob => {
              

              let objectURL = URL.createObjectURL(blob);
              let fileName =element.file_location;
              let docId =element.id;
          
             
          
          
    
          
          this.setState({...this.state, docs: [...this.state.docs, {uri:objectURL,fileName:fileName, docId:docId}]})
          

          //console.log('state is',this.state.docs )
                // p.push({
                //   src:objectURL,  
                
                // })
                // console.log('p is ',p)
                
            });
       
      
          
          // p.push({
          //   uri:`${window.server}/agent-files/${element.id}`,
          //   ...element
          // })
        }

        // console.log('P outside: ', p)
        
        // this.setState({
        //   ...this.state,
        //   docs:p
        // });
        //console.log('state: ', this.state)
       
        

      })
      .catch(d => {

        console.error('error is',d);
      });

  };

}




export default documentsView;