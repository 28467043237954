import React, { Component } from "react";
import Table from "../../components/Table";
import Filter from "../../components/filter";
import { Plus, Target, Circle, Smartphone } from "react-feather";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { Verify } from "crypto";
import Fuse from "fuse.js";
import moment from "moment";
import Nav from "../../components/Nav";

class okoaAgents extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false
  };

  timeout = null;
  render() {
    return (
      <div className="">
        <Nav
          name="All Agents"
        //buttons={[{ text: "Add an Agent", link: "agent-add/1" }]}
        ></Nav>
        {/* <div className="mt-3 table-card  border-0 card shado mx-3 shadow"> */}
        <div className="mt-3 border-0 card mx-3 bg-white">
          <div className="p-4">
            <Table
              search={["first_name", "other_names", "msisdn"]}
              sort="id"
              sortDirection={-1}
              data={this.state.tableData}
              fetch={params => {
                this.setState({ query: params });
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchAgents = () => {
    this.setState({ table_loading: true });
    //alert(localStorage.id);
    //let created_by = (localStorage.role_id === "3") ? localStorage.id : "";
    let q = {
      // ...this.state.filter,
      ...this.state.query,
      //roles_id: "1",
      //created_by
    };

    let urlParams = Object.entries(q)
      .map(e => e.join("="))
      .join("&");

    fetch(`${window.server}/agents?${urlParams}`, {
      headers: {
        Authorization: localStorage.token
      }
    })
      .then(response => response.json())
      .then(response => {
        console.log("AGENTS", response);

        let data = [];
        response.data.map((d, i) => {
          data.push({
            full_name: `${d.first_name} ${d.other_names}`,
            phone_number: d.msisdn,
            date_created: moment(d.created_at).format('DD-MM-YYYY HH:mm:ss'),
            date_updated: moment(d.updated_at).format('DD-MM-YYYY HH:mm:ss'),
            account_status: parseFloat(d.status) === 1 ?
              <span className="badge badge-success">ACTIVE</span> :
              <span className="badge badge-danger">INACTIVE</span>,
            action: (
              <div className="d-flex flex-row">
                <Link
                  to={"/agent-view/details/" + d.id + "/" + d.agent_id}
                  className="btn btn-sm btn-primary px-3 btn-round" style={{marginRight: '5px'}}
                >
                  View Information
                </Link>

                {/* <Link
                      to={"/upload-documents/" + d.msisdn}
                      className="btn btn-sm btn-primary px-3 btn-round" style={{marginRight: '5px'}}
                    >
                      Upload Documents
                </Link>
                <Link
                      to={"/view-documents/" + d.msisdn}
                      className="btn btn-sm btn-primary px-3 btn-round" style={{marginRight: '5px'}}
                    >
                      View Documents
                </Link> */}
              </div>
            )
          });
        });

        let dts = {};
        dts.data = data;

        this.setState({
          tableData: { ...response, ...dts },
          response,
          table_loading: false
        });
      })
      .catch(d => {
        this.setState({ table_loading: false });
        console.error(d);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify({ ...this.state.query, ...this.state.filter }) !==
      JSON.stringify({ ...prevState.query, ...prevState.filter })
    ) {
      let $t = this;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        $t.fetchAgents();
      }, 100);
    }
  }
}

export default okoaAgents;
